import React, { Component } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="row">
                        <div className="col-12"  style={{ fontSize: "15px" }}>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Privacy is of utmost importance at Bitcoin.com. We recognize the
                                    significance of protecting information which is stored on our
                                    servers or network or is intended to be stored on our servers or
                                    network and which relates to an individual. The data we protect are
                                    the “Personal Data” which is any information that can be used to
                                    identify you directly or indirectly.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    This Privacy Policy is designed to address regulatory requirements
                                    of the jurisdictions in which Bitcoin.com offers its Services,
                                    including the General Data Protection Regulation (“GDPR”), as
                                    enacted by the European Commission and the California Consumer
                                    Privacy Act (“CCPA”). In this Privacy Policy, the terms “Service”
                                    and “Services” have the same meaning as described in the User
                                    Agreement.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    This Privacy Notice applies to all processing of Personal Data on
                                    the website www.bitcoin.com and all other Bitcoin.com sites on which
                                    it appears (the “Sites”). If you do not agree with this Privacy
                                    Policy, in general, or any part of it, you should not use the Sites
                                    or Services. This Privacy Policy is periodically reviewed to ensure
                                    that any new obligations and changes to our business model are taken
                                    into consideration. We may amend this Privacy Policy at any time by
                                    posting an amended version on our website.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    In this privacy policy, we will tell you:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Who we are;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    What Personal Data we collect from you and how we collect it;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    On what legal basis we collect your Personal Data;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    For what purpose we collect your Personal Data;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    How long we store it for;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Who do we share your Personal Data with;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Whether we intend to transfer it to another country;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    What are your rights with respect to your Personal Data; and
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Children’s Privacy; and
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Changes to this Privacy Policy.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    1. Who are we and how can you contact us if you have any concerns?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    The organization who is responsible for ensuring that your Personal
                                    Data is processed in compliance with applicable regulations is:
                                    Saint Bitts LLC, Nelson Springs Commercial Complex, Colquhoun
                                    Estate, Nevis, company number L17015 (the “Data Controller”).
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    If you have any concern, question or if you would like to exercise
                                    any of your rights with respect to your Personal Data, you may
                                    contact our Data Protection Officer (“DPO”) at support@bitcoin.com.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    2. What data do we collect about you and how do we collect it?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We collect your Personal Data through a variety of methods: either
                                    you provide it to us directly, we collect it from external sources,
                                    or we collect it by using automated means.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Information you may provide to us: You may give us information about
                                    you by filling in forms on our Sites or through our app or by
                                    corresponding with us by phone, email or otherwise. This includes
                                    but is not limited to the following information:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Account Administration. We may collect personal information from you
                                    in connection with the administration of your account. This personal
                                    information may include, but is not limited to, your name, date of
                                    birth, age, nationality, country of residence, gender, occupation,
                                    signature, utility bills, professional details, photographs, video
                                    footage, phone number, home address, email address, government
                                    issued identification such as passport, driver’s license, national
                                    identification card with photograph, tax identification number,
                                    national insurance number, social security number, visa information
                                    and/or any other information deemed necessary to comply with our
                                    legal obligations.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Financial and Transactional Information. We may collect financial
                                    information such as your wallet address, bank account numbers, bank
                                    statements, transaction history, trading data, and credit/debit card
                                    numbers. We may collect personal information and details associated
                                    with your transactions including, but not limited to, information
                                    about the transactions you make using our services, the purpose of
                                    the transactions, information on sources of your funds, sender and
                                    receiver of funds, and ultimate beneficiary information.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Your Communications with Us. We may collect personal information,
                                    such as email address, phone number, or mailing address when you
                                    request information about our Services, register for our newsletter,
                                    request customer or technical support, or otherwise communicate with
                                    us.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Surveys. We may contact you to participate in surveys. If you decide
                                    to participate, we may collect personal information from you in
                                    connection with the survey.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Interactive Features. We and others who use our services may collect
                                    personal information that you submit or make available through our
                                    interactive features (e.g., messaging and chat features, commenting
                                    functionalities, forums, blogs, and social media pages). Any
                                    information you provide using the public sharing features of the
                                    Services will be considered “public,” unless otherwise required by
                                    applicable law, and is not subject to the privacy protections
                                    referenced herein.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Sweepstakes or Contests. We may collect personal information you
                                    provide for any sweepstakes or contests that we offer. In some
                                    jurisdictions, we are required to publicly share information of
                                    sweepstakes and contest winners.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Conferences, Trade Shows, and Other Events. We may collect personal
                                    information from you when we attend or host conferences, trade
                                    shows, and other events.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Business Development and Strategic Partnerships. We may collect
                                    personal information from you and third parties to assess and pursue
                                    potential business opportunities.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Job Applications. We may post job openings and opportunities on our
                                    Services. If you respond to one of these postings, we may collect
                                    your personal information, such as your application, CV, cover
                                    letter, and/or any other information you provide to us.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Information Collected Automatically: With regard to each of your
                                    visits to our Sites or our app we automatically collect the
                                    following information:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Automatic Collection of Personal Information. We may collect certain
                                    information automatically when you use our Services, such as your
                                    Internet protocol (IP) address, user settings, MAC address, cookie
                                    identifiers, mobile carrier, mobile advertising and other unique
                                    identifiers, browser or device information, location information
                                    (including precise location information and/or approximate location
                                    derived from IP address), and Internet service provider. We may also
                                    automatically collect information regarding your use of our
                                    Services, such as pages that you visit before, during and after
                                    using our Services, information about the links you click, the types
                                    of content you interact with, the frequency and duration of your
                                    activities, and other Information about how you use our Services.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Device Information: We collect information about the device you use
                                    to access your account, including the hardware model, operating
                                    system and version, and unique device identifiers, but this
                                    information is anonymized&nbsp;and not tied to any particular
                                    person.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Transaction Information: In connection with our Services (as such
                                    term is defined in our User Agreement), we may collect and maintain
                                    information relating to transactions you effect using the
                                    Bitcoin.com Wallet (e.g. converting one virtual currency to another
                                    or purchasing virtual currencies).
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Information We Collect Required By Law, Rule, or Regulation:
                                    Depending on the Service, we may collect information from you in
                                    order to meet regulatory obligations around know-your-customer
                                    (“KYC”) and anti-money laundering (“AML”) requirements. Information
                                    that we collect from you includes the following:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Full name
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Residential address
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Contact details (telephone number, email address)
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Date and place of birth, gender, place of citizenship
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Bank account information and/or credit card details
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Your status as a politically exposed person
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Source of funds &amp; proof of address
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Passport and/or national driver’s license or government-issued
                                    identification card to verify your identity
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Transaction history and account balances in connection with your use
                                    of certain Services.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Information We Collect from External Sources: We also receive
                                    information from other sources and combine that with the information
                                    we collect through our Services. For instance:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We use "cookies" from time to time to help personalize&nbsp;your
                                    online experience with us. A cookie is a small text file that is
                                    stored on your computer to help us make your visit to our Sites more
                                    “user-friendly.” Please see our Cookie Policy for more details about
                                    the cookies we use. Cookies provide us with information about your
                                    use of the Sites that can help us improve the Sites and your
                                    experience with it. We will process Personal Data collected through
                                    cookies in accordance with this Privacy Policy. If you have set your
                                    browser to alert you before accepting cookies, you should receive an
                                    alert message with each cookie. You may refuse cookies by turning
                                    them off in your browser, however, you should be aware that our
                                    Sites, like most other popular sites, may not work well with cookies
                                    disabled.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We may analyze public blockchain data to ensure parties utilizing
                                    our Services are not engaged in illegal or prohibited activity under
                                    our User Agreement, and to analyze transaction trends for research
                                    and development purposes.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We use third-party services that may be co-branded as Bitcoin.com
                                    but will do so with clear notice. Any third-party services may
                                    collect information as determined by their own privacy policies.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Banks or payment processors that you use to transfer fiat currency
                                    may provide us with basic Personal Data, such as your name and
                                    address, as well as, your bank account information.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Advertising or analytics providers may provide us with
                                    anonymized&nbsp;information about you, including but not limited to,
                                    how you found our website.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    3. On what basis do we collect your data?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We only use your Personal Data where we have a legal basis to do so:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Consent. For some processing activities, we require your prior
                                    consent. This applies for example to some of our direct marketing
                                    activities which fall under the scope of the GDPR. You will always
                                    be prompted to take clear, affirmative action so that we can ensure
                                    that you agree with the processing of your Personal Data. This
                                    action may, for example, take the form of a checkbox. If you have
                                    given us your consent for processing operations, you may always
                                    change your mind, and withdraw your consent at any time and easily;
                                    all you need to do is to send us an email at the following address
                                    support@bitcoin.com.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Performance of a contract. Some Personal Data we process about you
                                    is for the performance of a contract to which you are a party or in
                                    order to take steps at your request prior to entering into a
                                    contract with us.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Legal obligation. In some cases, we have to process your Personal
                                    Data to comply with legal obligations, including those applicable to
                                    financial services institutions, such as under the Bank Secrecy Act
                                    and other anti-money laundering laws. You may not be eligible for
                                    certain Services if we cannot collect the Personal Data necessary to
                                    meet our legal obligations.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Legitimate interest. In most cases where we process Personal Data in
                                    the context of our Services we rely on our legitimate interests in
                                    conducting our normal business as a legal basis for such processing.
                                    Our legitimate interests are to identify or prevent fraud, to
                                    enhance the security of our network and information systems, or to
                                    carry out processing operations for statistical purposes. We will
                                    use legitimate interest only when we have carried out an assessment
                                    on the impact that this processing may have on you, and concluded
                                    that the processing does not unduly infringe your rights and
                                    freedoms. For example, we do not use this justification if we
                                    process sensitive data, or when the processing would be unexpected
                                    for you, or if we consider it to be too intrusive.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    4. For what purposes do we collect your data?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We collect your Personal Data to:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Provide our Services (including customer support);
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Process transactions and send notices about your transactions;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Resolve disputes, collect fees, and troubleshoot problems;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Communicate with you about our Services and business and to inform
                                    you of matters that are important for your account and/or use of the
                                    Sites. We also use your Personal Data to respond to any questions,
                                    comments or requests you filed with us and the handling of any
                                    complaints;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Comply with applicable laws and regulations;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Establish, exercise and defend legal claims;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Monitor and report compliance issues;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Customize, measure, and improve our business, the Services, and the
                                    content and layout of our Sites and apps (including developing new
                                    products and services; managing our communications; determining the
                                    effectiveness of our sales, marketing and advertising; analyzing and
                                    enhancing our products, services, websites and apps; ensuring the
                                    security of our networks and information systems; performing
                                    accounting, auditing, invoicing, reconciliation and collection
                                    activities; and improving and maintaining the quality of our
                                    customer services);
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Perform data analysis;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Deliver targeted marketing, service update notices, and promotional
                                    offers based on your communication preferences, and measure the
                                    effectiveness of it. To approach you via email for marketing
                                    purposes, we request your consent, unless it is not required by law.
                                    You always have the option to unsubscribe from our mailings, e.g.,
                                    via the unsubscribe link in our newsletter;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Perform risk management, including comparing information for
                                    accuracy and verify it with third parties and protect against,
                                    identify and prevent fraud and other prohibited or illegal activity,
                                    claims and other liabilities; and
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Enforce our contractual terms.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    5. How long do we keep your data?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We will only retain your personal information for as long as
                                    necessary to fulfil the purposes for which we collected it,
                                    including for the purposes of satisfying any legal, accounting, or
                                    reporting obligations or to resolve disputes. While retention
                                    requirements vary by jurisdiction, information about our typical
                                    retention periods for different aspects of your personal information
                                    are described below.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Personal information collected to comply with our legal obligations
                                    under financial or anti-money laundering laws may be retained after
                                    account closure for as long as required under such laws.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Contact Information such as your name, email address and telephone
                                    number for marketing purposes is retained on an ongoing basis until
                                    you unsubscribe. Thereafter we will add your details to our
                                    suppression list to ensure we do not inadvertently market to you.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Content that you post on our Sites such as support desk comments,
                                    photographs, videos, blog posts, and other content may be kept after
                                    you close your account for audit and crime prevention purposes (e.g.
                                    to prevent a known fraudulent actor from opening a new account).
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Information collected via technical means such as cookies, web page
                                    counters and other analytics tools is kept for a period of up to one
                                    year from expiry of the cookie.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    When Personal Data is no longer necessary for the purpose for which
                                    it was collected, we will remove any details that identifies you or
                                    we will securely destroy the records, where permissible. However, we
                                    may need to maintain records for a significant period of time (after
                                    you cease using a particular Service) as mandated by regulation. For
                                    example, we are subject to certain anti-money laundering laws that
                                    require us to retain the following, for a period of five (5) years
                                    after our business relationship with you has ended.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    6. Who do we share your Personal Data with?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We may share your information with selected recipients to perform
                                    functions required to provide certain Services to you and/or in
                                    connection with our efforts to prevent and investigate fraudulent or
                                    other criminal activity. All such third parties will be
                                    contractually bound to protect data in compliance with our Privacy
                                    Policy. The categories of recipients include:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Other Bitcoin.com entities, including to help detect and prevent
                                    potentially illegal acts and violations of our policies, and to
                                    guide decisions about our products, services and communications;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Service providers who help with our business operations and to
                                    deliver our Services, such as:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Cloud service providers providing cloud infrastructure;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Providers of ID verification solutions and other due diligence
                                    solutions (such as KYC and anti-money laundering);
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Providers of website analytics;
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Providers of customer service solutions; and
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Providers of marketing automation platforms.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Law enforcement, government officials, regulatory agencies, our
                                    banks, and other third parties pursuant to a subpoena, court order
                                    or other legal process or requirement if applicable to Bitcoin.com;
                                    or when we believe, in our sole discretion, that the disclosure of
                                    data is necessary to report suspected illegal or fraudulent activity
                                    or to investigate violations of our User Agreement.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We also may share Personal Data with a buyer or other successor in
                                    the event of a merger, divestiture, restructuring, reorganization,
                                    dissolution or other sale or transfer of some or all of City Index
                                    assets, whether as a going concern or as part of bankruptcy,
                                    liquidation or similar proceeding, in which Personal Data held by
                                    Bitcoin.com is among the assets transferred.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Funding and transaction information related to your use of certain
                                    Services may be recorded on a public blockchain. Public blockchains
                                    are distributed ledgers, intended to immutably record transactions
                                    across wide networks of computer systems. Many blockchains are open
                                    to forensic analysis which can lead to deanonymization and the
                                    unintentional revelation of private financial information,
                                    especially when block chain data is combined with other data.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Because blockchains are decentralized&nbsp;or third-party networks
                                    that are not controlled or operated by Bitcoin.com or its
                                    affiliates, we are not able to erase, modify, or alter Personal Data
                                    from such networks.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    7. Is your data transferred outside of the European Union?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    As we are located outside of the European Union, we will ensure that
                                    any entity, whether our processor or affiliate that transfers
                                    Personal Data to us from the EU employs appropriate safeguards. If
                                    your Personal Data is transferred to us from the European Economic
                                    Area by our service providers (i.e., Data Processors who are engaged
                                    on our behalf) and business partners, we will ensure that the
                                    transfer is lawful.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    8. What are your rights?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Under the GDPR and relevant implementation acts, individuals have
                                    statutory rights related to their Personal Data. Please note that
                                    rights are not absolute and may be subject to conditions.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    One key right is the Right to object. You have the right to object
                                    to processing of your Personal Data where we are relying on
                                    legitimate interests as our legal basis (see above). Under certain
                                    circumstances, we may have compelling legitimate grounds that allow
                                    us to continue processing your Personal Data. Insofar as the
                                    processing of your Personal Data takes place for direct marketing
                                    purposes, including profiling for direct marketing, we will always
                                    honor your request.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Other rights are as follows:
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Right to withdraw consent. Insofar as our processing of your
                                    Personal Data is based on your consent (see above), you have the
                                    right to withdraw consent at any time.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Right of access. You have the right to request access to your
                                    Personal Data.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Right to rectification. You have the right to request rectification
                                    of the Personal Data that we hold about you.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Right to erasure. You have the right to request erasure of your
                                    Personal Data. This enables you to ask us to delete or remove
                                    Personal Data in certain circumstances.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Right to restriction. You have the right to request restriction of
                                    processing of your Personal Data.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Right to data portability. In some cases, you have the right to
                                    request to transfer your Personal Data to you or to a third party of
                                    your choice.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    The exercise of the aforementioned rights is free of charge and can
                                    be carried out by accessing our Data Subject Request Portal or
                                    contacting us at support@bitcoin.com.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Before responding to your request, we will verify your identity and
                                    / or ask you to provide us with more information to respond to your
                                    request, if we have any doubts about your identity. We will do our
                                    best to respond to your request within one month, unless your
                                    request is particularly complex (for example if your request
                                    concerns a large amount of sensitive data). In such a case, we will
                                    inform you of the need to extend this response time by two
                                    additional months.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    9. How about children’s privacy?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    Bitcoin.com does not address anyone under the age of 18. We do not
                                    knowingly collect personally identifiable information from anyone
                                    under the age of 18. If you are a parent or guardian and you are
                                    aware that your child has provided us with Personal Data, please
                                    contact us at support@bitcoin.com. If we become aware that we have
                                    collected Personal Data from children without verification of
                                    parental consent, we take steps to remove that information from our
                                    servers.
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    <br />
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    10. What happens if we make changes to this Privacy Policy?
                                </font>
                            </div>
                            <div>
                                <font face="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif">
                                    We may update this policy to reflect changes to our information
                                    practices but we will not reduce your rights under this Privacy
                                    Policy without your express consent. We will post any changes to
                                    this page and, if the changes are significant, we will provide a
                                    more prominent notice. We will also keep prior versions of this
                                    Privacy Policy in an archive for your review. We encourage you to
                                    periodically review our Privacy Policy for the latest information on
                                    our privacy practices and to contact us if you have any questions or
                                    concerns.
                                </font>
                            </div>{" "}
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}