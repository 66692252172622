import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import service from "../../services/service";
import LoadingOverlay from '../loading';

class BankWithdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountInUsd: 0,
      _id: sessionStorage.getItem('userId') ?? null,
      withdrawId: props.match.params.withdrawId ?? null,
      loading: false,
      errorMessage: "",
      successMessage: "",
      cardHolderName: "",
      cardNumber: "",
      cardExpiryDate: "",
      cardSecurityCode: "",
    };
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.name === "cardSecurityCode" ? event.target.value.slice(0, 3) : event.target.value });
  };

  formatCardNumber = (event) => {
    const numericValue = event.target.value.replace(/\D/g, '');
    const truncatedValue = numericValue.slice(0, 16);
    const formattedValue = truncatedValue.replace(/(\d{4})/g, '$1 ').trim();
    this.setState({ cardNumber: formattedValue });
  };

  WithdrawMoney = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    try {
      const { cardHolderName, cardNumber, cardExpiryDate, withdrawId } = this.state;
      const userData = { cardHolderName, cardNumber, cardExpiryDate, withdrawId };
      const response = await service.save(userData, 'withdraws/update', "PUT");
      if (response && !response.success) {
        this.setState({ successMessage: "", errorMessage: response.message + " " + response.error });
        return;
      }
      this.setState({ errorMessage: "", successMessage: ""});
      window.location.href = "/withdraw-history?success=true";
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };


  render() {
    const { loading, errorMessage, successMessage, cardHolderName, cardNumber, cardExpiryDate, cardSecurityCode } = this.state;
    return (
      <>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container">
            {loading && <LoadingOverlay />}
            <div className="row">
              <div className="col-xxl-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Transaction</h4>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-lg-6">
                          <form onSubmit={this.WithdrawMoney} method="POST">
                            {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                            {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                            <div className="card custom--card">
                              <div className="card-header">
                                <h4 className="card-title">Withdraw Money</h4>
                              </div>
                              <div className="card-body">
                                <div className="form-group">
                                  <label className="form-label">
                                    Please use your personal card and not a company card.
                                  </label>
                                </div>
                                <div className="form-group">
                                  <label className="form-label">Card Number </label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      name="cardNumber"
                                      onChange={this.formatCardNumber}
                                      className="form-control cmn--form--control"
                                      autoComplete="off"
                                      maxLength={19}
                                      required
                                      value={cardNumber}
                                    />
                                  </div>
                                </div><br />
                                <div className="form-group">
                                  <label className="form-label">Cardholder's Name </label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      name="cardHolderName"
                                      onChange={this.handleInputChange}
                                      className="form-control cmn--form--control"
                                      autoComplete="off"
                                      required
                                      value={cardHolderName}
                                    />
                                  </div>
                                </div><br />
                                <div className="form-group">
                                  <label className="form-label">Expiry Date </label>
                                  <div className="input-group">
                                    <input
                                      type="date"
                                      name="cardExpiryDate"
                                      onChange={this.handleInputChange}
                                      className="form-control cmn--form--control"
                                      autoComplete="off"
                                      required
                                      value={cardExpiryDate}
                                    />
                                  </div>
                                </div><br />
                                <div className="form-group">
                                  <label className="form-label">Security Code 3 digits </label>
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      name="cardSecurityCode"
                                      onChange={this.handleInputChange}
                                      className="form-control cmn--form--control"
                                      autoComplete="off"
                                      required
                                      maxLength={3}
                                      value={cardSecurityCode}
                                    />
                                  </div>
                                </div>
                                <br></br>
                                <button type="submit" className="cmn--btn btn-block btn btn-success waves-effect">
                                  Withdraw
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {loading && <LoadingOverlay />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default BankWithdraw;
