import React from 'react';

const ConfirmationModal = ({ isOpen, onCancel, onConfirm, modalText }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
        <p>{modalText}</p>
        <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
          <button style={{ marginRight: '10px', backgroundColor: '#f44336', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }} onClick={onCancel}>Cancel</button>
          <button style={{ backgroundColor: '#4CAF50', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }} onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

// const CustomModal = ({ isOpen, onClose, imageUrl }) => {
//   if (!isOpen) {
//     return null;
//   }

//   return (
//     <div className="custom-modal">
//       <div className="modal-content">
//         <span className="close" onClick={onClose}>&times;</span>
//         <img src={imageUrl} alt="Receipt" style={{ maxWidth: '100%', maxHeight: '100%' }} />
//       </div>
//     </div>
//   );
// };

// export default { ConfirmationModal, CustomModal };
export default ConfirmationModal;
