import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FE_BASE_URL } from "../config";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdowns: {
        trade: false,
        deposit: false,
        withdraw: false,
        practiceTrade: false,
      }
    };
  }

  toggleDropdown = (dropdown) => {
    this.setState(prevState => ({
      dropdowns: Object.keys(prevState.dropdowns).reduce((acc, key) => {
        acc[key] = key === dropdown ? !prevState.dropdowns[key] : false;
        return acc;
      }, {})
    }));
  }

  render() {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    const isAdmin = sessionStorage.getItem("isAdmin") === "true";
    return (
      <div className="sidebar">
        <div className="brand-logo"><Link to={isAdmin ? "/admin/dashboard" : "/"}><img src={`${FE_BASE_URL}assets/images/logo.png`} alt="" /></Link></div>
        <div className="menu">
          <ul>
            {isLoggedIn ? (
              <>
                {isAdmin ? (
                  <>
                    <li><Link to={"/admin/dashboard"} title="Home"><span><i className="icofont-ui-home"></i></span></Link></li>
                    <li><Link to={"/admin/users-list"} title="Manage Users"><span><i className="icofont-users"></i></span></Link></li>
                    <li><Link to={"/admin/assets-list"} title="Manage Assets"><span><i className="icofont-coins"></i></span></Link></li>
                  </>
                ) : (
                  <>
                    <li><Link to={"/"} title="Home"><span><i className="icofont-ui-home"></i></span></Link></li>
                    {/* Deposit Menu */}
                    <li style={{ position: 'relative' }}>
                      <Link to="#" title="Deposit" style={{ color: 'white', textDecoration: 'none' }} onClick={() => this.toggleDropdown('deposit')}>
                        <span><i className="icofont-bank"></i></span>
                      </Link>
                      {this.state.dropdowns.deposit && (
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0, backgroundColor: '#f8f8fb', position: 'absolute', left: '100%', top: 0, zIndex: 1000 }}>
                          <li><Link to={"/deposit-now"}
                            onMouseEnter={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.backgroundColor = "#f0f0f0";
                            }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                            style={{ color: 'blue', textDecoration: 'none', transition: "color 0.3s ease-in-out, background-color 0.3s ease-in-out" }}>Deposit Now</Link></li>
                          <li><Link to={"/deposit-history"}
                            onMouseEnter={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.backgroundColor = "#f0f0f0";
                            }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                            style={{ color: 'blue', textDecoration: 'none', transition: "color 0.3s ease-in-out, background-color 0.3s ease-in-out" }}>Deposit History</Link></li>
                        </ul>
                      )}
                    </li>
                    {/* Withdraw Menu */}
                    <li style={{ position: 'relative' }}>
                      <Link to="#" title="Withdraw" style={{ color: 'white', textDecoration: 'none' }} onClick={() => this.toggleDropdown('withdraw')}>
                        <span><i className="icofont-wallet"></i></span>
                      </Link>
                      {this.state.dropdowns.withdraw && (
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0, backgroundColor: '#f8f8fb', position: 'absolute', left: '100%', top: 0, zIndex: 1000 }}>
                          <li><Link to={"/withdraw-now"}
                            onMouseEnter={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.backgroundColor = "#f0f0f0";
                            }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                            style={{ color: 'blue', textDecoration: 'none' }}>Withdraw Now</Link></li>
                          <li><Link to={"/withdraw-history"}
                            onMouseEnter={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.backgroundColor = "#f0f0f0";
                            }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                            style={{ color: 'blue', textDecoration: 'none' }}>Withdraw History</Link></li>
                        </ul>
                      )}
                    </li>
                    {/* Trade Menu */}
                    <li style={{ position: 'relative' }}>
                      <Link to="#" title="Trade" style={{ color: 'white', textDecoration: 'none' }} onClick={() => this.toggleDropdown('trade')}>
                        <span><i className="icofont-stack-exchange"></i></span>
                      </Link>
                      {this.state.dropdowns.trade && (
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0, backgroundColor: '#f8f8fb', position: 'absolute', left: '100%', top: 0, zIndex: 1000 }}>
                          <li><Link to={"/trade"}
                            onMouseEnter={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.backgroundColor = "#f0f0f0";
                            }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                            style={{ color: 'blue', textDecoration: 'none' }}>Trade Now</Link></li>
                          <li><Link to={"/trade-log"}
                            onMouseEnter={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.backgroundColor = "#f0f0f0";
                            }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                            style={{ color: 'blue', textDecoration: 'none' }}>Trade Log</Link></li>
                        </ul>
                      )}
                    </li>
                    {/* Practice Menu */}
                    <li style={{ position: 'relative' }}>
                      <Link to="#" title="Practice Trade" style={{ color: 'white', textDecoration: 'none' }} onClick={() => this.toggleDropdown('practiceTrade')}>
                        <span><i className="icofont-handshake-deal"></i></span>
                      </Link>
                      {this.state.dropdowns.practiceTrade && (
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0, backgroundColor: '#f8f8fb', position: 'absolute', left: '100%', top: 0, zIndex: 1000 }}>
                          <li><Link to={"/practice"}
                            onMouseEnter={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.backgroundColor = "#f0f0f0";
                            }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                            style={{ color: 'blue', textDecoration: 'none' }}>Practice Trade Now</Link></li>
                          <li><Link to={"/practice-log"}
                            onMouseEnter={(e) => {
                              e.target.style.color = "blue";
                              e.target.style.backgroundColor = "#f0f0f0";
                            }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent'; }}
                            style={{ color: 'blue', textDecoration: 'none' }}>Practice Trade Log</Link></li>
                        </ul>
                      )}
                    </li>
                    <li><Link to={"/transaction-log"} title="Transaction Log"><span><i className="icofont-history"></i></span></Link></li>
                  </>
                )}
              </>
            ) : (
              <>
                <li><Link to={"/"} title="Home"><span><i className="icofont-ui-home"></i></span></Link></li>
                <li><Link to={"/signin"} title="Sign In"><span><i className="icofont-login"></i></span></Link></li>
                <li><Link to={"/signup"} title="Create Account"><span><i className="icofont-contact-add"></i></span></Link></li>
              </>
            )}
            <p className="copyright">&#169; <Link to={"/#"}>Trade</Link></p>
          </ul>
        </div>
      </div>
    );
  }
}