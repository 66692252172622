import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import service from "../../services/service";
import LoadingOverlay from '../../components/loading';
import { Link } from 'react-router-dom';
import { IMAGE_PATH_COMPELETE } from "../../config";

export default class AssetsP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            loading: false,
            errorMessage: "",
            successMessage: "",
            assets: [],
            deposits: 0,
            amountInUsd: 0,
            isModalOpen: false,
        };
    }

    findAllDepositsByUserId = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`practice-deposits/user-amount/${this.state._id}`);
            if (response && !response.success) {
                this.setState({ successMessage: "", errorMessage: "" });
                return;
            }
            this.setState({ errorMessage: "", deposits: response.data.deposit.amountInUsd });
        } catch (error) {
            this.setState({ successMessage: "", errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    openModal = () => { this.setState({ isModalOpen: true }); };
    closeModal = () => { this.setState({ isModalOpen: false }); };

    handleAddBalance = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            const response = await service.save({ amountInUsd: this.state.amountInUsd }, "practice-deposits", "POST");
            if (!response.success) {
                this.setState({ successMessage: "", errorMessage: response.message });
                return;
            }
            this.closeModal();
            this.setState({ successMessage: `${response.message}`, deposits: response.data.deposits }, () => {
                setTimeout(() => { this.setState({ successMessage: "" }); }, 3000);
            });
        } catch (error) {
            this.setState({ successMessage: "", errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    componentDidMount() {
        this.getAssetsList();
        if (this.state._id !== null) this.findAllDepositsByUserId();
    }

    getAssetsList = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view('assets', 'GET');
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message });
                return;
            }
            this.setState({ assets: response.data.assets });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, errorMessage, successMessage, assets, isModalOpen, amountInUsd, deposits } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                            {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                            <div className="col-12">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <button type="button" className="btn btn-success waves-effect" onClick={this.openModal}>
                                        <i className="icofont-plus"></i> Add Practice Balance
                                    </button><h6 className="my-2">Practice Balance : {deposits} USD </h6>
                                </div>
                            </div><br /><br /><br />
                            {assets.length === 0 ? (<div className="alert alert-danger" role="alert">{"No Assets Available Yet!"}</div>) : (
                                <>
                                    {assets.map((asset, index) => (
                                        <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                                            <div className="card dashboardCard" style={{ alignItems: 'center' }}>
                                                <div className="card-header p-2">
                                                    <h5 className="card-title">
                                                        {asset.shorthand}
                                                    </h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="deposit__thumb">
                                                        <Link to="">
                                                        <img alt={""} style={{ height: 'auto', width: '260px' }}
                                                                src={asset.imagePath !== undefined ? `
                                                                ${IMAGE_PATH_COMPELETE}${asset.imagePath.replace(/\\/g, '/')}
                                                            ` : ''} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <Link to={`practice-now/${asset._id}`} className="btn--sm d-block cmn--btn text-center">Trade Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        {isModalOpen && (
                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
                                    <span style={{ color: '#aaa', float: 'right', fontSize: '28px', fontWeight: 'bold', cursor: 'pointer', }}
                                        onClick={this.closeModal}>
                                        &times;
                                    </span>
                                    <label htmlFor="modalInput" style={{ marginBottom: '10px', display: 'block', }}>Add Balance for Practice</label>
                                    <input type="text" className="form-control" placeholder="Amount" name="amountInUsd" id="modalInput"
                                        value={amountInUsd} onChange={this.handleInputChange}
                                        style={{ width: '100%', padding: '8px', marginBottom: '10px', }} />
                                    <button style={{ backgroundColor: '#4CAF50', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }}
                                        onClick={this.handleAddBalance}>Add
                                    </button>{" "}
                                    <button style={{ marginRight: '10px', backgroundColor: '#f44336', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }}
                                        onClick={this.closeModal}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div >
            </>
        );
    }
}