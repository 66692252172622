import React, { Component } from 'react';
import Header from '../../layout/header';
import Sidebar from '../../layout/sidebar';
import service from '../../services/service';
import LoadingOverlay from '../loading';
import moment from 'moment';

export default class TradeLogAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            userId: props.match.params.userId,
            type: props.match.params.type ?? null,
            loading: false,
            success: '',
            errorMessage: "",
            successMessage: "",
            tradeLog: [],
        };
    }

    componentDidMount() { if (this.state.userId !== null) this.tradeLog(); }

    tradeLog = async () => {
        this.setState({ loading: true });
        const { type, userId } = this.state
        try {
            const response = await service.view(`trades/user/${userId}/`, "GET");
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message + ": " + response.error });
                return;
            }
            this.setState({ successMessage: '', errorMessage: '' });
            const tradeLog = response.data.trades
            if (type && type === "won") {
                const filteredTradeLog = tradeLog.filter(trade => trade.finalResult === 'win');
                this.setState({ tradeLog: filteredTradeLog });
            } else if (type && type === "lost") {
                const filteredTradeLog = tradeLog.filter(trade => trade.finalResult === 'lose');
                this.setState({ tradeLog: filteredTradeLog });
            } else {
                this.setState({ tradeLog });
            }
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, errorMessage, successMessage, tradeLog, type } = this.state
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">
                                            {type === "won" ? "Won " : type === "lost" ? "Lost " : ""}
                                            Trade Log
                                        </h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'center', }}>S#</th>
                                                        <th style={{ textAlign: 'center', }}>Crypto</th>
                                                        <th style={{ textAlign: 'center', }}>Amount</th>
                                                        <th style={{ textAlign: 'center', }}>High/Low</th>
                                                        <th style={{ textAlign: 'center', }}>Result</th>
                                                        <th style={{ textAlign: 'center', }}>Profit/Loss</th>
                                                        <th style={{ textAlign: 'center', }}>Status</th>
                                                        <th style={{ textAlign: 'center', }}>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tradeLog && tradeLog.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="100%" className="text-center text-muted">Data not found</td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {tradeLog.map((history, index) => (
                                                                <tr key={index}>
                                                                    <td style={{ textAlign: 'center', }}>{index + 1}</td>
                                                                    <td style={{ textAlign: 'center', }}>{history.assetName}</td>
                                                                    <td style={{ textAlign: 'center', }}>{"$ " + history.amountInUsd}</td>
                                                                    <td style={{ textAlign: 'center', }}>{history.chosenResult}</td>
                                                                    <td style={{
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'center',
                                                                        border: '1px solid #ccc',
                                                                        ...(history.finalResult === 'win' ? { color: '#2ecc71', borderColor: '#2ecc71' } : {}),
                                                                        ...(history.finalResult === 'lose' ? { color: '#e74c3c', borderColor: '#e74c3c' } : {}),
                                                                    }}>
                                                                        {history.finalResult}</td>
                                                                    <td style={{
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'center',
                                                                        border: '1px solid #ccc', // Common border color
                                                                        ...(history.finalResult === 'win' ? { color: '#2ecc71', borderColor: '#2ecc71' } : {}),
                                                                        ...(history.finalResult === 'lose' ? { color: '#e74c3c', borderColor: '#e74c3c' } : {}),
                                                                    }}>
                                                                        {"$ " + history.profitLossAmount}</td>
                                                                    <td style={{
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'center',
                                                                        border: '1px solid #ccc',
                                                                        ...(history.status === 'completed' ? { color: '#2ecc71', borderColor: '#2ecc71' } : {}),
                                                                        ...(history.status === 'pending' ? { color: '#f39c12', borderColor: '#f39c12' } : {}),
                                                                    }}>
                                                                        {history.status}</td>
                                                                    <td style={{ textAlign: 'center', }}>{moment(history.datetime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}