import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import LoadingOverlay from '../loading';
import service from "../../services/service";
import moment from 'moment';

export default class WithdrawalApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            userId: props.match.params.userId ?? null,
            loading: false,
            errorMessage: "",
            successMessage: "",
            approvalData: [],
        };
    }

    componentDidMount() { if (this.state.userId) this.getApprovalRequestsByUser(); }

    getApprovalRequestsByUser = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`admin/users/${this.state.userId}/withdraws`)
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message });
                return;
            }
            this.setState({ approvalData: response.data.withdraws })
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    changeStatus = async (withdrawalId, status = 'approve') => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`admin/withdrawal/${withdrawalId}/${status}`);
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message + ": " + response.error });
                return;
            }
            this.setState({ successMessage: response.message, errorMessage: '' }, () => {
                this.getApprovalRequestsByUser();
            });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, errorMessage, successMessage, approvalData } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                        {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Users Withdrawal Amount Approval</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h3>Approvals</h3>
                                                    <table className="table table-striped responsive-table">
                                                        <thead>
                                                            <tr>
                                                                <th>S#</th>
                                                                <th>Receiving Details</th>
                                                                <th>Amount</th>
                                                                <th>Original Amount</th>
                                                                <th>Status</th>
                                                                <th>Requested On</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {approvalData && approvalData.length > 0 ? (
                                                                <>
                                                                    {approvalData.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td style={{whiteSpace: 'pre-line'}}>
                                                                                {item.receivingDetails !== undefined ?
                                                                                    item.receivingDetails.bankDetails !== undefined && item.receivingDetails.bankDetails.cardNumber !== undefined ?
                                                                                        `Card Holder Name: ${item.receivingDetails.bankDetails.cardHolderName}
                                                                                         Card Number: ${item.receivingDetails.bankDetails.cardNumber}
                                                                                         Expiry Date: ${moment(item.receivingDetails.bankDetails.cardExpiryDate).format('MMM YYYY')}`
                                                                                        :
                                                                                        `Wallet Address: ${item.receivingDetails.walletAddress}
                                                                                        Network Type: ${item.receivingDetails.networkType}`
                                                                                    : "Receiving details not shared yet."}
                                                                            </td>
                                                                            <td>{"$ " + item.amountInUsd}</td>
                                                                            <td>{item.originalAmountInWithDrawalCurrency + " " + item.withdrawCurrency}</td>
                                                                            <td style={{
                                                                                fontWeight: 'bold',
                                                                                border: '1px solid #ccc',
                                                                                ...(item.status === 'approved' ? { color: '#2ecc71', borderColor: '#2ecc71' } : {}),
                                                                                ...(item.status === 'rejected' ? { color: '#e74c3c', borderColor: '#e74c3c' } : {}),
                                                                                ...(item.status === 'pending' ? { color: '#f39c12', borderColor: '#f39c12' } : {}),
                                                                            }}>
                                                                                {item.status}
                                                                            </td>
                                                                            <td>{moment(item.datetime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                            <td>
                                                                                {/* <button className="btn btn-info" disabled={item.status === 'approved'}
                                                                                    style={iconButtonStyle}>
                                                                                    <i className="icofont-eye"></i>
                                                                                </button>{" "} */}
                                                                                <button className="btn btn-success" disabled={item.status !== 'pending'}
                                                                                    style={iconButtonStyle}
                                                                                    onClick={() => { this.changeStatus(item._id) }}>
                                                                                    <i className="icofont-tick-mark"></i>
                                                                                </button>{" "}
                                                                                <button className="btn btn-danger" disabled={item.status !== 'pending'}
                                                                                    style={iconButtonStyle}
                                                                                    onClick={() => { this.changeStatus(item._id, 'reject') }}>
                                                                                    <i className="icofont-close-circled"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="100%" className="text-center text-muted">Data not found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const iconButtonStyle = { width: '30px', height: '30px', padding: '5px', };