import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import service from "../../services/service";
import LoadingOverlay from '../../components/loading';

class DepositMoney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      depositCurrency: '',
      amountInUsd: 0,
      _id: sessionStorage.getItem('userId') ?? null,
      originalAmountInDepositedCurrency: 0,
      charge: 0,
      payable: 0,
      showChargeAndPayable: false,
      loading: false,
      errorMessage: "",
      successMessage: "",
      paymentMethods: [],
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    let charge = 0;
    let payable = 2;

    if (value >= 10 && value <= 1000000) {
      charge = 0;
      payable = value;
    }

    this.setState({
      [name]: value,
      charge,
      payable,
      showChargeAndPayable: value !== "" && value >= 10 && value <= 1000000,
    });
  };

  DepositMoney = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    try {
      const { depositCurrency, amountInUsd, _id, charge, payable, showChargeAndPayable } = this.state;
      const apiKey = "e5f69364b5ee090b84eec57e366c054ff58336182708e7fa0a7b92ac1329e42c";
      const apiUrl = `https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=${depositCurrency}&api_key=${apiKey}`;

      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error("Failed to fetch exchange rate");
      }

      const data = await response.json();
      const rate = data[depositCurrency];
      const originalAmountInDepositedCurrency = amountInUsd * rate;

      const userData = { depositCurrency, amountInUsd, _id, originalAmountInDepositedCurrency, charge, payable, showChargeAndPayable };
      const saveResponse = await service.save(userData, 'deposits');

      if (!saveResponse.success) {
        throw new Error(saveResponse.message);
      }

      this.setState({ errorMessage: "", successMessage: saveResponse.message });
      window.location.href = "/deposit-confirm/" + saveResponse.depositId;
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.getPaymentMethods();
  }

  getPaymentMethods = async () => {
    this.setState({ loading: true });
    try {
      const response = await service.view("payment-methods/");
      if (response && !response.success) {
        this.setState({ successMessage: "", errorMessage: response.message });
        return;
      }
      this.setState({ paymentMethods: response.data.paymentMethods });
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    const { showChargeAndPayable, charge, payable, loading, errorMessage, successMessage, paymentMethods } = this.state;
    return (
      <>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container">
            {loading && <LoadingOverlay />}
            <div className="row">
              <div className="col-xxl-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Transaction</h4>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-lg-6">
                          <form onSubmit={this.DepositMoney} method="POST">
                            {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                            {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                            <div className="card custom--card">
                              <div className="card-header">
                                <h4 className="card-title">Deposit Money</h4>
                              </div>
                              <div className="card-body">
                                <div className="form-group">
                                  <label className="form-label">
                                    Select Gateway
                                  </label>
                                  <select
                                    className="form-control"
                                    name="depositCurrency"
                                    required
                                    onChange={this.handleInputChange}
                                  >
                                    <option value="">Select One</option>
                                    {paymentMethods.length === 0 ? (<></>) : (
                                      paymentMethods.map((paymentMethod, index) => (
                                        <option key={index} value={paymentMethod.shorthand}>{paymentMethod.name}</option>
                                      ))
                                    )}
                                  </select>
                                </div>
                                <div className="form-group">
                                  <label className="form-label">Amount</label>
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      min="0"
                                      max="1000000"
                                      name="amountInUsd"
                                      onChange={this.handleInputChange}
                                      className="form-control cmn--form--control"
                                      autoComplete="off"
                                      required
                                    />
                                    <span style={{ color: "black", }} className="input-group-text">USD</span>
                                  </div>
                                </div><br />
                                {showChargeAndPayable && (
                                  <div>
                                    <div className="form-group">
                                      <label className="form-label">Limit</label>
                                      <div>10.00 USD - 1000000.00 USD</div>
                                    </div><hr />
                                    <div className="form-group">
                                      <label className="form-label">Charge</label>
                                      <div>{charge} USD</div>
                                    </div><hr />
                                    <div className="form-group">
                                      <label className="form-label">Payable</label>
                                      <div>{payable} USD</div>
                                    </div><hr />
                                  </div>
                                )}
                                <button type="submit" className="cmn--btn btn-block btn btn-success waves-effect">
                                  Deposit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {loading && <LoadingOverlay />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default DepositMoney;
