import React, { Component } from 'react';
import Header from '../../layout/header';
import Sidebar from '../../layout/sidebar';
import service from '../../services/service';
import LoadingOverlay from '../loading';
import moment from 'moment';

class WithdrawHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: sessionStorage.getItem('userId') || null,
            withdrawId: props.match.params.withdrawId || null,
            loading: false,
            successMessage: '',
            errorMessage: '',
            withdrawHistory: [],
        };
    }

    componentDidMount() {
        this.fetchWithdrawHistory();
        this.handleSuccessParameter();
    }

    fetchWithdrawHistory = async () => {
        const { userId } = this.state;
        if (!userId) return;

        this.setState({ loading: true });
        try {
            const response = await service.view(`withdraws/${userId}`);
            if (response?.success) {
                this.setState({ withdrawHistory: response.data.withdraws });
            } else {
                this.setState({ errorMessage: response?.message ?? 'Failed to fetch withdrawal history' });
            }
        } catch (error) {
            console.error('Error fetching withdrawal history:', error);
            this.setState({ errorMessage: 'An error occurred while fetching withdrawal history' });
        } finally {
            this.setState({ loading: false });
        }
    };

    handleSuccessParameter = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const success = searchParams.get('success');

        if (success === 'true') {
            this.setState({
                successMessage: 'SUCCESS: Withdraw Request Sent!',
            });
        } else if (success === 'false') {
            this.setState({
                errorMessage: 'ERROR: Withdraw Request not Sent!',
            });
        }
    };

    redirectToWithdrawConfirmPage = async (withdrawId) => {
        if (!withdrawId) return;

        this.setState({ loading: true });
        try {
            const withdraws = await service.view(`withdraws/withdrawsByWithdrawId/${withdrawId}`);
            if (!withdraws.success) {
                this.setState({ errorMessage: withdraws.message });
                return;
            }

            const { withdrawCurrency } = withdraws.data.withdraws;
            if (withdrawCurrency === "USD") {
                window.location.href = `/usdt-withdraw/${withdrawId}`;
            } else if (withdrawCurrency === "BankTransfer") {
                window.location.href = `/bank-withdraw/${withdrawId}`;
            }
        } catch (error) {
            console.error('Error redirecting to withdrawal confirmation page:', error);
            this.setState({ errorMessage: 'An error occurred while redirecting' });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { loading, errorMessage, successMessage, withdrawHistory } = this.state;

        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="card">
                                    <div className="card-header"><h4 className="card-title">Withdraw Transactions Log</h4></div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th>S#</th>
                                                        <th>Transaction Time</th>
                                                        <th>Withdraw Amount</th>
                                                        <th>Amount in USD</th>
                                                        <th>Status</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {withdrawHistory.length === 0 ? (
                                                        <tr><td colSpan="7" className="text-center text-muted">Data not found</td></tr>
                                                    ) : (
                                                        withdrawHistory.map((withdraw, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{moment(withdraw.datetime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                <td>{withdraw.originalAmountInWithDrawalCurrency + " " + withdraw.withdrawCurrency}</td>
                                                                <td>{"$ " + withdraw.amountInUsd}</td>
                                                                <td style={{
                                                                    fontWeight: 'bold',
                                                                    border: '1px solid #ccc',
                                                                    color: withdraw.status === 'approved' ? '#2ecc71' : withdraw.status === 'rejected' ? '#e74c3c' : withdraw.status === 'pending' ? '#f39c12' : '',
                                                                }}>{withdraw.status}</td>
                                                                <td>
                                                                    {withdraw.receivingDetails === null || withdraw.receivingDetails === undefined ?
                                                                        (<>
                                                                            <div>
                                                                                <p>Receiving Details Not Shared</p>
                                                                                <button className="cmn--btn btn-block btn btn-success waves-effect" onClick={() => this.redirectToWithdrawConfirmPage(withdraw._id)}>Share Details</button>
                                                                            </div>
                                                                        </>)
                                                                        :
                                                                        (<><div><p>Receiving Details Shared</p></div></>)
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default WithdrawHistory;
