import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import LoadingOverlay from '../loading';
import service from "../../services/service";
import moment from 'moment';
import { IMAGE_PATH_COMPELETE } from "../../config";

export default class DepositApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            userId: props.match.params.userId ?? null,
            loading: false,
            errorMessage: "",
            successMessage: "",
            approvalData: [],
            showModal: false,
            imageParameter: ''
        };
    }

    handleButtonClick = (imageParam) => {
        this.setState({
            imageParameter: imageParam,
            showModal: true
        });
    };

    handleCloseModal = () => {
        this.setState({ showModal: false });
    };

    componentDidMount() { if (this.state.userId !== null) this.getApprovalRequestsByUser(); }

    getApprovalRequestsByUser = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`admin/users/${this.state.userId}/deposits`)
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message });
                return;
            }
            this.setState({ approvalData: response.data.deposits })
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    changeStatus = async (depositId, status = 'approve') => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`admin/deposits/${depositId}/${status}`);
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message + ": " + response.error });
                return;
            }
            this.setState({ successMessage: response.message, errorMessage: '' }, () => {
                this.getApprovalRequestsByUser();
            });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, errorMessage, successMessage, approvalData, showModal, imageParameter } = this.state;
        console.log('showModal', showModal)
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                        {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}

                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Users Deposited Amount Approval</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h3>Approvals</h3>
                                                    <table className="table table-striped responsive-table">
                                                        <thead>
                                                            <tr>
                                                                <th>S#</th>
                                                                <th>User Email</th>
                                                                <th>Amount in USD</th>
                                                                <th>Original Amount</th>
                                                                <th>Status</th>
                                                                <th>Requested On</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {approvalData && approvalData.length > 0 ? (
                                                                <>
                                                                    {approvalData.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{item.userEmail}</td>
                                                                            <td>{"$ " + item.amountInUsd}</td>
                                                                            <td>{item.originalAmountInDepositedCurrency + " " + item.depositCurrency}</td>
                                                                            <td style={{
                                                                                fontWeight: 'bold',
                                                                                border: '1px solid #ccc',
                                                                                ...(item.status === 'approved' ? { color: '#2ecc71', borderColor: '#2ecc71' } : {}),
                                                                                ...(item.status === 'rejected' ? { color: '#e74c3c', borderColor: '#e74c3c' } : {}),
                                                                                ...(item.status === 'pending' ? { color: '#f39c12', borderColor: '#f39c12' } : {}),
                                                                            }}>
                                                                                {item.status}
                                                                            </td>
                                                                            <td>{moment(item.datetime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                            <td>
                                                                                <button className="btn btn-info" disabled={item.status !== 'pending'}
                                                                                    style={iconButtonStyle}
                                                                                    onClick={() => this.handleButtonClick(item.transactionScreenshotPath)}
                                                                                >
                                                                                    <i className="icofont-eye"></i>
                                                                                </button>{" "}
                                                                                <button className="btn btn-success" disabled={item.status !== 'pending'}
                                                                                    style={iconButtonStyle}
                                                                                    onClick={() => { this.changeStatus(item._id) }}>
                                                                                    <i className="icofont-tick-mark"></i>
                                                                                </button>{" "}
                                                                                <button className="btn btn-danger" disabled={item.status !== 'pending'}
                                                                                    style={iconButtonStyle}
                                                                                    onClick={() => { this.changeStatus(item._id, 'reject') }}>
                                                                                    <i className="icofont-close-circled"></i>
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="100%" className="text-center text-muted">Data not found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                    <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
                            {imageParameter !== undefined ?
                                (<><img src={`${IMAGE_PATH_COMPELETE}${imageParameter.replace(/\\/g, '/')}`} width={450} height={450} alt="Transaction Receipt" />
                                </>) : (<span>Transaction Receipt Not Uploaded Yet.</span>)}
                            <div style={{ display: 'flex', justifyContent: 'right', marginTop: '20px' }}>
                                <button style={{ marginRight: '10px', backgroundColor: '#f44336', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }} onClick={this.handleCloseModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}

const iconButtonStyle = { width: '30px', height: '30px', padding: '5px', };