import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import service from "../../services/service";
import LoadingOverlay from '../loading';

class WithdrawMoney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountInUsd: 0,
      withdrawMethod: '',
      _id: sessionStorage.getItem('userId') ?? null,
      // originalAmountInWithDrawalCurrency: 0,
      charge: 0,
      receivable: 0,
      showChargeAndReceivable: false,
      loading: false,
      errorMessage: "",
      successMessage: "",
      withdraws: {},
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    let charge = 0;
    let receivable = 2;

    if (value >= 10 && value <= 1000000) {
      charge = 0;
      receivable = value;
    }

    this.setState({
      [name]: value,
      charge,
      receivable,
      showChargeAndReceivable: value !== "" && value >= 10 && value <= 1000000,
    });
  };

  WithdrawMoney = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    try {
      const { amountInUsd, _id, withdrawMethod } = this.state;
      const { withdrawCurrency, originalAmountInWithDrawalCurrency } = this.calculateWithdrawalDetails(amountInUsd);

      const userData = { amountInUsd, _id, originalAmountInWithDrawalCurrency, withdrawCurrency };
      const response = await service.save(userData, 'withdraws');

      const { success, message, withdrawId } = response || {};
      const redirectMethod = this.getRedirectMethod(withdrawMethod);

      if (!success) {
        this.setState({ errorMessage: message, successMessage: "" });
      } else {
        this.setState({ errorMessage: "", successMessage: message });
        if (redirectMethod) window.location.href = redirectMethod + withdrawId;
      }
    } catch (error) {
      this.setState({ errorMessage: error.message, successMessage: "" });
    } finally {
      this.setState({ loading: false });
    }
  };

  calculateWithdrawalDetails = (amountInUsd) => {
    let originalAmountInWithDrawalCurrency = amountInUsd, withdrawCurrency = 'USD';
    return { withdrawCurrency, originalAmountInWithDrawalCurrency };
  };

  getRedirectMethod = (withdrawMethod) => {
    if (withdrawMethod === 'BankTransfer') return "/bank-withdraw/";
    if (withdrawMethod === 'USDT') return "/usdt-withdraw/";
    return null;
  };


  render() {
    const { showChargeAndReceivable, charge, receivable, loading, errorMessage, successMessage } = this.state;
    return (
      <>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container">
            {loading && <LoadingOverlay />}
            <div className="row">
              <div className="col-xxl-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Transaction</h4>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-lg-6">
                          {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                          {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                          <form onSubmit={this.WithdrawMoney} method="POST">
                            <div className="card custom--card">
                              <div className="card-header">
                                <h4 className="card-title">Withdraw Money</h4>
                              </div>
                              <div className="card-body">
                                <div className="form-group">
                                  <label className="form-label">
                                    Select Method
                                  </label>
                                  <select
                                    className="form-control"
                                    name="withdrawMethod"
                                    required
                                    onChange={this.handleInputChange}
                                  >
                                    <option value="">Select One</option>
                                    <option value="BankTransfer">Bank Transfer</option>
                                    <option value="USDT">USDT</option>
                                  </select>
                                </div><br />
                                <div className="form-group">
                                  <label className="form-label">Amount</label>
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      min="0"
                                      max="1000000"
                                      name="amountInUsd"
                                      onChange={this.handleInputChange}
                                      className="form-control cmn--form--control"
                                      autoComplete="off"
                                      required
                                    />
                                    <span style={{ color: "black", }} className="input-group-text">USD</span>
                                  </div>
                                </div><br />
                                {showChargeAndReceivable && (
                                  <div>
                                    <div className="form-group">
                                      <label className="form-label">Limit</label>
                                      <div>10.00 USD - 1000000.00 USD</div>
                                    </div><hr />
                                    <div className="form-group">
                                      <label className="form-label">Charge</label>
                                      <div>{charge} USD</div>
                                    </div><hr />
                                    <div className="form-group">
                                      <label className="form-label">Receivable</label>
                                      <div>{receivable} USD</div>
                                    </div><hr />
                                  </div>
                                )}
                                <button type="submit" className="cmn--btn btn-block btn btn-success waves-effect">
                                  Withdraw
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {loading && <LoadingOverlay />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WithdrawMoney;
