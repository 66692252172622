// const API_BASE_URL = 'http://localhost:5000/api/';
// const FE_BASE_URL = 'http://localhost:3000/';
// const IMAGE_PATH_COMPELETE = 'http://localhost:5000/'

const API_BASE_URL = '/api/';
const FE_BASE_URL = '/';
const IMAGE_PATH_COMPELETE = 'https://cinex.live/'

const COUNTRIES_API_KEY = 'QUtQMnJqQVNodVNhZlZpT2RDTXZCRFI5SXJwQ29yM29JTlZTVjZPVg==';

const CoinLibAPIKey = '04a866eb7b64e959';
const CoinLibAPI = 'https://coinlib.io/api/v1/';

const TradeMadeAPIKey = 'q9IwersUO9kP-bvpT4TF';

const TwelweDataAPIKey = '1588a6edd87d4991aa2c239dc2fb5889';

export {
    API_BASE_URL, FE_BASE_URL, COUNTRIES_API_KEY,
    CoinLibAPIKey, CoinLibAPI,
    TradeMadeAPIKey, 
    TwelweDataAPIKey, IMAGE_PATH_COMPELETE
};