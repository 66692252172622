import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FE_BASE_URL, API_BASE_URL } from '../config';

class EmailVerify extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validUrl: true,
		};
	}

	componentDidMount = async () => {
		const { id, token } = this.props.match.params;
		await fetch(`${API_BASE_URL}auth/${id}/verify/${token}`);
	}

	render() {
		const { validUrl } = this.state;

		return (
			<React.Fragment>
				{validUrl ? (
					<div style={{
						width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column',
					}}>
						<img src={`${FE_BASE_URL}assets/images/success.png`} alt="success_img" style={{ maxWidth: '100%', height: 'auto' }} />
						<h1>Email verified successfully</h1>
						<Link to="/signin">
							<button style={{
								border: 'none', outline: 'none', padding: '12px 0', backgroundColor: '#3bb19b', borderRadius: '20px', width: '180px', fontWeight: 'bold', fontSize: '14px', cursor: 'pointer',
							}}>Login</button>
						</Link>
					</div>
				) : (
					<h1>404 Not Found</h1>
				)}
			</React.Fragment>
		);
	}
}

export default EmailVerify;
