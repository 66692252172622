import React, { Component } from 'react';
import Header from '../../layout/header';
import Sidebar from '../../layout/sidebar';
import LoadingOverlay from '../loading';
import service from '../../services/service';
import moment from 'moment';

export default class UsersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            loading: false,
            errorMessage: "",
            successMessage: "",
            users: [],
            showBanned: false,
            showUnverified: false,
            searchQuery: '',
        };
    }

    fetchUsers = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view('admin/users');
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message + ": " + response.error });
                return;
            }
            this.setState({ users: response.data.users });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    getFilteredUsers = () => {
        const { users, showBanned, showUnverified, searchQuery } = this.state;
        return users.filter(user => {
            const isBanned = user.blocked;
            const isUnverified = !user.emailVerified;

            // Filter based on search query
            const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
            const email = user.email.toLowerCase();
            const contactNumber = user.contact_number.toLowerCase();

            if (
                searchQuery &&
                !(fullName.includes(searchQuery.toLowerCase()) ||
                    email.includes(searchQuery.toLowerCase()) ||
                    contactNumber.includes(searchQuery.toLowerCase()))
            ) {
                return false;
            }

            if (showBanned && isBanned) {
                return true; // Include banned users when "Show Banned" filter is active
            }

            if (showUnverified && isUnverified) {
                return true; // Include unverified users when "Show Unverified" filter is active
            }

            // Include active users only when both "Show Banned" and "Show Unverified" filters are inactive
            return !showBanned && !showUnverified && !isBanned && !isUnverified;
        });
    }

    handleSearchChange = (event) => {
        this.setState({ searchQuery: event.target.value });
    }

    toggleBannedFilter = () => {
        this.setState(prevState => ({
            showBanned: !prevState.showBanned,
            showUnverified: false // Reset showUnverified when showBanned is toggled
        }), () => {
            this.getFilteredUsers();
        });
    }

    toggleUnverifiedFilter = () => {
        this.setState(prevState => ({
            showUnverified: !prevState.showUnverified,
            showBanned: false // Reset showBanned when showUnverified is toggled
        }), () => {
            this.getFilteredUsers();
        });
    }

    verifyEmail = async (userId) => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`admin/verify-email/${userId}`);
            if (response && !response.success) {
                this.setState({ successMessage: '', errorMessage: response.message });
                return;
            }
            sessionStorage.setItem('successMessage', response.message);
            window.location.reload();
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.fetchUsers();
        const successMessage = sessionStorage.getItem('successMessage');
        if (successMessage) {
            this.setState({ successMessage });
            sessionStorage.removeItem('successMessage');
        }
    }

    redirectToEditUserPage(userId) { window.location.href = "update-user/" + userId; }

    render() {
        const { loading, errorMessage, successMessage, showBanned, showUnverified, searchQuery } = this.state;
        const filteredUsers = this.getFilteredUsers();

        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Users</h4>
                                    </div>
                                    <div className="card-header">
                                        <div className="d-flex flex-wrap gap-12">
                                            <div className="flex-grow-1">
                                                <label>Search by Name, Email, Contact Number</label>
                                                <input type="text" name="search"
                                                    placeholder='Type here to search...'
                                                    value={searchQuery} onChange={this.handleSearchChange} className="form-control cmn--form--control" />
                                            </div>
                                        </div>
                                        <div className="filter-buttons">
                                            <button className='btn btn-primary' onClick={this.toggleBannedFilter}>
                                                <i className='icofont-ban'></i>{showBanned ? ' Hide Banned' : ' Show Banned'}
                                            </button>{" "}
                                            <button className='btn btn-primary' onClick={this.toggleUnverifiedFilter}>
                                                <i className='icofont-ban'></i>{showUnverified ? ' Hide Unverified' : ' Show Unverified'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th>S#</th>
                                                        <th>User</th>
                                                        <th>Email</th>
                                                        <th>Contact Number</th>
                                                        <th>Password</th>
                                                        <th>Country</th>
                                                        <th>Joined At</th>
                                                        <th>Balance</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredUsers && filteredUsers.length > 0 ? (
                                                        <>
                                                            {filteredUsers.map((user, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{user.firstName + " " + user.lastName}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.contact_number}</td>
                                                                    <td>{user.password}</td>
                                                                    <td>{user.address.country}</td>
                                                                    <td>{moment(user.datetime).format('MMMM Do YYYY, h:mm:ss a') ?? "N/A"}</td>
                                                                    <td>{"$ " + Number(user.currentBalance).toFixed(2)}</td>
                                                                    <td>
                                                                        <button className="btn btn-info" style={iconButtonStyle} title='View Details'
                                                                            onClick={() => this.redirectToEditUserPage(user._id)}>
                                                                            <i className="icofont-eye-open"></i>
                                                                        </button>&nbsp;
                                                                        {user.emailVerified === false ? (<>
                                                                            <button className="btn btn-success" style={iconButtonStyle} title='Verify Email'
                                                                                onClick={() => this.verifyEmail(user._id)}>
                                                                                <i className="icofont-tick-mark"></i>
                                                                            </button>
                                                                        </>) : (<></>)}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <tr><td colSpan="100%" className="text-center text-muted">Data not found</td></tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const iconButtonStyle = { width: '30px', height: '30px', padding: '5px', };