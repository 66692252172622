import { COUNTRIES_API_KEY } from '../config'

const thirdPartyAPIs = {
    getCountriesFromAPI: async () => {
        const url = "https://api.countrystatecity.in/v1/countries";
        const apiKey = COUNTRIES_API_KEY;

        const requestOptions = {
            method: 'GET',
            headers: {
                "X-CSCAPI-KEY": apiKey
            },
            redirect: 'follow'
        };

        try {
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching countries:', error);
            throw error;
        }
    },

    getStatesForCountry: async (selectedCountry) => {
        const url = `https://api.countrystatecity.in/v1/countries/${selectedCountry}/states`;

        const requestOptions = {
            method: 'GET',
            headers: {
                "X-CSCAPI-KEY": COUNTRIES_API_KEY
            },
            redirect: 'follow'
        };

        try {
            const response = await fetch(url, requestOptions);
            const states = await response.json();
            return states;
        } catch (error) {
            console.error('Error fetching states:', error);
            throw error;
        }
    },

    getCitiesForState: async (selectedCountry, selectedState) => {
        const url = `https://api.countrystatecity.in/v1/countries/${selectedCountry}/states/${selectedState}/cities`;

        const requestOptions = {
            method: 'GET',
            headers: {
                "X-CSCAPI-KEY": COUNTRIES_API_KEY
            },
            redirect: 'follow'
        };

        try {
            const response = await fetch(url, requestOptions);
            const cities = await response.json();
            return cities;
        } catch (error) {
            console.error('Error fetching cities:', error);
            throw error;
        }
    },

    getTradeCurrentPrice: async (symbol, toCurrency="usd") => {
        const url = `https://api.coingecko.com/api/v3/simple/price?ids=${symbol}&vs_currencies=${toCurrency}`;
        const requestOptions = { method: 'GET', redirect: 'follow' };
        try {
            const response = await fetch(url, requestOptions);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },
};

export default thirdPartyAPIs;