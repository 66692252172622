import React, { Component } from 'react';
import Header from '../../layout/header';
import Sidebar from '../../layout/sidebar';
import LoadingOverlay from '../loading';
import service from '../../services/service';
import ConfirmationModal from '../ConfirmationModal';
export default class AssetsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            loading: false,
            success: '',
            errorMessage: "",
            successMessage: "",
            assets: [],
            isConfirmationModalOpen: false,
            assetToDeleteId: null,
        };
    }

    handleDeleteAsset = (assetId) => { this.setState({ isConfirmationModalOpen: true, assetToDeleteId: assetId }); };

    handleCancelDelete = () => { this.setState({ isConfirmationModalOpen: false, assetToDeleteId: null, }); };

    handleConfirmDelete = async () => {
        this.setState({ loading: true });
        const { assetToDeleteId } = this.state;
        try {
            const response = await service.view('assets/' + assetToDeleteId, { method: 'DELETE' });
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message });
                return;
            }
            this.setState({ successMessage: 'Asset Deleted Successfully', errorMessage: '' });
            window.location.href = '/admin/assets-list';
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ isConfirmationModalOpen: false, assetToDeleteId: null, loading: false });
        }
    };

    fetchAssets = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view('assets');
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message + ": " + response.error });
                return;
            }
            this.setState({ assets: response.data.assets });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.fetchAssets();
    }

    redirectToEditAssetPage(assetId) { window.location.href = "asset-form/" + assetId; }

    render() {
        const { loading, errorMessage, successMessage, assets } = this.state;

        return (
            <>
                {/* Header and Sidebar components remain unchanged */}
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-12 mb-3"><button className="btn btn-primary" onClick={() => {
                                window.location.href = '/admin/asset-form'
                            }}><i className="icofont-plus"></i>
                                Add New Asset </button></div>
                            <div className="col-xxl-12 col-xl-12">
                                {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Assets List</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th>S#</th>
                                                        <th>Asset</th>
                                                        <th>Short Hand</th>
                                                        <th>Symbol</th>
                                                        <th>Class</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {assets && assets.length > 0 ? (
                                                        <>
                                                            {assets.map((asset, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{asset.name}</td>
                                                                    <td>{asset.shorthand}</td>
                                                                    <td>{asset.symbol}</td>
                                                                    <td>{asset.assetClass}</td>
                                                                    <td>
                                                                        <button className="btn btn-success"
                                                                            style={{ width: '30px', height: '30px', padding: '5px', }}
                                                                            onClick={() => this.redirectToEditAssetPage(asset._id)}>
                                                                            <i className="icofont-eye-open"></i>
                                                                        </button>{" "}
                                                                        <button className="btn btn-danger"
                                                                            style={{ width: '30px', height: '30px', padding: '5px', }}
                                                                            onClick={() => this.handleDeleteAsset(asset._id)}>
                                                                            <i className="icofont-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="100%" className="text-center text-muted">Data not found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmationModal isOpen={this.state.isConfirmationModalOpen} onCancel={this.handleCancelDelete}
                    onConfirm={this.handleConfirmDelete} modalText="Are you sure you want to delete this asset?" />
            </>
        );
    }
}
