import React, { Component } from 'react';
import Header from '../../layout/header';
import Sidebar from '../../layout/sidebar';
import service from '../../services/service';
import LoadingOverlay from '../loading';
import moment from 'moment';

class DepositHistory extends Component {
    state = {
        userId: sessionStorage.getItem('userId') || null,
        loading: false,
        successMessage: '',
        errorMessage: '',
        depositHistory: [],
    };

    async componentDidMount() {
        this.handleSuccessParameter();
        await this.fetchDepositHistory();
    }

    fetchDepositHistory = async () => {
        const { userId } = this.state;
        if (!userId) return;

        this.setState({ loading: true });
        try {
            const response = await service.view(`deposits/${userId}`);
            if (response?.success) {
                this.setState({ depositHistory: response.data.deposits });
            } else {
                this.setState({ errorMessage: response?.message ?? 'Failed to fetch deposit history' });
            }
        } catch (error) {
            this.setState({ errorMessage: 'An error occurred while fetching deposit history' });
        } finally {
            this.setState({ loading: false });
        }
    };

    handleSuccessParameter = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const success = searchParams.get('success');

        if (success === 'true') {
            this.setState({
                successMessage: 'SUCCESS: Transaction Screenshot Uploaded',
                errorMessage: '',
            });
        } else if (success === 'false') {
            this.setState({
                successMessage: '',
                errorMessage: 'ERROR: Transaction Screenshot Upload Failed',
            });
        }
    };

    redirectToDepositConfirmPage = depositId => {
        window.location.href = `/deposit-confirm/${depositId}`;
    };

    render() {
        const { loading, errorMessage, successMessage, depositHistory } = this.state;

        return (
            <>
                <Header />
                <Sidebar />

                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                        {successMessage && <div className="alert alert-success" role="alert">{successMessage}</div>}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Deposit Transaction Log</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th>S#</th>
                                                        <th>Transaction Time</th>
                                                        <th>Deposit Currency</th>
                                                        <th>Deposited Amount</th>
                                                        <th>Amount in USD</th>
                                                        <th>Status</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {depositHistory.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="7" className="text-center text-muted">Data not found</td>
                                                        </tr>
                                                    ) : (
                                                        depositHistory.map((deposit, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{moment(deposit.datetime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                <td>{deposit.depositCurrency}</td>
                                                                <td>{deposit.originalAmountInDepositedCurrency}</td>
                                                                <td>{"$ " + deposit.amountInUsd}</td>
                                                                <td style={{ fontWeight: 'bold', color: deposit.status === 'approved' ? '#2ecc71' : deposit.status === 'rejected' ? '#e74c3c' : deposit.status === 'pending' ? '#f39c12' : '' }}>{deposit.status}</td>
                                                                <td>
                                                                    {deposit.transactionScreenshotPath ? (
                                                                        <div>
                                                                            <p>Transaction receipt uploaded</p>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <p>No transaction receipt uploaded</p>
                                                                            <button className="cmn--btn btn-block btn btn-success waves-effect" onClick={() => this.redirectToDepositConfirmPage(deposit._id)}>Upload Receipt</button>
                                                                        </div>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DepositHistory;
