import React, { Component } from 'react';
import Header from '../../layout/header';
import Sidebar from '../../layout/sidebar';
import service from '../../services/service';
import LoadingOverlay from '../loading';
import thirdPartyAPIs from '../../services/thirdPartyAPIs';

class PracticeNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            assetId: props.match.params.assetId ?? null,
            tradeId: null,
            loading: false,
            success: '',
            errorMessage: '',
            successMessage: '',
            selectedDuration: null,
            amountInUsd: 0,
            tradeWindow: null,
            seconds: 0,
            currentPrice: "N/A",
            chart: null,
            assetShorthand: "",
            assetIdForAPI: null,
        };
    }
    getAssetShorthand = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`assets/asset-shorthand/${this.state.assetId}`);
            if (response && !response.success) return;
            this.setState({ assetShorthand: response.data.shorthand, assetIdForAPI: response.data.assetIdForAPI })
            const priceResponse = await thirdPartyAPIs.getTradeCurrentPrice(response.data.name);
            if (!priceResponse) return;
            const priceArray = Object.entries(priceResponse).map(([assetName, assetData]) => ({ usd: assetData.usd }));
            this.setState({ currentPrice: `${priceArray[0].usd} USD` })
        } catch (error) { } finally {
            this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.timerInterval = setInterval(this.updateTimer, 1000);
        if (this.state.assetId !== null) this.getAssetShorthand();
    }
    componentWillUnmount() { clearInterval(this.timerInterval); }

    updateTimer = () => {
        this.setState((prevState) => {
            if (prevState.seconds === 0) {
                clearInterval(this.timerInterval);
                if (this.state.tradeId !== null) {
                    this.finaliseTrade(this.state.tradeId);
                }
            } else {
                return { seconds: prevState.seconds - 1 };
            }
        });
    };

    finaliseTrade = async (tradeId) => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`practice-trades/${tradeId}/result`, "POST");
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message + ": " + response.error });
                return;
            }
            this.setState({ completedTrade: response.data.trade, successMessage: '', errorMessage: '' });
            localStorage.removeItem('practiceTradeInfo');
            window.location.href = "/practice-log";
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false })
        }
    }

    handleCardSelect = (duration) => {
        clearInterval(this.timerInterval);
        this.setState({ tradeWindow: duration, seconds: parseInt(duration, 10) });
    };

    handleTradeSubmit = async (event) => {
        event.preventDefault();

        this.setState({ loading: true });

        const chosenResult = event.nativeEvent.submitter.getAttribute('data-value');
        const { assetId, amountInUsd, tradeWindow } = this.state;
        const tradeData = { assetId, amountInUsd, chosenResult, tradeWindow };

        try {
            const response = await service.save(tradeData, "practice-trades/", "POST");

            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: `${response.message} ${response.error ?? ""}` });
                return;
            }

            this.setState({ successMessage: response.message, errorMessage: '', tradeId: response.data.tradeId });

            // Retrieve existing trade info from localStorage
            let tradeInfo = JSON.parse(localStorage.getItem('tradeInfo')) || [];

            // Add new trade information
            tradeInfo.push({
                tradeId: response.data.tradeId,
                tradeWindow: tradeWindow,
                timestamp: new Date().toISOString(),
            });

            // Store updated trade info back to localStorage
            localStorage.setItem('practiceTradeInfo', JSON.stringify(tradeInfo));

            setTimeout(() => { this.setState({ successMessage: '' }); }, 4000);
            // Reset the timer to the selected duration
            if (tradeWindow) {
                const seconds = parseInt(tradeWindow, 10);
                clearInterval(this.timerInterval);
                this.setState({ seconds }, () => { this.timerInterval = setInterval(this.updateTimer, 1000); });
            }
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }


    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    render() {
        const durations = ['300', '120', '60', '30'];
        const { assetIdForAPI, loading, errorMessage, successMessage, amountInUsd, tradeWindow, seconds, tradeId } = this.state;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="container">
                            {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                            {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                            <div className="row gy-4">
                                {durations.map((duration) => (
                                    <div key={duration} className="col-lg-2 col-md-2 col-sm-4 col-xs-4 dt"
                                        onClick={() => this.handleCardSelect(duration)}>
                                        <div style={{
                                            alignItems: 'center', width: '100%', padding: '10px', textAlign: 'center',
                                            backgroundColor: tradeWindow === duration ? '#e6e6e6' : 'white',
                                            border: tradeWindow === duration ? '2px solid #198754' : '1px solid #ddd',
                                            cursor: 'pointer'
                                        }}>
                                            <i className="icofont-clock-time"></i>
                                            <span className="card-title">{" " + duration + " "}seconds</span>
                                        </div>
                                    </div>
                                ))}
                                {tradeId && (<div><p>Time Remaining: {minutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}</p></div>)}
                                <div className="col-lg-12">
                                    <div className="card custom--card">
                                        <div className="card-header">
                                            <h5 className="card-title">Enter amount below to trade</h5>
                                        </div>
                                        <div className="card-body text-center">
                                            <span className="trade-user-price"></span>
                                            <form onSubmit={this.handleTradeSubmit} method='POST'>
                                                <div className="predict-group">
                                                    <div className="input-group">
                                                        <input type="number"
                                                            min="0"
                                                            max="1000000"
                                                            name="amountInUsd"
                                                            onChange={this.handleInputChange}
                                                            className="form-control cmn--form--control"
                                                            autoComplete="off"
                                                            value={amountInUsd}
                                                            required />
                                                        <span style={{ color: 'black' }} className="input-group-text">USD</span>

                                                        <div className="highlow-predict">
                                                            <button style={{
                                                                backgroundColor: '#198754',
                                                                color: 'white',
                                                                border: 'none',
                                                                padding: '10px 20px',
                                                                textAlign: 'center',
                                                                textDecoration: 'none',
                                                                display: 'inline-block',
                                                                fontSize: '16px',
                                                                margin: '4px 2px',
                                                                cursor: 'pointer'
                                                            }}
                                                                className="cmn--btn border-0 btn--danger highlowButton"
                                                                type="submit"
                                                                name="highlow"
                                                                data-value="high">
                                                                <i className="icofont-long-arrow-up"></i> High
                                                            </button>
                                                            <button style={{
                                                                backgroundColor: '#FF0000',
                                                                color: 'white',
                                                                border: 'none',
                                                                padding: '10px 20px',
                                                                textAlign: 'center',
                                                                textDecoration: 'none',
                                                                display: 'inline-block',
                                                                fontSize: '16px',
                                                                margin: '4px 2px',
                                                                cursor: 'pointer'
                                                            }}
                                                                className="cmn--btn border-0 btn--danger highlowButton"
                                                                type="submit"
                                                                name="highlow"
                                                                data-value="low">
                                                                <i className="icofont-long-arrow-down"></i> Low
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="clock w-100 mt-5 text-center"></div>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card custom--card">
                                        <div className="card-body">
                                            {/* <div id="graph"></div> */}
                                            <iframe title='chart-graph' src={`https://widget.coinlib.io/widget?type=chart&theme=light&coin_id=${assetIdForAPI}&pref_coin_id=1505`} width="100%" height="536px" scrolling="auto" marginWidth="0" marginHeight="0" frameBorder="0" border="0" style={{ border: '0', margin: '0', padding: '0', lineHeight: '14px' }}></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PracticeNow;
