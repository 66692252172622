// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget({ assetIdForAPI }) {
    const container = useRef();
    useEffect(() => {
        if (assetIdForAPI && !document.getElementById("tradingview-widget-script")) {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.id = "tradingview-widget-script";

            script.innerHTML = `
            {
              "autosize": true,
              "symbol": "${assetIdForAPI}",
              "interval": "1",
              "timezone": "Etc/UTC",
              "theme": "light",
              "style": "3",
              "locale": "en",
              "enable_publishing": false,
              "allow_symbol_change": true,
              "calendar": false,
              "support_host": "https://www.tradingview.com"
            }`;

            container.current.appendChild(script);
        }
    }, [assetIdForAPI]);


    return (
        <div className="" ref={container} style={{ height: "500px", width: "100%", maxHeight: "none" }}>
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
}

export default memo(TradingViewWidget);
