import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authService from '../services/authService';
import { FE_BASE_URL } from '../config';
import LoadingOverlay from '../components/loading';

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            errorMessage: "",
            successMessage: "",
            loading: false,
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleReset = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const { id, token } = this.props.match.params;
        const { password, confirmPassword } = this.state;
        // Client-side validation: Check if passwords match
        if (password !== confirmPassword) {
            this.setState({ successMessage: "", errorMessage: "Passwords do not match" });
            return;
        }
        try {
            // Make the API call for user registration
            const userData = { id, token, password };
            const result = await authService.users(userData, "change-password");
            if (result && !result.success) {
                this.setState({ successMessage: "", errorMessage: result.errorMessage + " " + result.displayMessage });
                return;
            }
            this.setState({ errorMessage: "", successMessage: result.message + " " + result.displayMessage });
            window.location.href = "/signin";
        } catch (error) {
            console.error('Error during password reset:', error.message);
        } finally {
            this.setState({ loading: false });
        }
    }
    render() {
        const { password, confirmPassword, errorMessage, successMessage, loading } = this.state;
        return (
            <>
                {loading && <LoadingOverlay />}
                <div className="authincation">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-4 col-md-5">
                                <div className="mini-logo text-center my-3">
                                    <Link to={"/"}><img src={`${FE_BASE_URL}assets/images/logo.png`} alt="" /></Link>
                                    <h4 className="card-title mt-3">Change your Password</h4>
                                </div>
                                <div className="auth-form card">
                                    <div className="card-body">
                                        {errorMessage && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>
                                        )}
                                        {successMessage && (
                                            <div className="alert alert-success" role="alert">
                                                {successMessage}
                                            </div>
                                        )}
                                        <form method="POST" className="row g-3" onSubmit={this.handleReset}>
                                            <div className="col-12">
                                                <label className="form-label">New Password</label>
                                                <input className="form-control" placeholder="***********"
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleInputChange}
                                                    required
                                                    autoFocus={true} />
                                            </div>
                                            <div className="col-12">
                                                <label className="form-label">New Password</label>
                                                <input className="form-control" placeholder="***********"
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={this.handleInputChange}
                                                    required />
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-block">Update Password</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}