import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authService from '../services/authService';
import LoadingOverlay from '../components/loading';

export default class Signin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            errorMessage: "",
            loading: false,
            success: props.match.params.success ?? false,
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleLogin = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            const { username, password } = this.state;
            const userData = { username, password };
            const result = await authService.users(userData, "login");
            if (result && !result.success) {
                this.setState({ errorMessage: result.error });
                return;
            }
            sessionStorage.setItem('isLoggedIn', 'true');
            sessionStorage.setItem('userId', result.userId);
            sessionStorage.setItem('isAdmin', result.isAdmin);
            sessionStorage.setItem('token', result.token);
            sessionStorage.setItem("isAdminLoggedInAsUser", false);
            const userRole = result.isAdmin ? "/admin/dashboard" : "/"
            window.location.href = userRole;
        } catch (error) {
            this.setState({ errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { username, password, errorMessage, loading, success } = this.state;
        return (
            <>
                {loading && <LoadingOverlay />}
                <div className="authincation">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-5 col-md-6">
                                <div className="mini-logo text-center my-4">
                                    <Link to={"/"}><img src={"/assets/images/logo.png"} alt="City Index Logo" /></Link>
                                    <h4 className="card-title mt-3">Sign in to City Index</h4>
                                </div>
                                <div className="auth-form card">
                                    <div className="card-body">
                                        <form onSubmit={this.handleLogin} name="myform" className="signin_validate row g-3" action="otp-2">
                                            <div className="text-center mb-3">
                                                {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                                {success && (<div className="alert alert-success" role="alert">
                                                    Account created. Verify email to log in; check junk/spam if not found.
                                                </div>)}
                                            </div>
                                            <div className="col-12">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="johndoe"
                                                    name="username"
                                                    value={username}
                                                    onChange={this.handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-12">
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    name="password"
                                                    value={password}
                                                    onChange={this.handleInputChange}
                                                    required
                                                />
                                            </div>
                                            <div className="col-6">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexSwitchCheckDefault"
                                                    >
                                                        Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6 text-right">
                                                <Link to={"/reset"}>Forgot Password?</Link>
                                            </div>
                                            <div className="text-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-block"
                                                >
                                                    Sign in
                                                </button>
                                            </div>
                                        </form>
                                        <p className="mt-3 mb-0">
                                            Don't have an account?{' '}
                                            <Link className="text-primary" to={"/signup"}>
                                                Sign up
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}