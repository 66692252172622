import React, { Component } from "react";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import LoadingOverlay from '../components/loading';
// import { finaliseTradeIfNeeded } from "../services/tradeService";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersDetails: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.getUserDetailsById();
  }

  getUserDetailsById = async () => {
    const userId = sessionStorage.getItem("userId") ?? null;
    if (userId !== null) {
      this.setState({ loading: true });
      try {
        const response = await authService.getUserDetailsById(userId);
        if (response && response.success) {
          this.setState(prevState => ({
            usersDetails: response.user,
            loading: false,
          }));
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };


  render() {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    const { usersDetails, loading } = this.state;
    return (
      <>
        <Header />
        {isLoggedIn && (<Sidebar />)}
        <div className={isLoggedIn ? "content-body" : ""}>
          {loading && <LoadingOverlay />}
          {isLoggedIn && (
            <div className="container">
              <div className="row">
                {[
                  {
                    value: "$ " + Number(usersDetails.currentBalance).toFixed(2),
                    title: "Balance",
                    goto: "/transaction-log",
                    color: "#3498db",
                    icon: "icofont-money",
                  },
                  {
                    value: "$ " + usersDetails.totalDeposit,
                    title: "Deposit",
                    goto: "/deposit-history",
                    color: "#2ecc71",
                    icon: "icofont-bank-alt",
                  },
                  {
                    value: "$ " + usersDetails.totalWithdrawal,
                    title: "Withdraw",
                    goto: "/withdraw-history",
                    color: "#e74c3c",
                    icon: "icofont-bank",
                  },
                  {
                    value: usersDetails.totalTrades,
                    title: "Trade",
                    goto: "/trade-log",
                    color: "#f39c12",
                    icon: "icofont-chart-bar-graph",
                  },
                  {
                    value: usersDetails.totalWins,
                    title: "Winning",
                    goto: "/trade-log/won",
                    color: "#27ae60",
                    icon: "icofont-trophy",
                  },
                  {
                    value: usersDetails.Totallosses,
                    title: "Losing",
                    goto: "/trade-log/lost",
                    color: "#c0392b",
                    icon: "icofont-warning",
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="col-lg-2 col-md-2 col-sm-4 col-xs-4 dt"
                  >
                    <div
                      className="card dashboardCard"
                      style={{
                        alignItems: "center",
                        backgroundColor: item.color,
                      }}
                    >
                      <span style={{ color: "white" }}>{item.value}</span>
                      <span className="card-title" style={{ color: "white" }}>
                        {item.title}
                      </span>
                      <Link
                        to={item.goto}
                        className="btn-view-all"
                        style={{
                          color: "white",
                          textDecoration: "none",
                          backgroundColor: "#34495e",
                          padding: "5px 10px",
                          borderRadius: "5px",
                        }}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="container">
            <div className="row">
              <div>
                {!isLoggedIn ? (
                  <section className="app-section overflow-hidden pt-120 pb-120">
                    <div className="container">
                      <div className="row align-items-center justify-content-between gy-5">
                        <div className="col-lg-6 col-xl-5">
                          <div className="app-thumb rtl">
                            <img
                              alt=""
                              className="w-100"
                              src="https://gffex.live/assets/images/frontend/app/6056f1d0ac9261616310736.png"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="app-content">
                            <div className="section__header section__header__left">
                              <h3 className="title">
                                Now It&#039;s more easy to make a invest
                              </h3>
                              <p>
                                {" "}
                                There are never the pains of repudiating the
                                advantage, the guilt itself escapes. Easy, it
                                prevents us. To be expected, chosen nothing? You
                                must be happy with the pleasure of those who
                                have less pain! Who but the pain and the
                                pleasure of life, and that escape from flattery,
                                with reason, will make the mistake of making the
                                mistake of hating what it pleases to be? To be
                                taken, by her. Often, pain is an option but
                                there are some minor pains to forgive!
                              </p>
                            </div>
                            <div className="couple--buttons">
                              <Link to={"/signin"}>
                                <span
                                  style={{
                                    backgroundColor: "#3498db",
                                    color: "#fff",
                                    padding: "10px 20px",
                                    borderRadius: "20px",
                                    display: "inline-block",
                                    textDecoration: "none",
                                    border: "1px solid #2980b9",
                                  }}
                                  className="btn"
                                >
                                  <i className="icofont-login"></i> Sign In
                                </span>
                              </Link>{" "}
                              <Link to={"/signup"}>
                                <span
                                  style={{
                                    backgroundColor: "#2ecc71",
                                    color: "#fff",
                                    padding: "10px 20px",
                                    borderRadius: "20px",
                                    display: "inline-block",
                                    textDecoration: "none",
                                    border: "1px solid #27ae60",
                                  }}
                                  className="btn"
                                >
                                  <i className="icofont-contact-add"></i> Sign
                                  Up
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : (
                  <></>
                )}
                <br />
                <div
                  style={{
                    backgroundColor: "#FFFFFF",
                    overflow: "hidden",
                    boxSizing: "border-box",
                    border: "1px solid #56667F",
                    borderRadius: "4px",
                    textAlign: "right",
                    lineHeight: "14px",
                    fontSize: "12px",
                    fontFeatureSettings: "normal",
                    textAdjust: "100%",
                    padding: "1px",
                    margin: "0px",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      height: "40px",
                      padding: "0px",
                      margin: "0px",
                      width: "100%",
                    }}
                  >
                    <iframe
                      src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=light&pref_coin_id=1505&invert_hover=no"
                      width="100%"
                      height="36px"
                      scrolling="auto"
                      marginWidth="0"
                      marginHeight="0"
                      frameBorder="0"
                      title="coinsdata"
                      style={{ border: "0", margin: "0", padding: "0" }}
                    ></iframe>
                  </div>
                </div>

                <br />
                <iframe
                  title="trendTrade"
                  src="https://widget.coinlib.io/widget?type=full_v2&amp;theme=light&amp;cnt=20&amp;pref_coin_id=1505&amp;graph=yes"
                  width="100%"
                  height="1235px"
                  scrolling="auto"
                  marginWidth="0"
                  marginHeight="0"
                  frameBorder="0"
                  border="0"
                  style={{ border: "0", margin: "0", padding: "0" }}
                ></iframe>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}

export default Homepage;
