import React, { Component } from 'react';
import Header from '../layout/header';
import Sidebar from '../layout/sidebar';
import service from '../services/service';
import LoadingOverlay from './loading';
import moment from 'moment';

class TransactionLog extends Component {
    _id = sessionStorage.getItem('userId') ?? null;

    state = {
        loading: false,
        errorMessage: "",
        transactionLog: [],
    };

    componentDidMount() {
        if (this._id !== null) {
            this.transactionDetails();
        }
    }

    transactionDetails = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`auth/transactions-log/${this._id}`);
            this.setState(prevState => ({
                errorMessage: response && !response.success ? response.message : "",
                transactionLog: response && response.success ? response.data.transactionLog : prevState.transactionLog,
                loading: false,
            }));
        } catch (error) {
            this.setState({ errorMessage: error.message, loading: false });
        }
    }

    render() {
        const { loading, errorMessage, transactionLog } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                {errorMessage && <div className="alert alert-danger" role="alert">{errorMessage}</div>}
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Transaction Log</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th>S#</th>
                                                        <th>Transaction Date</th>
                                                        <th>Credit</th>
                                                        <th>Debit</th>
                                                        <th>Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactionLog.length === 0 ? (
                                                        <tr><td colSpan="5" className="text-center text-muted">Data not found</td></tr>
                                                    ) : (
                                                        transactionLog.map((history, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{moment(history.deposit ? history.deposit.datetime : history.withdraw.datetime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                <td>{history.deposit ? history.deposit.amountInUsd : '-'}</td>
                                                                <td>{history.withdraw ? history.withdraw.amountInUsd : '-'}</td>
                                                                <td>{"$ " + history.balance}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TransactionLog;
