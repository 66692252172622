import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import authService from "../services/authService";
import { FE_BASE_URL } from "../config";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userDetail: {},
      seconds: 0,
    };
    this.timerInterval = null;
  }

  handleLogout = () => {
    try {
      const logoutResponse = authService.logout();
      if (logoutResponse.success) window.location.href = "/";
    } catch (error) {
      console.error("Logout failed:", error.message);
    }
  };

  componentDidMount() {
    this.showTimer();
    this.timerInterval = setInterval(this.showTimer, 1000);
    this.getUserDetailsByID();
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  getUserDetailsByID = async () => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    if (isLoggedIn) {
      const userId = sessionStorage.getItem("userId");
      const userDetail = await authService.getUserDetailsById(userId);
      if (userDetail.success) this.setState({ userDetail: userDetail.user });
    }
  };

  handleSwitchToAdmin = () => {
    const isAdminLoggedInAsUser = sessionStorage.getItem("isAdminLoggedInAsUser") === "true";

    if (!isAdminLoggedInAsUser) {
      return; // No need to proceed if not logged in as user
    }

    try {
      sessionStorage.setItem("userId", sessionStorage.getItem('isAdminUserId'));
      sessionStorage.setItem("token", sessionStorage.getItem('isAdminToken'));
      sessionStorage.setItem("isAdmin", true);
      sessionStorage.setItem("isAdminLoggedInAsUser", false);

      sessionStorage.removeItem("isAdminUserId");
      sessionStorage.removeItem("isAdminToken");
      window.location.href = "/admin/dashboard";
    } catch (error) {
      console.error("Error switching to admin:", error);
    }
  }

  showTimer = async () => {
    try {
      const storedTradeInfoString = localStorage.getItem('tradeInfo');
      if (!storedTradeInfoString) {
        clearInterval(this.timerInterval);
        return;
      }

      const tradeInfoArray = JSON.parse(storedTradeInfoString) || [];
      if (tradeInfoArray.length === 0) {
        clearInterval(this.timerInterval);
        return;
      }

      tradeInfoArray.forEach(async (tradeInfo) => {
        const { tradeId, timestamp, tradeWindow } = tradeInfo;
        if (tradeId && timestamp && tradeWindow !== undefined) {
          const timeDifferenceInSeconds = Math.abs((new Date() - new Date(timestamp)) / 1000);
          if (timeDifferenceInSeconds <= tradeWindow) {
            const remainingSeconds = Math.ceil(tradeWindow - timeDifferenceInSeconds);
            this.setState({ seconds: remainingSeconds });
          } else {
            clearInterval(this.timerInterval);
          }
        }
      });
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  render() {
    const { userDetail, seconds } = this.state;
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    const isAdminLoggedInAsUser = sessionStorage.getItem("isAdminLoggedInAsUser") === "true";
    const isAdmin = sessionStorage.getItem("isAdmin") === "true";
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    if (remainingSeconds === 1) { this.setState({ seconds: 0 }); }

    return (
      <>
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="header-content">
                  <div className="header-left">
                    <div className="brand-logo">
                      <Link to={"/"}>
                        <img src={`${FE_BASE_URL}assets/images/logo.png`} alt="City Index" /><span>City Index</span>
                      </Link>
                    </div>
                  </div>

                  <div className="header-right">
                    {isAdminLoggedInAsUser && isLoggedIn ? (
                      <div className="dark-light-toggle">
                        <span style={{ backgroundColor: "#3498db", color: "#fff", padding: "5px 20px", borderRadius: "20px", display: "inline-block", textDecoration: "none", border: "1px solid #2980b9", }}
                          onClick={this.handleSwitchToAdmin}>
                          <i class="icofont-loop"></i> Switch to Admin
                        </span></div>) : (<></>)
                    }

                    {seconds > 0 && (<div className="dark-light-toggle">
                      <span style={{ backgroundColor: "#3498db", color: "#fff", padding: "5px 20px", borderRadius: "20px", display: "inline-block", textDecoration: "none", border: "1px solid #2980b9", }}>
                        Trade Time Remaining: {minutes}:{remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
                      </span>
                    </div>)
                    }

                    {isLoggedIn ? (
                      <>
                        {/* <Dropdown className="notification" alignRight>
                              <Dropdown.Toggle>
                                  <div className="notify-bell" data-toggle="dropdown">
                                      <span><i className="icofont-alarm"></i></span>
                                  </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="notification-list">
                                  <h4>Announcements</h4>
                                  <div className="lists">
                                      <Link to={"#"} className="">
                                          <div className="d-flex align-items-center">
                                              <span className="mr-3 icon success"><i className="icofont-check"></i></span>
                                              <div>
                                                  <p>Account created successfully</p>
                                                  <span>2020-11-04 12:00:23</span>
                                              </div>
                                          </div>
                                      </Link>
                                      <Link to={"#"} className="">
                                          <div className="d-flex align-items-center">
                                              <span className="mr-3 icon fail"><i className="icofont-close"></i></span>
                                              <div>
                                                  <p>2FA verification failed</p>
                                                  <span>2020-11-04 12:00:23</span>
                                              </div>
                                          </div>
                                      </Link>
                                      <Link to={"#"} className="">
                                          <div className="d-flex align-items-center">
                                              <span className="mr-3 icon success"><i className="icofont-check"></i></span>
                                              <div>
                                                  <p>Device confirmation completed</p>
                                                  <span>2020-11-04 12:00:23</span>
                                              </div>
                                          </div>
                                      </Link>
                                      <Link to={"#"} className="">
                                          <div className="d-flex align-items-center">
                                              <span className="mr-3 icon pending"><i className="icofont-warning"></i></span>
                                              <div>
                                                  <p>Phone verification pending</p>
                                                  <span>2020-11-04 12:00:23</span>
                                              </div>
                                          </div>
                                      </Link>

                                      <Link to={"./settings-activity"}>More <i
                                          className="icofont-simple-right"></i>
                                      </Link>

                                  </div>
                              </Dropdown.Menu>
                          </Dropdown> */}

                        <Dropdown className="profile_log" alignright="true">
                          <Dropdown.Toggle>
                            <span className="thumb"><img src={`${FE_BASE_URL}assets/images/profile/3.png`} alt="" width="30" /></span>
                            <span className="arrow"><i className="icofont-angle-down"></i></span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <div className="user-email">
                              <div className="user">
                                <span className="thumb"><img src={`${FE_BASE_URL}assets/images/profile/3.png`} alt="" /></span>
                                <div className="user-info">
                                  <h5>{userDetail.firstName + " " + userDetail.lastName}</h5>
                                  <span>{userDetail.email}</span>
                                </div>
                              </div>
                            </div>
                            {!isAdmin ? (
                              <div className="user-balance">
                                <div className="available">
                                  <p>Current Balance</p>
                                  <span>{Number(userDetail.currentBalance).toFixed(2)}</span>
                                </div>
                                <div className="total">
                                  <p>Total Deposits</p>
                                  <span>{userDetail.totalDeposit}</span>
                                </div>
                              </div>
                            ) : (<></>)}
                            <Link to={"/user/profile"} className="dropdown-item">
                              <i className="icofont-ui-user"></i>Profile
                            </Link>
                            {!isAdmin ? (
                              <Link to={"/customer-support"} className="dropdown-item">
                                <i className="icofont-support"></i>Customer Support
                              </Link>
                            ) : (<></>)}
                            {/* <Link to={"settings-profile"} className="dropdown-item">
                                  <i className="icofont-ui-settings"></i> Setting
                              </Link>
                              <Link to={"settings-activity"} className="dropdown-item">
                                  <i className="icofont-history"></i> Activity
                              </Link>
                              <Link to={"lock"} className="dropdown-item">
                                  <i className="icofont-lock"></i>Lock
                              </Link> */}
                            <Link to={""} className="dropdown-item logout" onClick={this.handleLogout}>
                              <i className="icofont-logout"></i> Logout
                            </Link>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>
                    ) : (
                      <>
                        <div className="auth-buttons">
                          <Link to={"/signin"}>
                            <span
                              style={{
                                backgroundColor: "#3498db",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "20px",
                                display: "inline-block",
                                textDecoration: "none",
                                border: "1px solid #2980b9",
                              }}
                              className="btn"
                            >
                              <i className="icofont-login"></i> Sign In
                            </span>
                          </Link>{" "}
                          <Link to={"/signup"}>
                            <span
                              style={{
                                backgroundColor: "#2ecc71",
                                color: "#fff",
                                padding: "10px 20px",
                                borderRadius: "20px",
                                display: "inline-block",
                                textDecoration: "none",
                                border: "1px solid #27ae60",
                              }}
                              className="btn"
                            >
                              <i className="icofont-contact-add"></i> Sign Up
                            </span>
                          </Link>
                        </div>
                        {/* <div className="search">
                            <form action="#">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search Here" />
                                    <span className="input-group-text"><i className="icofont-search"></i></span>
                                </div>
                            </form>
                        </div> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </>
    );
  }
}

export default Header;
