import { API_BASE_URL } from '../config';

const token = sessionStorage.getItem('token') ?? null;

const service = {
  save: async (userData, API, method = "POST") => {
    try {
      const response = await fetch(`${API_BASE_URL}${API}`, {
        method,
        headers: { 'Content-Type': 'application/json', 'Authorization': `${token}` },
        body: JSON.stringify(userData),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  saveWithFile: async (userData, API, method = "POST") => {
    try {
      const response = await fetch(`${API_BASE_URL}${API}`, {
        method,
        headers: { 'Authorization': `${token}` },
        body: userData,
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  view: async (API, options = { method: "GET", queryParams: {} }) => {
    try {
      const { method } = options;
      // const { method, queryParams } = options;
      // Construct the URL with query parameters if they exist
      // const url = new URL(`${API_BASE_URL}${API}`);
      // if (queryParams && Object.keys(queryParams).length > 0) {
      //   Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
      // }
      const response = await fetch(`${API_BASE_URL}${API}`, {
        method,
        headers: { 'Content-Type': 'application/json', 'Authorization': `${token}` },
      });

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

}

export default service;