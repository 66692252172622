import React, { Component } from 'react';
import Header from '../../layout/header';
import Sidebar from '../../layout/sidebar';
import service from '../../services/service';
import LoadingOverlay from '../loading';
import moment from 'moment';

class TransactionLogAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            userId: props.match.params.userId ?? null,
            loading: false,
            success: '',
            errorMessage: "",
            successMessage: "",
            transactionLog: [],
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    componentDidMount() { if (this.state.userId !== null) this.transactionDetails(); }

    transactionDetails = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view(`auth/transactions-log/${this.state.userId}`);
            if (response && !response.success) {
                this.setState({ errorMessage: response.message, successMessage: "" });
                return;
            }
            this.setState({ transactionLog: response.data.transactionLog })
        } catch (error) {
            this.setState({ errorMessage: error.message, successMessage: "" });
        } finally {
            this.setState({ loading: false });
        }
    }

    handleFilters = (event) => {
        event.preventDefault();
    }

    render() {
        const { loading, errorMessage, successMessage, transactionLog } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Transaction Log</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th>S#</th>
                                                        <th>Transaction Date</th>
                                                        <th>Credit</th>
                                                        <th>Debit</th>
                                                        <th>Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {transactionLog.length === 0 ? (
                                                        <tr><td colSpan="5" className="text-center text-muted">Data not found</td></tr>
                                                    ) : (
                                                        <>
                                                            {transactionLog.map((history, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td> {/* Assuming index is used for transaction number */}
                                                                    <td>{moment(history.deposit ? history.deposit.datetime : history.withdraw.datetime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                    <td>{history.deposit ? history.deposit.amountInUsd : '-'}</td>
                                                                    <td>{history.withdraw ? history.withdraw.amountInUsd : '-'}</td>
                                                                    <td>{"$ " + history.balance}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default TransactionLogAdmin;
