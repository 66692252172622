import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import service from "../../services/service";
import LoadingOverlay from '../loading';

export default class AssetForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            assetId: props.match.params.assetId ?? null,
            updateFields: {},
            loading: false,
            errorMessage: "",
            successMessage: "",
            imageFile: "",
            ...this.getInitialAssetState(),
        };
    }

    getInitialAssetState = () => { return { name: "", shorthand: "", symbol: "", assetClass: "", assetIdForAPI: 0, imagePath: null }; };

    getAssetDetailsByID = async () => {
        this.setState({ loading: true });
        try {
            const assetDetail = await service.view('assets/asset-details/' + this.state.assetId);
            if (!assetDetail.success) {
                this.setState({ successMessage: "", errorMessage: `${assetDetail.message}` });
                return;
            }
            const assetData = assetDetail.data.asset;
            this.setState({ updateFields: assetData, ...assetData });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        this.setState((prevState) => ({
            updateFields: {
                ...prevState.updateFields,
                [name]: value,
            },
        }));
    };

    componentDidMount() {
        if (this.state.assetId) this.getAssetDetailsByID();
    }

    handleFormSubmit = async (event) => {
        event.preventDefault();
        const { assetId, updateFields, imageFile } = this.state;
        this.setState({ loading: true });
        try {
            const formData = new FormData();
            formData.append('name', updateFields.name);
            formData.append('shorthand', updateFields.shorthand);
            formData.append('assetClass', updateFields.assetClass);
            formData.append('symbol', updateFields.symbol);
            formData.append('assetIdForAPI', updateFields.assetIdForAPI);
            formData.append('imagePath', imageFile ? imageFile : updateFields.imagePath);

            const endpoint = assetId ? `assets/${assetId}` : 'assets/';

            const response = await service.saveWithFile(formData, endpoint);

            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message });
                return;
            }

            this.setState({ successMessage: response.message, errorMessage: '' });
            window.location.href = '/admin/assets-list';
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    };


    handleFileChange = (event) => { this.setState({ imageFile: event.target.files[0] }); };

    render() {
        const { loading, imageFile, errorMessage, assetId, successMessage, ...assetData } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="page-title">
                                    <h4>Asset Details</h4>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {/* <h4 className="card-title">Information of { }</h4> */}
                                        {/* <SubMenu /> */}
                                        {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                        {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-12 col-xl-12 col-lg-12">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <form onSubmit={this.handleFormSubmit}>
                                                            <div className="row g-3">
                                                                <div className="col-4">
                                                                    <label className="form-label" htmlFor="name">
                                                                        Name <span className="text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="name"
                                                                        name="name"
                                                                        required={true}
                                                                        value={assetData.name}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                </div>
                                                                <div className="col-4">
                                                                    <label className="form-label" htmlFor="shorthand">
                                                                        Shorthand <span className="text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="shorthand"
                                                                        name="shorthand"
                                                                        required={true}
                                                                        value={assetData.shorthand}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                </div>
                                                                <div className="col-4">
                                                                    <label className="form-label" htmlFor="symbol">
                                                                        Symbol <span className="text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="symbol"
                                                                        name="symbol"
                                                                        required={true}
                                                                        value={assetData.symbol}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                </div>
                                                            </div><br />
                                                            <div className="row g-3">
                                                                <div className="col-3">
                                                                    <label className="form-label" htmlFor="assetClass">
                                                                        Asset Class <span className="text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="assetClass"
                                                                        name="assetClass"
                                                                        required={true}
                                                                        value={assetData.assetClass}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                </div>
                                                                <div className="col-3">
                                                                    <label className="form-label" htmlFor="assetIdForAPI">
                                                                        Asset ID For API
                                                                    </label>
                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        id="assetIdForAPI"
                                                                        name="assetIdForAPI"
                                                                        value={assetData.assetIdForAPI}
                                                                        onChange={this.handleInputChange}
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="form-group">
                                                                        <label className="form-label">
                                                                            Asset Image
                                                                        </label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control form--control"
                                                                            id="imagePath"
                                                                            name="imagePath"
                                                                            required={assetId ? false : true}
                                                                            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                                                                            onChange={this.handleFileChange}
                                                                        />
                                                                        <pre className="text--base mt-1">
                                                                            Supported mimes: jpg, jpeg, png, pdf, doc, docx
                                                                        </pre>
                                                                    </div>
                                                                </div>
                                                            </div><br />
                                                            <div className="col-12">
                                                                <button type="submit" className="btn btn-success waves-effect">
                                                                    <i className="icofont-save"></i>
                                                                    {assetId ? ' Update' : ' Save'}
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}