import React, { Component } from 'react';
import Header from '../../layout/header';
import Sidebar from '../../layout/sidebar';
import service from '../../services/service';
import LoadingOverlay from '../loading';
import moment from 'moment';

export default class PracticeLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            loading: false,
            success: '',
            errorMessage: "",
            successMessage: "",
            practiceHistory: [],
        };
    }

    componentDidMount() { if (this.state._id !== null) this.tradeLog(); }

    tradeLog = async () => {
        this.setState({ loading: true });
        const { _id } = this.state
        try {
            const response = await service.view(`practice-trades/user/${_id}/`, "GET");
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message + ": " + response.error });
                return;
            }
            this.setState({ successMessage: '', errorMessage: '', practiceHistory: response.data.trades });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }


    render() {
        const { loading, errorMessage, successMessage, practiceHistory } = this.state
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                {successMessage && (
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div>
                                )}
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Practice Log</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive transaction-table">
                                            <table className="table table-striped responsive-table">
                                                <thead>
                                                    <tr>
                                                        <th >S#</th>
                                                        <th >Crypto</th>
                                                        <th >Amount</th>
                                                        <th >High/Low</th>
                                                        <th >Result</th>
                                                        <th >Status</th>
                                                        <th >Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {practiceHistory.length === 0 ? (
                                                        <tr>
                                                            <td colSpan="100%" className="text-center text-muted">Data not found</td>
                                                        </tr>
                                                    ) : (
                                                        <>
                                                            {practiceHistory.map((history, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{history.assetName}</td>
                                                                    <td>{"$ " + history.amountInUsd}</td>
                                                                    <td>{history.chosenResult}</td>
                                                                    <td style={{
                                                                        fontWeight: 'bold',
                                                                        border: '1px solid #ccc', // Common border color
                                                                        ...(history.finalResult === 'win' ? { color: '#2ecc71', borderColor: '#2ecc71' } : {}),
                                                                        ...(history.finalResult === 'lose' ? { color: '#e74c3c', borderColor: '#e74c3c' } : {}),
                                                                    }}>
                                                                        {history.finalResult}</td>
                                                                    <td style={{
                                                                        fontWeight: 'bold',
                                                                        border: '1px solid #ccc', // Common border color
                                                                        ...(history.status === 'completed' ? { color: '#2ecc71', borderColor: '#2ecc71' } : {}),
                                                                        ...(history.status === 'pending' ? { color: '#f39c12', borderColor: '#f39c12' } : {}),
                                                                    }}>
                                                                        {history.status}</td>
                                                                    <td>{moment(history.datetime).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                                                </tr>
                                                            ))}
                                                        </>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}