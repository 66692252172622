import React, { Component } from 'react';
import Header from '../layout/header'
import Sidebar from '../layout/sidebar';
import { Link } from "react-router-dom";
import LoadingOverlay from '../components/loading';
import service from "../services/service";

export default class CustomerSupport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
            loading: false,
            errorMessage: "",
            successMessage: "",
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleContactForm = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            const { name, email, message } = this.state;
            const payload = { name, email, message };
            const response = await service.save(payload, "auth/customer-support", "POST");
            if (response && !response.success) {
                this.setState({ errorMessage: response.message, successMessage: "" });
                return;
            }
            this.setState({ errorMessage: "", successMessage: response.message, name: "", email: "", message: "", });
        } catch (error) {
            this.setState({ errorMessage: error.message, successMessage: "" });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { name, email, message, errorMessage, successMessage, loading } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Welcome to our Customer Support </h4>
                                        {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                        {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                                    </div>
                                    <div class="card-body">
                                        <div class="container mt-4">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <h3>Contact us Via</h3>
                                                    <ul class="list-unstyled social-media-list">
                                                        <li><a href="https://telegram.me/Cinexsupport" target="__blank" rel="noopener noreferrer"><i class="icofont-telegram"></i> @Cinexsupport</a></li>
                                                        <li><a href="https://wa.me/13322602486" target="__blank" rel="noopener noreferrer"><i class="icofont-whatsapp"></i> +13322602486</a></li>
                                                    </ul>
                                                </div>
                                                <div class="col-md-8">
                                                    <div class="col-md-6">
                                                        <h3>Send us Email</h3>
                                                        <form method='POST' onSubmit={this.handleContactForm}>
                                                            <div class="mb-3">
                                                                <label class="form-label" for="name">Name</label>
                                                                <input class="form-control" onChange={this.handleInputChange} type="text" name="name" id="name" placeholder='John Doe' value={name} required />
                                                            </div>
                                                            <div class="mb-3">
                                                                <label class="form-label" for="email">Preferred Contact Email</label>
                                                                <input class="form-control" onChange={this.handleInputChange} type="email" name="email" id="email" placeholder='abc@example.com' value={email} required />
                                                            </div>
                                                            <div class="mb-3">
                                                                <label class="form-label" for="message">Message</label>
                                                                <input class="form-control" onChange={this.handleInputChange} name="message" id="message" placeholder='Type your message here' value={message} required />
                                                            </div>
                                                            <button class="btn btn-success" type="submit">Submit</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};