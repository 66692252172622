import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import LoadingOverlay from "../loading";
import authService from "../../services/authService";
import service from "../../services/service";
import thirdPartyAPIs from "../../services/thirdPartyAPIs";

export default class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: sessionStorage.getItem("userId") ?? null,
      userId: props.match.params.userId,
      trade_30_seconds: 0,
      trade_60_seconds: 0,
      trade_120_seconds: 0,
      trade_300_seconds: 0,
      result: "",
      amountInUsd: 0,
      isModalOpen: false,
      buttonValue: null,
      countries: [],
      states: [],
      cities: [],
      loading: false,
      errorMessage: "",
      successMessage: "",
      updateFields: {},
      ...this.getInitialUserState(),
    };
  }

  openModal = (buttonValue) => { this.setState({ isModalOpen: true, buttonValue }); };
  closeModal = () => { this.setState({ isModalOpen: false }); };

  getInitialUserState = () => {
    return {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      contact_number: "",
    };
  };

  getUserDetailsByID = async () => {
    this.setState({ loading: true });
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
    try {
      if (isLoggedIn) {
        const userId = this.state.userId;
        const userDetail = await authService.getUserDetailsById(userId);
        const response = await service.view(`admin/users/${userId}/`, "GET");

        if (!userDetail.success) {
          this.setState({
            successMessage: "",
            errorMessage: `${userDetail.message}: ${userDetail.error}`,
          });
          return;
        }

        const { user } = userDetail;
        const countries = await thirdPartyAPIs.getCountriesFromAPI();
        const states = await thirdPartyAPIs.getStatesForCountry(
          userDetail.user.address.country
        );
        const cities = await thirdPartyAPIs.getCitiesForState(
          userDetail.user.address.country,
          userDetail.user.address.state
        );
        this.setState({
          ...user,
          street: userDetail.user.address.street,
          postalCode: userDetail.user.address.postalCode,
          country: userDetail.user.address.country,
          state: userDetail.user.address.state,
          city: userDetail.user.address.city,
          trade_30_seconds: response.data.user.tradeResultConfigs.trade_30_seconds,
          trade_60_seconds: response.data.user.tradeResultConfigs.trade_60_seconds,
          trade_120_seconds: response.data.user.tradeResultConfigs.trade_120_seconds,
          trade_300_seconds: response.data.user.tradeResultConfigs.trade_300_seconds,
          result: response.data.user.tradeResultConfigs.result,
          countries,
          states,
          cities,
        });
      }
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  getCountriesFromAPI = async () => {
    try {
      const countriesData = await thirdPartyAPIs.getCountriesFromAPI();
      this.setState({ countries: countriesData });
      console.log("Received countries data:", countriesData);
    } catch (error) {
      // Handle the error, such as showing an error message or logging
      console.error("Error:", error);
    }
  };

  handleCountryChange = async (event) => {
    const selectedCountry = event.target.value;
    try {
      const states = await thirdPartyAPIs.getStatesForCountry(selectedCountry);
      this.setState({
        country: selectedCountry,
        states: states,
        state: "",
        cities: [],
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  handleStateChange = async (event) => {
    const selectedState = event.target.value;
    try {
      const cities = await thirdPartyAPIs.getCitiesForState(
        this.state.country,
        selectedState
      );
      this.setState({
        state: selectedState,
        cities: cities,
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  componentDidMount() { if (this.state.userId) this.getUserDetailsByID(); }

  handleFormSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const { userId, updateFields } = this.state;
      // Check if any fields have been updated before making the API call
      if (Object.keys(updateFields).length === 0) {
        this.setState({
          successMessage: "",
          errorMessage: "No fields have been updated",
        });
        return;
      }
      // Restructure the updated fields if any of the specified columns are present
      const restructuredFields = {};
      if (
        updateFields.street ||
        updateFields.city ||
        updateFields.state ||
        updateFields.postalCode ||
        updateFields.country
      ) {
        restructuredFields.address = {
          street: updateFields.street || this.state.street || "",
          city: updateFields.city || this.state.city || "",
          state: updateFields.state || this.state.state || "",
          postalCode: updateFields.postalCode || this.state.postalCode || "",
          country: updateFields.country || this.state.country || "",
        };
      }

      // Include other fields as-is
      Object.keys(updateFields).forEach((key) => {
        if (
          key !== "street" &&
          key !== "city" &&
          key !== "state" &&
          key !== "postalCode" &&
          key !== "country"
        ) {
          restructuredFields[key] = updateFields[key];
        }
      });

      const response = await authService.updateProfile(
        userId,
        restructuredFields
      );
      if (response && !response.success) {
        this.setState({
          successMessage: "",
          errorMessage: response.message + " " + response.displayMessage,
        });
        return;
      }
      this.setState({
        errorMessage: "",
        successMessage: response.message + " " + response.displayMessage,
      });
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    this.setState((prevState) => ({
      updateFields: {
        ...prevState.updateFields,
        [name]: value,
      },
    }));
  };

  updateBalance = async () => {
    try {
      this.setState({ loading: true, successMessage: "", errorMessage: "" });
      const response = await service.save(
        { amountInUsd: this.state.amountInUsd },
        `admin/users/${this.state.userId}/${this.state.buttonValue}`,
        "POST"
      );
      if (!response.success) {
        this.setState({
          errorMessage: `${response.message} ${response.error}`,
        });
      } else {
        this.setState(
          { successMessage: "Balance Updated Successfully" },
          () => {
            setTimeout(() => {
              this.setState({ successMessage: "", amountInUsd: 0 });
            }, 4000);
            this.getUserDetailsByID();
            this.closeModal();
          }
        );
      }
    } catch (error) {
      this.setState({ errorMessage: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  updateBlockedStatusUser = async (type = "block") => {
    try {
      this.setState({ loading: true, successMessage: "", errorMessage: "" });
      const response = await service.view(
        `admin/users/${this.state.userId}/${type}`,
        "GET"
      );
      if (!response.success) {
        this.setState({
          errorMessage: `${response.message} ${response.error}`,
        });
      } else {
        this.setState({ successMessage: `${response.message}` }, () => {
          setTimeout(() => {
            this.setState({ successMessage: "" });
          }, 4000);
          this.getUserDetailsByID();
        });
      }
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  cardPage(route, type = null) {
    const url = `/admin/${route}/${this.state.userId}${type !== null ? `/${type}` : ''}`;
    window.location.href = url;
  }

  loginAsUser = async () => {
    this.setState({ loading: true });
    try {
      const response = await service.view(`admin/login-as-user/${this.state.userId}`, "GET");
      if (!response.success) {
        this.setState({ successMessage: "", errorMessage: `${response.message}` });
        return;
      }
      // current admin session data
      sessionStorage.setItem("isAdminUserId", sessionStorage.getItem('userId'));
      sessionStorage.setItem("isAdminToken", sessionStorage.getItem('token'));
      // after logged in as user
      sessionStorage.setItem("isAdminLoggedInAsUser", response.success);
      sessionStorage.setItem("userId", response.userId);
      sessionStorage.setItem("isAdmin", response.isAdmin);
      sessionStorage.setItem('token', response.token);
      window.location.href = "/";
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: `${error.message}` });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleConfiguration = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const { userId, trade_30_seconds, trade_60_seconds, trade_120_seconds, trade_300_seconds, result } = this.state;
    const tradeConfiguration = { userId, result, trade_30_seconds, trade_60_seconds, trade_120_seconds, trade_300_seconds }
    try {
      const response = await service.save(tradeConfiguration, `admin/users/${userId}/configure`, "POST");
      if (!response.success) {
        this.setState({ successMessage: "", errorMessage: `${response.message} ${response.error}`, });
      }
      this.setState({ successMessage: `${response.message}`, errorMessage: "" }, () => {
        setTimeout(() => { this.setState({ successMessage: "" }); }, 4000);
        this.getUserDetailsByID();
      });
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { isModalOpen, trade_30_seconds, trade_120_seconds, trade_300_seconds, trade_60_seconds,
      result, amountInUsd, loading, errorMessage, successMessage, ...userData } = this.state;
    return (
      <>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container">
            {loading && <LoadingOverlay />}
            <div className="row">
              <div className="col-xxl-12 col-xl-12">
                <div className="page-title">
                  <h4>
                    User Details -{" "}
                    {userData.firstName + " " + userData.lastName}
                  </h4>
                </div>
                <div className="row">
                  {[
                    {
                      title: "Balance",
                      goto: { onClick: () => this.cardPage("balance"), },
                      icon: "icofont-money",
                      color: "#2ecc71",
                      count: "$ " + Number(userData.currentBalance).toFixed(2),
                    },
                    {
                      title: "Deposits",
                      goto: { onClick: () => this.cardPage("deposit-approval"), },
                      icon: "icofont-bank-transfer-alt",
                      color: "#e74c3c",
                      count: "$ " + userData.totalDeposit,
                    },
                    {
                      title: "Withdrawals",
                      goto: { onClick: () => this.cardPage("withdrawal-approval"), },
                      icon: "icofont-listing-box",
                      color: "#3498db",
                      count: "$ " + userData.totalWithdrawal,
                    },
                    {
                      title: "Transactions",
                      goto: { onClick: () => this.cardPage("transactions"), },
                      icon: "icofont-retweet",
                      color: "#929497",
                      count: userData.totalTransactions,
                    },
                    {
                      title: "Total Trades",
                      goto: { onClick: () => this.cardPage("trade-log"), },
                      icon: "icofont-ui-game",
                      color: "#0a559f",
                      count: userData.totalTrades
                      ,
                    },
                    {
                      title: "Win Trades",
                      goto: { onClick: () => this.cardPage("trade-log", "won"), },
                      icon: "icofont-trophy-alt",
                      color: "#4CAF50",
                      count: userData.totalWins,
                    },
                    {
                      title: "Loss Trades",
                      goto: { onClick: () => this.cardPage("trade-log", "lost"), },
                      icon: "icofont-close-circled",
                      color: "#FF5722",
                      count: userData.Totallosses,
                    },
                    {
                      title: "Draw Trades",
                      goto: "",
                      icon: "icofont-ban",
                      color: "#607D8B",
                      count: userData.totalDraws,
                    },
                  ].map((item, index) => (
                    <div key={index} className="col-lg-3 col-md-3 col-sm-3 col-xs-3 dt">
                      <div
                        className="card dashboardCard"
                        style={{
                          backgroundColor: item.color,
                          height: "80px",
                          borderRadius: "5px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            height: "100%",
                            padding: "0 5px", // Adjusted padding
                          }}
                        >
                          <div>
                            <i
                              style={{
                                fontSize: "40px",
                                opacity: 0.9,
                                color: "white",
                              }}
                              className={item.icon}
                            ></i>
                          </div>
                          <div style={{ textAlign: "left" }}>
                            {/* Counting value */}
                            <p
                              className="card-title"
                              style={{ color: "white", fontSize: '20px', margin: 0 }}
                            >
                              {item.count}
                            </p>
                            {/* Title */}
                            <p
                              className="card-title"
                              style={{ color: "white", fontSize: '10px', margin: 0 }}
                            >
                              {item.title}
                            </p>
                          </div>
                          <span onClick={item.goto.onClick}
                            style={{ cursor: "pointer", fontSize: "14px", opacity: 0.9, color: "white", marginTop: '5px', }}>
                            {item.title !== "Draw Trades" ? "View" : ""}
                          </span>

                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  {[
                    {
                      title: "Balance",
                      goto: { onClick: () => this.openModal("increaseBalance"), },
                      icon: "icofont-plus-circle",
                      color: "#2ecc71",
                    },
                    {
                      title: "Balance",
                      goto: { onClick: () => this.openModal("decreaseBalance"), },
                      icon: "icofont-minus-circle",
                      color: "#e74c3c",
                    },
                    {
                      title: "Login as User",
                      goto: { onClick: () => { this.loginAsUser() } },
                      icon: "icofont-login",
                      color: "#0a559f",
                    },
                    {
                      title: userData.blocked ? "Unban User" : "Ban User",
                      goto: {
                        onClick: () =>
                          this.updateBlockedStatusUser(
                            userData.blocked ? "unblock" : "block"
                          ),
                      },
                      icon: "icofont-ban",
                      color: "#f39c12",
                    },
                  ].map((item, index) => (
                    <div
                      key={index}
                      className="col-lg-3 col-md-3 col-sm-3 col-xs-3 dt"
                    >
                      <div
                        className="card dashboardCard"
                        onClick={item.goto.onClick}
                        style={{ cursor: "pointer", backgroundColor: item.color, }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "100%",
                          }}
                        >
                          <i
                            style={{ fontSize: "20px", color: "white" }}
                            className={item.icon}
                          ></i>
                          <span
                            className="card-title"
                            style={{ color: "white" }}
                          >
                            {item.title}
                          </span>
                          <span style={{ fontSize: "20px", color: "white" }}></span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="card">
                  {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                  {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                  <div className="card-header">
                    <h4 className="card-title">
                      Information of{" "}
                      {userData.firstName + " " + userData.lastName}
                    </h4>

                    {/* <SubMenu /> */}
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xxl-12 col-xl-12 col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <form method="POST" onSubmit={this.handleConfiguration}>
                              <div className="row g-3">
                                <div className="col-3">
                                  <br />
                                  <label
                                    className="form-label"
                                    htmlFor="trade_30_seconds"
                                  >
                                    30 Seconds Profit (%){" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    id="trade_30_seconds"
                                    name="trade_30_seconds"
                                    value={trade_30_seconds}

                                  />
                                </div>
                                <div className="col-3">
                                  <br />
                                  <label
                                    className="form-label"
                                    htmlFor="trade_60_seconds"
                                  >
                                    60 Seconds Profit (%){" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    min={0}
                                    type="number"
                                    id="trade_60_seconds"
                                    name="trade_60_seconds"
                                    value={trade_60_seconds}
                                  />
                                </div>
                                <div className="col-3">
                                  <br />
                                  <label
                                    className="form-label"
                                    htmlFor="trade_120_seconds"
                                  >
                                    120 Seconds Profit (%){" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    min={0}
                                    type="number"
                                    id="trade_120_seconds"
                                    name="trade_120_seconds"
                                    value={trade_120_seconds}
                                  />
                                </div>
                                <div className="col-3">
                                  <br />
                                  <label
                                    className="form-label"
                                    htmlFor="trade_300_seconds"
                                  >
                                    300 Seconds Profit (%){" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    min={0}
                                    type="number"
                                    id="trade_300_seconds"
                                    name="trade_300_seconds"
                                    value={trade_300_seconds}
                                  />
                                </div>
                              </div>

                              <div className="row g-3">
                                <div className="col-6">
                                  <br />
                                  <label className="form-label" htmlFor="result">Trade Result </label>
                                  <select required className="form-select" id="result" name="result" value={result} onChange={this.handleInputChange}>
                                    <option value="" disabled="">Select Trade Result</option>
                                    <option value="win" >Win</option>
                                    <option value="lose" >Lose</option>
                                  </select>
                                </div>
                                <div className="col-6"><br /><br /><br />
                                  <button type="submit" className="btn btn-success waves-effect">
                                    <i className="icofont-ui-settings"></i> Configure
                                  </button>
                                </div>
                              </div>
                            </form>
                            <hr />
                            <form method="POST" onSubmit={this.handleFormSubmit}>
                              <div className="row g-3">
                                <div className="col-6">
                                  <br />
                                  <label
                                    className="form-label"
                                    htmlFor="firstName"
                                  >
                                    First Name{" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={userData.firstName}
                                  />
                                </div>
                                <div className="col-6">
                                  <br />
                                  <label
                                    className="form-label"
                                    htmlFor="lastName"
                                  >
                                    Last Name{" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={userData.lastName}
                                  />
                                </div>
                              </div>

                              <div className="row g-3">
                                <div className="col-4">
                                  <br />
                                  <label
                                    className="form-label"
                                    htmlFor="username"
                                  >
                                    Username{" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    type="text"
                                    id="username"
                                    name="username"
                                    value={userData.username}
                                  />
                                </div>
                                <div className="col-4">
                                  <br />
                                  <label className="form-label" htmlFor="email">
                                    Email{" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={userData.email}
                                  />
                                </div>
                                <div className="col-4">
                                  <br />
                                  <label
                                    className="form-label"
                                    htmlFor="contact_number"
                                  >
                                    Contact Number{" "}
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    className="form-control"
                                    type="number"
                                    id="contact_number"
                                    name="contact_number"
                                    value={userData.contact_number}
                                  />
                                </div>
                              </div>

                              <div className="col-12">
                                <br />
                                <label className="form-label" htmlFor="street">
                                  Address{" "}
                                </label>
                                <input
                                  onChange={this.handleInputChange}
                                  className="form-control"
                                  type="text"
                                  id="street"
                                  name="street"
                                  value={userData.street}
                                />
                              </div>
                              <br />
                              <div className="row g-3">
                                <div className="col-3">
                                  <label className="form-label">City</label>
                                  <select
                                    name="city"
                                    style={{ color: "#000" }}
                                    className="form-control"
                                    value={userData.city}
                                    onChange={this.handleInputChange}
                                    required
                                  >
                                    <option value="Select City">
                                      Select City
                                    </option>
                                    {userData.cities.map((city) => (
                                      <option key={city.id} value={city.name}>
                                        {city.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="col-3">
                                  <label className="form-label">State</label>
                                  <select
                                    name="state"
                                    style={{ color: "#000" }}
                                    className="form-control"
                                    value={userData.state}
                                    onChange={this.handleStateChange}
                                    required
                                  >
                                    <option value="Select State">
                                      Select State
                                    </option>
                                    {userData.states.map((state) => (
                                      <option key={state.id} value={state.iso2}>
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="col-3">
                                  <label className="form-label">
                                    Postal Code
                                  </label>
                                  <input
                                    onChange={this.handleInputChange}
                                    type="text"
                                    className="form-control"
                                    placeholder="25481"
                                    name="postalCode"
                                    value={userData.postalCode}
                                  />
                                </div>

                                <div className="col-3">
                                  <label className="form-label">Country</label>
                                  <select
                                    name="country"
                                    style={{ color: "#000" }}
                                    className="form-control"
                                    value={userData.country}
                                    onChange={this.handleCountryChange}
                                    required
                                  >
                                    <option value="Select Country">
                                      Select Country
                                    </option>
                                    {Array.isArray(userData.countries) &&
                                      userData.countries.length > 0 ? (
                                      userData.countries.map((country) => (
                                        <option
                                          key={country.id}
                                          value={country.iso2}
                                        >
                                          {country.name}
                                        </option>
                                      ))
                                    ) : (
                                      <option value="" disabled>
                                        Loading countries...
                                      </option>
                                    )}
                                  </select>
                                </div>
                              </div>
                              <br />
                              <div className="col-12">
                                <br />
                                <button
                                  type="submit"
                                  className="btn btn-success waves-effect"
                                >
                                  <i className="icofont-save"></i> Update
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isModalOpen && (
              <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.1)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
                  <span style={{ color: '#aaa', float: 'right', fontSize: '28px', fontWeight: 'bold', cursor: 'pointer', }}
                    onClick={this.closeModal}>
                    &times;
                  </span>
                  <label htmlFor="modalInput" style={{ marginBottom: '10px', display: 'block', }}>Update Balance</label>
                  <input type="text" className="form-control" placeholder="Amount" name="amountInUsd" id="modalInput"
                    value={amountInUsd} onChange={this.handleInputChange}
                    style={{ width: '100%', padding: '8px', marginBottom: '10px', }} />
                  <button style={{ backgroundColor: '#4CAF50', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }}
                    onClick={this.updateBalance}>Update
                  </button>{" "}
                  <button style={{ marginRight: '10px', backgroundColor: '#f44336', color: '#fff', border: 'none', padding: '10px', borderRadius: '4px', cursor: 'pointer' }}
                    onClick={this.closeModal}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
