import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import service from "../../services/service";
import LoadingOverlay from '../../components/loading';
import { Link } from 'react-router-dom';
import { IMAGE_PATH_COMPELETE } from "../../config";

export default class Assets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            loading: false,
            errorMessage: "",
            successMessage: "",
            assets: [],
        };
    }

    componentDidMount() {
        this.getAssetsList();
    }

    getAssetsList = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view('assets', 'GET');
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message });
                return;
            }
            this.setState({ assets: response.data.assets });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, errorMessage, successMessage, assets } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                            {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                            {assets.length === 0 ? (<div className="alert alert-danger" role="alert">{"No Assets Available Yet!"}</div>) : (
                                <>
                                    {assets.map((asset, index) => (
                                        <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                                            <div className="card dashboardCard" style={{ alignItems: 'center' }}>
                                                <div className="card-header p-2">
                                                    <h5 className="card-title">
                                                        {asset.shorthand}
                                                        {/* {asset.name + "(" + asset.shorthand + ")"} */}
                                                    </h5>
                                                </div>
                                                <div className="card-body">
                                                    <div className="deposit__thumb">
                                                        <Link to="">
                                                            <img alt={""} style={{ height: 'auto', width: '260px' }}
                                                                src={asset.imagePath !== undefined ? `
                                                                ${IMAGE_PATH_COMPELETE}${asset.imagePath.replace(/\\/g, '/')}
                                                            ` : ''} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <Link to={`trade-now/${asset._id}`} className="btn--sm d-block cmn--btn text-center">Trade Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                </div >
            </>
        );
    }
}