import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import LoadingOverlay from '../../components/loading';
import service from "../../services/service";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: sessionStorage.getItem('userId') ?? null,
            loading: false,
            errorMessage: "",
            successMessage: "",
            metrics: [],
        };
    }

    componentDidMount() { this.getDashboardMetrics(); }

    getDashboardMetrics = async () => {
        this.setState({ loading: true });
        try {
            const response = await service.view('admin/metrics', { method: 'GET' });
            if (!response.success) {
                this.setState({ successMessage: '', errorMessage: response.message });
                return;
            }
            this.setState({ successMessage: '', errorMessage: '', metrics: response.metrics });
        } catch (error) {
            this.setState({ successMessage: '', errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { loading, errorMessage, successMessage, metrics } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                        {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                        <div className="row">{[
                                { value: metrics.totalUsers, title: "Total Users", goto: "", icon: "icofont-users-alt-5", color: "#3498db" },
                                { value: metrics.activeUsers, title: "Active Users", goto: "", icon: "icofont-tick-mark", color: "#2ecc71" },
                                { value: metrics.unverifiedEmailUsers, title: "Email Unverified Users", goto: "", icon: "icofont-email", color: "#e74c3c" },
                                { value: metrics.blockedUsers, title: "Banned Users", goto: "", icon: "icofont-ban", color: "#f39c12" },
                            ].map((item, index) => (
                                <div key={index} className="col-lg-3 col-md-3 col-sm-12 col-xs-6 dt">
                                    <div className="card dashboardCard" style={{ backgroundColor: item.color }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%' }}>
                                            <i style={{ fontSize: '20px', color: 'white' }} className={item.icon}></i>
                                            <span className="card-title" style={{ color: 'white' }}>{item.title}</span>
                                            <span style={{ fontSize: '20px', color: 'white' }}>{item.value}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}