import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <>
        <footer
          className="footer-section"
          style={{ background: "", marginTop: "50px", marginBottom: "10px" }}
        >
          <div className="footer-top pt-120 pb-120">
            <div className="container">
              <div className="row gy-5 justify-content-between">
                <div className="col-lg-4 col-md-6">
                  <div className="footer__widget">
                    <Link to="/" className="logo">
                      <img src="assets/images/logo.png" alt="logo" />
                    </Link>
                    <p style={{ marginTop: "7px" }}>
                      {" "}
                      Welcome to the world&#039;s largest trading platform City Index. Customer
                      satisfaction is our first priority. Thank you for your support to City Index. Stay with us and build your own luck.
                    </p>
                    <ul style={{ marginTop: "-13px", padding: 0, display: "flex", listStyleType: "none" }}>
                      <li style={{ margin: "5px" }}><a href="https://telegram.me/Cinexsupport" target="__blank" rel="noopener noreferrer"><i className="icofont-telegram"></i>{" "}</a></li>
                      <li style={{ margin: "5px" }}><a href="https://wa.me/13322602486" target="__blank" rel="noopener noreferrer"><i className="icofont-whatsapp"></i>{" "}</a></li>
                      {/* <li style={{ margin: "5px" }}><Link to="https://mail.google.com/" target="__blank"><i className="icofont-envelope"></i>{" "}</Link></li>
                      <li style={{ margin: "5px" }}><Link to="https://twitter.com/" target="__blank"><i className="icofont-twitter"></i>{" "}</Link></li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer__widget">
                    <h5 className="title">Quick Links</h5><hr />
                    <ul className="widget__links">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/customer-support">Contact</Link>
                      </li>
                      <li>
                        <Link to="/signin">Sign In</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer__widget">
                    <h5 className="title">Policies</h5><hr />
                    <ul className="widget__links">
                      <li>
                        <Link to="/about">
                          About us
                        </Link>
                      </li>
                      <li>
                        <Link to="/term-of-services">
                          Terms Of Service
                        </Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="footer__widget">
                    <h5 className="title">Our Newsletter</h5><hr />
                    <p>Subscribe our newsletter to get regular news and tips. we promise we won&#039;t harass you.</p>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                      <form style={{ display: 'flex' }}>
                        <input name="email" id="email" type="email" required placeholder="Enter your email"
                          style={{ padding: '2px 45px 4px 7px', borderRadius: '5px 0 0 5px', border: '1px solid #ccc', outline: 'none' }}
                        />
                        <button type="submit" style={{
                          padding: '10px 20px', backgroundColor: '#007bff', color: '#fff',
                          border: 'none', borderRadius: '0 5px 5px 0', cursor: 'pointer',
                          transition: 'background-color 0.3s ease'
                        }}>Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><br />
          <div className="footer-middle">
            <div className="container">
              <div className="footer-middle-wrapper">
                <div className="row g-0">
                  <div className="col-lg-4">
                    <div className="footer__contact__item">
                      <div className="footer__contact__content">
                        <h6 className="footer__contact__title">
                          <a href="mailto:cinexlive@gmail.com" target="__blank" rel="noopener noreferrer">
                            cinexlive@gmail.com
                          </a>
                        </h6>
                        <span className="info"><i className="icofont-envelope"></i> Email Address</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="footer__contact__item">
                      <div className="footer__contact__content">
                        <h6 className="footer__contact__title">
                        Open 24 hours
                          {/* <Link to="#">+15044703683</Link> */}
                        </h6>
                        <span className="info"><i className="icofont-phone"></i> Hours</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="footer__contact__item">
                      <div className="footer__contact__content">
                        <h6 className="footer__contact__title">
                          <span>
                            Devon House, 58 St Katharine's Way,
                            <br /> London E1W 1JP, United Kingdom
                          </span>
                        </h6>
                        <span className="info"><i className="icofont-location-pin"></i> Our Address</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">Copyright © {new Date().getFullYear()}. All Rights Reserved By{" "}
              <Link to="/" className="text--base">City Index</Link>
            </div>
          </div>
        </footer>
      </>
    );
  }
}