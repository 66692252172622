import service from './service';

export const finaliseTradeIfNeeded = async (callback) => {
    try {
        const storedTradeInfoString = localStorage.getItem('tradeInfo');
        if (!storedTradeInfoString) return;

        const tradeInfoArray = JSON.parse(storedTradeInfoString) || [];
        if (tradeInfoArray.length === 0) return;

        tradeInfoArray.forEach(async (tradeInfo) => {
            const { tradeId, timestamp, tradeWindow } = tradeInfo;
            if (tradeId && timestamp && tradeWindow !== undefined) {
                const timeDifferenceInSeconds = Math.abs((new Date() - new Date(timestamp)) / 1000);
                if (timeDifferenceInSeconds >= tradeWindow && (await service.view(`trades/${tradeId}/result`)).success) {
                    const tradeResponse = await service.view(`trades/${tradeId}`);
                    if (tradeResponse.success) {
                        const { trade } = tradeResponse.data;
                        callback(trade);
                    }
                    const updatedTradeInfoArray = tradeInfoArray.filter(info => info.tradeId !== tradeId);
                    localStorage.setItem('tradeInfo', JSON.stringify(updatedTradeInfoArray));
                    // window.location.reload();
                }
            }
        });
        // Schedule the next check after 2 seconds if the component is still mounted
        if (!unmounted) {
            setTimeout(() => finaliseTradeIfNeeded(callback), 2000);
        }
    } catch (error) {
        console.error('Error:', error.message);
    }
};

let unmounted = false;

export const unmountFinaliseTradeIfNeeded = () => {
    unmounted = true;
};

export const finalisePracticeTradeIfNeeded = async () => {
    try {
        const storedTradeInfoString = localStorage.getItem('practiceTradeInfo');
        if (!storedTradeInfoString) return;
        const tradeInfoArray = JSON.parse(storedTradeInfoString) || [];
        tradeInfoArray.forEach(async (tradeInfo) => {
            const { tradeId, timestamp, tradeWindow } = tradeInfo;
            if (tradeId && timestamp && tradeWindow !== undefined) {
                const timeDifferenceInSeconds = Math.abs((new Date() - new Date(timestamp)) / 1000);
                if (timeDifferenceInSeconds >= tradeWindow && (await service.view(`practice-trades/${tradeId}/result`)).success) {
                    const updatedTradeInfoArray = tradeInfoArray.filter(info => info.tradeId !== tradeId);
                    localStorage.setItem('practiceTradeInfo', JSON.stringify(updatedTradeInfoArray));
                    // window.location.reload();
                }
            }
        });
        setTimeout(finaliseTradeIfNeeded, 2000);
    } catch (error) {
        console.error('Error:', error.message);
    }
};


export const logoutIfTokenExpired = () => {
    try {
        const jwtToken = sessionStorage.getItem('token');

        if (jwtToken) {
            const [, payload] = jwtToken.split('.');
            const decodedPayload = JSON.parse(atob(payload));
            const expirationTime = decodedPayload.exp * 1000;
            const currentTime = new Date().getTime();
            if (currentTime >= expirationTime) {
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('userId');
                sessionStorage.removeItem('isLoggedIn');
                sessionStorage.removeItem("isAdmin");
                sessionStorage.removeItem("isAdminToken");
                sessionStorage.removeItem("isAdminUserId");
                sessionStorage.removeItem("isAdminLoggedInAsUser");
                window.location.href = "/";
            }
            setTimeout(logoutIfTokenExpired, 60000);
        }
    } catch (error) {
        console.error('Error checking or handling the expired token:', error.message);
    }
};