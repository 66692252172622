import { API_BASE_URL } from '../config';

const authService = {
  users: async (userData, API, method = "POST") => {
    try {
      const response = await fetch(`${API_BASE_URL}auth/${API}`, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  updateProfile: async (userId, userData) => {
    try {
      const response = await fetch(`${API_BASE_URL}auth/user/edit/${userId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  getUserDetailsById: async (userId) => {
    try {
      const response = await fetch(`${API_BASE_URL}auth/user/${userId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error.message);
    }
  },

  logout: () => {
    try {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('isLoggedIn');
      sessionStorage.removeItem("isAdmin");
      sessionStorage.removeItem("isAdminToken");
      sessionStorage.removeItem("isAdminUserId");
      sessionStorage.removeItem("isAdminLoggedInAsUser");
      return { success: true, message: 'Logout successful' };
    } catch (error) {
      throw new Error('Error during logout: ' + error.message);
    }
  },
};

export default authService;
