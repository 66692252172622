import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import service from "../../services/service";
import LoadingOverlay from "../loading";

class usdtWithdraw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amountInUsd: 0,
      _id: sessionStorage.getItem("userId") ?? null,
      withdrawId: props.match.params.withdrawId ?? null,
      loading: false,
      errorMessage: "",
      successMessage: "",
      networkType: "",
      walletAddress: "",
    };
  }

  handleInputChange = (event) => { this.setState({ [event.target.name]: event.target.value }); };

  WithdrawMoney = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    try {
      const { withdrawId, walletAddress, networkType } = this.state;
      const userData = { withdrawId, walletAddress, networkType };
      const response = await service.save(userData, "withdraws/update", "PUT");
      if (response && !response.success) {
        this.setState({ successMessage: "", errorMessage: response.message + " " + response.error });
        return;
      }
      this.setState({ errorMessage: "", successMessage: "" });
      window.location.href = "/withdraw-history?success=true";
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading, errorMessage, successMessage, walletAddress } = this.state;
    return (
      <>
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container">
            {loading && <LoadingOverlay />}
            <div className="row">
              <div className="col-xxl-12 col-xl-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Withdraw Via USDT</h4>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-lg-6">
                          <form onSubmit={this.WithdrawMoney} method="POST">
                            {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                            {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                            <div className="card custom--card">
                              <div className="card-header">
                                <h4 className="card-title">Withdraw Money</h4>
                              </div>
                              <div className="card-body">
                                <div className="form-group">
                                  <label className="form-label">
                                    Network Type
                                  </label>
                                </div>
                                <div className="form-group">
                                  <div className="input-group">
                                    <div>
                                      <div>
                                        <input type="radio" value="btc" onChange={this.handleInputChange} required id="btc" name="networkType" />
                                        <label htmlFor="btc">BTC</label>
                                      </div>

                                      <div>
                                        <input type="radio" value="erc20" onChange={this.handleInputChange} required id="erc20" name="networkType" />
                                        <label htmlFor="erc20">ERC20</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br />

                                <div className="form-group">
                                  <label className="form-label">Withdrawl Address </label>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      name="walletAddress"
                                      onChange={this.handleInputChange}
                                      className="form-control cmn--form--control"
                                      autoComplete="off"
                                      required
                                      value={walletAddress}
                                    />
                                  </div>
                                </div>
                                <br />
                                <button
                                  type="submit"
                                  className="cmn--btn btn-block btn btn-success waves-effect"
                                >
                                  Withdraw
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    {loading && <LoadingOverlay />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default usdtWithdraw;
