// TradePopup.js
import React, { useEffect , useState} from 'react';
import ReactDOM from 'react-dom';
import authService from '../services/authService';

const TradePopup = ({ tradeInfo, onClose }) => {
    const { userId, amountInUsd, profitLossAmount, finalResult, tradeWindow, chosenResult } = tradeInfo;
    const [userBalance, setUserBalance] = useState(null);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await authService.getUserDetailsById(userId)
                if (response.success) {
                    setUserBalance(Number(response.user.currentBalance).toFixed(2));
                }
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };
        fetchUserData();
    }, [userId]);
    return ReactDOM.createPortal(
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', color: 'black', border: '2px solid #1830a3', borderRadius: '10px', padding: '20px', zIndex: '9999', textAlign: 'center', }}>
            <h2 style={{ color: 'greenyellow' }}><i className="icofont-tick-mark"></i></h2>
            <h3 style={{ textAlign: 'center' }}> {finalResult === "win" ? "+" : "-"} {profitLossAmount} {finalResult === "win" ? "Profit" : "Loss"}</h3>
            <span>Message: Trade {finalResult}</span><br />
            <span>Balance: {userBalance}</span> <br />
            <span>Selected Period: {tradeWindow}s</span> <br />
            <span>Price: {profitLossAmount}</span> <br />
            <span>Direction: {chosenResult}</span> <br />
            <span>Amount: {amountInUsd}</span> <br />
            <button style={{ backgroundColor: '#1830a3', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer', marginTop: '20px', }}
                onClick={onClose}>OK</button>
        </div>,
        document.getElementById('popup-root')
    );
};

export default TradePopup;
