import React, { Component } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

export default class TermsOfServices extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="row">
                        <div class="mb-5" style={{ marginBottom: "3rem" }}>
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        You should read these terms of service carefully. By viewing this
                                        website, creating an account or{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>Tested</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;account, using the mobile app or API and/or accessing or
                                        using the services, you agree to be legally and unconditionally
                                        bound by these terms of service. If you have any questions about
                                        these terms of service, you should contact Support.
                                    </span>
                                </font>
                            </p>
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>Table of Contents</span>
                                </font>
                            </p>
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>1. Introduction</span>
                                </font>
                            </p>{" "}
                            <p
                                class="font-18"
                                style={{
                                    marginRight: "0px",
                                    marginLeft: "0px",
                                }}
                            >
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        1.1. These Terms of Service apply to your (collectively, “you” or
                                        “your”) access to and use of our Website(s) (including
                                        gffexvip.com and its subdomains) and the Services provided by HDR
                                        Global Trading Limited (the “Company”), a company incorporated in
                                        the Republic of Seychelles with a company number of 148707 and the
                                        Group (collectively, “HDR”, “gffexvip”, “we”, “our” or “us”).
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        1.2. Where you act on behalf of a corporation, partnership, firm
                                        or{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>organization</span>
                                    <span style={{ fontSize: "18px" }}>
                                        , references in these Terms of Service to “you” mean the
                                        corporation, partnership, firm or{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>organization</span>
                                    <span style={{ fontSize: "18px" }}>.</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p
                                class="font-18"
                                style={{
                                    marginRight: "0px",
                                    marginLeft: "0px",
                                }}
                            >
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>2. General Terms</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        2.1. When you access and/or use the Website and any of our
                                        Services, you represent, warrant and undertake that you will not:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) carry out any activity that:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) involves proceeds from any illegal or unlawful activity
                                        (including, but not limited to, activities relating to money
                                        laundering or terrorism financing);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        ii) publishes, distributes or disseminates any illegal or unlawful
                                        material or information; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iii) otherwise violates, or could violate, any Applicable Law;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) attempt to undermine the security or integrity of our computing
                                        systems or networks or, where the Services are hosted by a third
                                        party, that third party’s computing systems or networks;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) use, or misuse, the Services in any way which may impair the
                                        functionality of the Services or other systems used to deliver the
                                        Services or impair the ability of any other user to use the
                                        Services;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>d) attempt to gain </span>
                                    <span style={{ fontSize: "18px" }}>unauthorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;access to the systems on which the Services are hosted
                                        (including, without limitation, any hardware and software operated
                                        by us or by third parties for us) or to any materials other than
                                        those which you have been given express permission to access;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        e) transmit or input any files that may:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) damage any other person’s computing devices or software; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        ii) contain material or data in violation of any law (including
                                        data or other material protected by copyright or trade secrets
                                        which you do not have the right to use);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        f) attempt to modify, copy, adapt, reproduce, disassemble,
                                        decompile or reverse-engineer any computer programs used to
                                        deliver or operate any of the Services except as is strictly
                                        necessary for normal operation; and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        g) attempt to or access the source code or object code or utilise
                                        the Services in any manner to enable you to offer a competing
                                        product or service,
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        (collectively, the "General Obligations").
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        2.2. We do not act as your agent, broker, adviser, trustee or
                                        fiduciary in any capacity. These Terms of Service do not create
                                        any partnership, joint venture, joint enterprise or similar
                                        business relationship with you. None of the Services amounts to
                                        and no communication or information provided to you shall be
                                        deemed or construed to be investment or financial advice. We have
                                        no obligation to, and shall not, provide you with any legal, tax,
                                        accounting or other advice. You should conduct your own due
                                        diligence and consult an independent adviser where appropriate.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        2.3. No information or material provided on any Website or through
                                        our Services shall be construed as a promotion of the Services or
                                        any product, or a solicitation, an offer or a recommendation to
                                        trade in, use or engage the Services or any product in any manner.
                                        You will make your own independent decision to access and/or use
                                        the Services (including the Trading Platform).
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        2.4. References in these Terms of Service to ‘Account’ mean each
                                        of your Accounts opened with us, as applicable.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>3. Services</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        3.1. We may provide Services which you can access and/or use, with
                                        such features, rights and privileges and on such terms and
                                        conditions as we may specify. Any such terms and conditions may be
                                        set out through any means we consider appropriate, including as an
                                        annex to these Terms of Service. Such terms and conditions will be
                                        incorporated by reference into and supplement these Terms of
                                        Service. We have the right to amend any terms and conditions on
                                        which the Services are offered at our sole and absolute discretion
                                        and without prior Notice. In the event of a conflict between the
                                        Terms of Service and the terms and conditions of the Services, the
                                        terms and conditions of the Services shall prevail (unless
                                        expressly stated otherwise).
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        3.2. We may introduce new Services, or vary, suspend, withdraw or
                                        cease to provide any or all of the Services. We will{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>endeavor</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;to give you reasonable prior Notice before we vary, suspend,
                                        withdraw or cease to provide any of the Services but retain the
                                        right to do so without prior Notice to you. We shall not be liable
                                        for any losses or damages as a result of (i) varying, suspending,
                                        withdrawing or ceasing to provide access to and use of any of the
                                        Services or (ii) amending any terms and conditions on which the
                                        Services are provided.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        3.3. In order to access certain Services (including the Trading
                                        Platform), you will need an Account and be granted certain
                                        features, rights and privileges.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        3.4. We shall make reasonable efforts to ensure that the Services
                                        are available. However, access to the Services may be disrupted
                                        from time to time due to necessary maintenance, technical issues,
                                        network and system overloads or other events (whether within or
                                        outside of our control). We will use commercially reasonable
                                        efforts to avoid downtime of the Services, but assume no liability
                                        (whether for trading-related losses or otherwise) if the Services
                                        or any part thereof are unavailable at any time or for any period.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        3.5. We may not make the Services available in all markets and
                                        jurisdictions, and may restrict or prohibit use of the Services in
                                        certain markets or jurisdictions. Our current policies on the
                                        availability of Services in specified markets and jurisdictions
                                        can be found here. We may amend these policies from time to time
                                        at our sole and absolute discretion without prior Notice to you.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        3.6. We have the right to implement or impose (or vary) any
                                        restrictions and limitations on access to and use of the Services
                                        in our sole and absolute discretion, including (but not limited
                                        to) transaction volumes, risk limits, rate limits, account limits,
                                        and order restrictions. We may also implement or impose (or vary)
                                        restrictions and limitations on the access to and use of the
                                        Services specifically in relation to your Account. We may
                                        implement, impose or amend these restrictions and limitations at
                                        our sole and absolute discretion without prior Notice to you.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        3.7. From time to time, we may launch certain features or Services
                                        (including any software that may be produced, developed or
                                        published by the Group from time to time) as beta versions at the
                                        time of release (a “Development Service”). If you agree to
                                        participate in the beta testing of a Development Service, you
                                        acknowledge, agree and understand that the Development Service may
                                        be unstable and may change from time to time. We do not warrant
                                        that the functionality of Development Services will meet your
                                        requirements or that the operation of Development Services will be
                                        uninterrupted or error-free. We will not be liable for any loss or
                                        damage arising from your access to and use of a Development
                                        Service. We reserve the right, at any time and for any reason, to
                                        discontinue, redesign, modify, enhance or change the Development
                                        Services.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>4. Account</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        4.1. In order to access and use certain Services (including the
                                        Trading Platform), you will need to have an Account. You do not
                                        need an Account to access and use the publicly available portion
                                        of the Website(s).
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        4.2. If you are an Individual User, your Account will be
                                        designated as an “Individual Account”. If you are a Corporate
                                        User, your Account will be designated as a “Corporate Account”. We
                                        may from time to time introduce different tiers or classes of
                                        Accounts, along with respective features, terms, obligations
                                        privileges and/or rights.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>5. Account Creation</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        5.1. If you are an Individual User, to be eligible to open an
                                        Individual Account, you must satisfy all of the following
                                        eligibility criteria (“Individual Eligibility Criteria”):
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) you must be at least 18 years old and above the legal age of
                                        majority in your relevant jurisdiction(s);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) the entry into and performance of, and the obligations
                                        contemplated by, these Terms of Service do not and will not
                                        conflict with any Applicable Laws with respect to you;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) you have reviewed the Risk Disclosure Statement in full,
                                        understand the risks of trading in digital assets and using the
                                        Services, and have the necessary knowledge, experience, risk
                                        tolerance and financial assets (such that you can afford up to
                                        total loss of your digital assets) to use all of the Services
                                        (including the Trading Platform);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>d) you must not:</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) be currently under actual or threatened investigation, inquiry,
                                        or audit by any government authority in relation to any potential
                                        offence involving fraud, bribery, corruption, or dishonesty;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        ii) have been convicted of or pleaded guilty to an offence
                                        involving fraud, bribery, corruption, or dishonesty;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iii) have been listed by any government agency or non-governmental{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>organization</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;as debarred, suspended, proposed for suspension or
                                        debarment, or otherwise ineligible for procurement programmes;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>iv) be bankrupt;</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        v) be (or have received any oral or written notice from any
                                        government or regulatory authority that you may be) in violation
                                        or breach of any Applicable Laws (including but not limited to
                                        AML/CFT, anti-corruption or economic sanction laws);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        vi) be a Sanctioned Person;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        vii) be a person restricted from using our Services in accordance
                                        with our Restricted Jurisdiction Policy (as updated, amended or
                                        replaced from time to time); and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        viii) be a person who is restricted from opening or holding an
                                        Account and/or using the Services as determined by us or in
                                        accordance with Applicable Laws;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        e) all governmental and other consents that are required to have
                                        been obtained by you with respect to these Terms of Service have
                                        been obtained and are in full force and effect and all conditions
                                        of any such consents have been complied with;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        f) your use of the Account and the Services does not constitute a
                                        breach of any Applicable Laws;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        g) you are acting for your own account and are not relying on any
                                        communication (written or oral) from us as solicitation,
                                        recommendation, marketing, promotion or investment or similar
                                        advice;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        h) you are entering into these Terms of Service as principal and
                                        not as agent of any person or entity; and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) you have not been previously prohibited from accessing or using
                                        any of our Services.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        j) you have reviewed the GFFEX Token Disclaimer in full,
                                        understand that the purpose of the BMEX Token is to provide
                                        additional utility to BitMEX users in relation to the Services,
                                        and understand that the BMEX Token does not entitle you to any
                                        equity, governance, voting or similar right or interest in
                                        gffexvip.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        5.2. If you are a Corporate User, to be eligible to open a
                                        Corporate Account, you must satisfy all of the following
                                        eligibility criteria (“Corporate Eligibility Criteria”):
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>a) you:</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>i) must be duly </span>
                                    <span style={{ fontSize: "18px" }}>organized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;and validly existing under the laws of the jurisdiction of
                                        your{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>organization</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;or incorporation, and, if relevant under such laws, in good
                                        standing;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        ii) must have duly appointed each of your{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>Authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;Representatives to act on behalf of the corporation,
                                        partnership, firm or{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>organization</span>
                                    <span style={{ fontSize: "18px" }}>; and</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iii) must have the necessary corporate or other power and
                                        authority to enter into these Terms of Service, and otherwise to
                                        perform its obligations hereunder, and have taken all necessary
                                        action to{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>authorize</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;such execution, delivery and performance;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>b) each of your </span>
                                    <span style={{ fontSize: "18px" }}>Authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;Representatives must be:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) at least 18 years old and above the legal age of majority in
                                        your relevant jurisdiction(s);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>ii) duly </span>
                                    <span style={{ fontSize: "18px" }}>authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;by you to enter into, and perform, the obligations
                                        contemplated by these Terms of Service;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) the entry into and performance of, and the obligations
                                        contemplated by, these Terms of Service do not and will not
                                        conflict with any Applicable Laws with respect to you and/or any
                                        constitutional document, agreement or instrument binding upon you;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        d) you have reviewed the Risk Disclosure Statement in full,
                                        understand the risks of trading in digital assets and using the
                                        Services, and have the necessary knowledge, experience, risk
                                        tolerance and financial assets (such that you can afford up to
                                        total loss of your digital assets) to use all of the Services
                                        (including the Trading Platform);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>e) you and each of your </span>
                                    <span style={{ fontSize: "18px" }}>Authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;Representatives must not:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) be currently under actual or threatened investigation, inquiry,
                                        or audit by any government authority in relation to any potential
                                        offence involving fraud, bribery, corruption, or dishonesty;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        ii) have been convicted of or pleaded guilty to an offence
                                        involving fraud, bribery, corruption, or dishonesty;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iii) have been listed by any government agency or non-governmental{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>organization</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;as debarred, suspended, proposed for suspension or
                                        debarment, or otherwise ineligible for procurement{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>programmed</span>
                                    <span style={{ fontSize: "18px" }}>;</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iv) be bankrupt, insolvent, or under administration;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        v) be (or have received any oral or written notice from any
                                        government or regulatory authority that you may be) in violation
                                        or breach of any Applicable Laws (including but not limited to
                                        AML/CFT, anti-corruption or economic sanction laws);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        vi) be a Sanctioned Person;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        vii) be a person restricted from using our Services in accordance
                                        with our Restricted Jurisdiction Policy (as updated, amended or
                                        replaced from time to time); and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        viii) be a person who is restricted from opening or holding an
                                        Account and/or using the Services as determined by us or in
                                        accordance with Applicable Laws;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        f) all governmental and other consents that are required to have
                                        been obtained by you with respect to these Terms of Service have
                                        been obtained and are in full force and effect and all conditions
                                        of any such consents have been complied with;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        g) the use of the Account and the Services by you and each of your{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>Authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;Representatives do not constitute a breach of any Applicable
                                        Laws;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        h) you are acting for your own account and are not relying on any
                                        communication (written or oral) from us as solicitation,
                                        recommendation, marketing, promotion or investment or similar
                                        advice;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) you are entering into these Terms of Service as principal and
                                        not as agent of any person or entity; and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>j) you and each of your </span>
                                    <span style={{ fontSize: "18px" }}>Authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;Representatives have not been previously prohibited from
                                        accessing or using any of our Services.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        k) you have reviewed the GFFEX Token Disclaimer in full,
                                        understand that the purpose of the BMEX Token is to provide
                                        additional utility to BitMEX users in relation to the Services,
                                        and understand that the BMEX Token does not entitle you to any
                                        equity, governance, voting or similar right or interest in
                                        gffexvip.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        5.3. By registering to open an Account, you represent, warrant and
                                        undertake that:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) you satisfy the Eligibility Criteria in full;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) you have accepted these Terms of Service; and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) any and all information you provide to us in connection with
                                        the Account is (and will be) true, complete and correct.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        5.4. When you apply to open an Account, we will ask you to provide
                                        our required account registration information (including your
                                        email and full name) and any “know-your-client” information or
                                        documents which we may require from time to time. A non-exhaustive
                                        list of the types of information and documents which we may
                                        require from you is set out here for individuals and here for
                                        corporates. We reserve the right to request more information at
                                        any time in order to process your Account opening request.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        5.5. We may initiate the Account opening process before you
                                        provide all of the “know-your-client” information or documents. If
                                        we do so, your Account will be subject to any restrictions that we
                                        deem necessary (including restrictions on access to or use of the
                                        Trading Platform) until we can complete our “know-your-client”
                                        procedures.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        5.6. We will rely on your representations, warranties and
                                        undertakings set out in Clause 5.3 and the information provided
                                        under Clause 5.4 if we open an Account for you.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        5.7. We may refuse to open an Account for you at our sole and
                                        absolute discretion and do not have to provide reasons for doing
                                        so.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>6. Account Usage</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        6.1. After your Account is opened, you can access and use your
                                        Account through the Website, the Mobile App, API or any other
                                        means that we may specify from time to time, subject to these
                                        Terms of Service and any other terms we may apply to your Account
                                        access and use.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        6.2. In respect of an Individual Account, your Account is provided
                                        on the basis that you are the only authorised user of the Account.
                                        In respect of a Corporate Account, your Account is provided on the
                                        basis that you and your{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>Authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;Representatives are the only{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;users of the Account. You must not provide your Account
                                        details (including any access credentials, such as your email
                                        address, password or API Key(s)) to any third party other than for
                                        the purposes of verifying or auditing your Account. Such third
                                        parties are not permitted to trade on the Trading Platform or make
                                        Withdrawals or Deposits, and they will be deemed to have agreed to
                                        (and must comply with) these Terms of Service, and you will be
                                        responsible for the actions (and omissions) of any such third
                                        parties.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        6.3. At the time of Account opening, or any time afterwards, we
                                        may make certain features, rights or privileges (which may be
                                        necessary in order to use certain functions or aspects of the
                                        Services) available on your Account at our sole and absolute
                                        discretion. We may add, vary, modify, or cease to provide your
                                        Account with these features, rights or privileges at any time at
                                        our sole and absolute discretion.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        6.4. You agree that any trading or other instructions received or
                                        made under your Account (including through the Trading Platform)
                                        are deemed to be final and conclusive, and we may act on such
                                        trading or other instructions and shall not be liable for any loss
                                        or damage arising out of the improper use of your Account.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>7. Account Security</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        7.1. We implement certain security controls to prevent malicious
                                        actors from accessing your Account. We also provide the option for
                                        you to enable two-factor authentication in respect of your
                                        Account. However, you are responsible for safeguarding your
                                        authentication credentials and for restricting access to the
                                        Services from your compatible devices. You must also ensure that
                                        your login credentials are safe, limit access to your Account to
                                        authorised persons and control the devices which can operate the
                                        Account. You must let us know immediately if an{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>unauthorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;person is accessing or using your Account.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        7.2. We may implement or impose additional security controls from
                                        time to time at our sole and absolute discretion (including
                                        mandatory two-factor authentication for Account log-in and
                                        password resets). A non-exhaustive list of security controls we
                                        have implemented is set out here. We may also implement or impose
                                        security controls individually on your Account. We may amend or
                                        vary these security controls in our sole and absolute discretion
                                        and without prior Notice to you.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        7.3. If you forget or lose any of the information necessary to log
                                        in to your Account, you will need to provide all information
                                        required by us in order to facilitate any potential recovery or
                                        reset of your Account or Account details.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        7.4. You must immediately notify us of any{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>unauthorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;use of your Account or any other breach of security. In
                                        particular, you agree to notify us immediately:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) upon any changes to your registered email address associated
                                        with your Account;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) upon any breach or compromise of security affecting your
                                        Account credentials, access to your account, your registered email
                                        address associated with your Account or any of your registered
                                        Withdrawal Addresses;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) if any person other than you has access to or uses your
                                        Account;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        d) if you become aware of any{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>unauthorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;use of your Account; and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        e) upon any other breach of security regarding your Account.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        7.5. We have the right to restrict the use of your Account:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>a) upon your request;</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) if we believe you have forgotten or lost your Account
                                        information;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) if we believe there has been or may be{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>unauthorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;access to or use of your Account, your registered email
                                        address associated with your Account or any of your registered
                                        Withdrawal Addresses; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        d) if we believe you have provided false information
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        until such time as we can verify your identity or any other
                                        relevant information.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        7.6. Upon a breach of security, you will immediately take all
                                        reasonable steps to mitigate the effects and will cooperate with
                                        us (and provide us with all information requested) to remediate
                                        the breach. Any assistance provided by us in relation to a
                                        security breach does not in any way operate as acceptance or
                                        acknowledgement that we are in any way responsible or liable to
                                        you or any other party in connection with such breach. We will not
                                        be liable for any loss or damage arising from your failure to
                                        protect your Account and/or any unauthorised access to and use of
                                        your Account (including access to and use of any of the Services),
                                        including, without limitation, in circumstances where you have
                                        provided notice to us under these Terms of Service.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        8. Account Suspension, Termination or Closure
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        8.1. Nothing in this Clause 8 shall limit any rights we have under
                                        the Exchange Rules to (i) reject or cancel any instruction or (ii)
                                        close, reverse, off-set (including by entering into an opposite
                                        and equal Trade), liquidate, mitigate, deleverage or settle any
                                        open Orders, Trades or Positions.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        8.2. Without prejudice to any other rights we may have under these
                                        Terms of Service or Applicable Laws, we may take any or all of the
                                        following steps if an Account Breach has occurred (or we suspect
                                        that an Account Breach has occurred) or if you request your
                                        Account to be closed:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) restrict further Deposit(s) and/or Withdrawal(s) in respect of
                                        your Account (including by ceasing to provide a Deposit Address);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) restrict the placement of Orders or Trades through the Trading
                                        Platform (including a ban on the placement of new Orders);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) disable any active API Keys in respect of your Account or
                                        institute any other restriction on the API in respect of your
                                        Account;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        d) place restrictions and limits on the risk, trading and
                                        positions on your Account (including limits on any increase in
                                        position size);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        e) cease payment of any Commission (as defined in the Affiliate
                                        Program Terms of Service) or take any other action under the
                                        Affiliate Program Terms of Service;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        f) cease to provide you with and/or restrict your access to and
                                        use of any or all of the Services;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        g) restrict or cease any features, rights and privileges granted
                                        or provided to you;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        h) reject or cancel any instruction;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) close, reverse, off-set (including by entering into an opposite
                                        and equal transaction), liquidate, mitigate, deleverage or settle
                                        any open Orders, Trades or Positions (regardless of the profit or
                                        loss position);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        j) deduct any amounts owing to us under these Terms of Service
                                        (including any amounts which we consider to have arisen from any
                                        Account Breach), from your Account Balance – and you authorise us
                                        to deduct such amounts without further Notice to you;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        k) deal with any of your available Account Balance in the
                                        following manner:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) transfer to you all the available Account Balance (after
                                        subtracting any amount owing to us or otherwise deductible in
                                        accordance with these Terms of Service):
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        A) to a Withdrawal Address provided by you – and you undertake to
                                        provide a Withdrawal Address to us within 2 days upon our request
                                        for these purposes; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        B) to any Withdrawal Address registered with your Account; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        ii) where we reasonably believe we are prohibited from
                                        transferring to you any or all the available Account Balance due
                                        to Applicable Laws or our policies and procedures, we may take any
                                        action to segregate or restrict Withdrawal of the Account Balance
                                        until it can be released in accordance with Applicable Laws or our
                                        policies and procedures;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        l) take any action we consider necessary or desirable in order to
                                        comply with any order or request from a government, court or
                                        regulator or in compliance with Applicable Laws;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        m) conduct an investigation into or review of any matters we deem
                                        appropriate or necessary;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        n) disable access to, or close, your Account; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        o) take any other action which we reasonably deem to be
                                        appropriate or necessary.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        We can take any or all of the above steps without prior Notice or
                                        providing reasons to you (and may not be able to provide prior
                                        Notice or reasons under Applicable Laws), but, where appropriate,
                                        we may give you a specified period within which to provide any
                                        information or documents to show that such Account Breach has not
                                        occurred before we take certain actions. Where permissible under
                                        Applicable Laws and at our discretion, we may permit you to amend
                                        existing and open Orders and Positions and/or withdraw your
                                        Account Balance but restrict you from placing new Trades through
                                        the Trading Platform.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        8.3. We are not liable for any loss, damage or costs (without
                                        limitation) which you may incur when we take any or all of the
                                        steps mentioned in Clause 8.2. You expressly agree that we shall
                                        not be liable to you for any profit or loss as a result of any
                                        action we take in respect of your open Orders, Trades or
                                        Positions.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        8.4. Our failure or delay to take immediate action set out in
                                        Clause 8.2 does not constitute a waiver of any of our rights, and
                                        we retain the right to take the steps at any time in respect of
                                        your Account.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        8.5. An Account Breach occurs when we, at our sole and absolute
                                        discretion, determine:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) you breach or fail to perform or observe these Terms of
                                        Service, the Exchange Rules or any other terms incorporated by
                                        reference in or to these Terms of Service or applicable to you in
                                        respect of the Account or the Services*;*
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) you take any action which is inconsistent with a fair and
                                        orderly market or the integrity of the market (including any
                                        market misconduct or market manipulation);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) you breach any of the Chatroom Restrictions or the General
                                        Obligations;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        d) you breach any Applicable Laws;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        e) we are required to take any action in respect of your Account
                                        we consider necessary or desirable in order to comply with any
                                        Applicable Laws;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        f) a government, regulator or authority requests or requires us to
                                        take any action in respect of your Account;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        g) you apply for bankruptcy, insolvency or administration or such
                                        similar event has occurred or been declared;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        h) an action, suit or procedure has been commenced against you or
                                        your assets;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) you make a misrepresentation to us or provide us with any false
                                        or misleading information;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        j) you do not provide any information which we request or require
                                        from you in accordance with these Terms of Service, the Exchange
                                        Rules or any other terms incorporated by reference in or to these
                                        Terms of Service;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        k) you do not meet any or all of the Eligibility Criteria;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        l) you are not able to give the representations and warranties set
                                        out in Clause 11;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        m) you are a Sanctioned Person;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        n) you are a person restricted from using our Services in
                                        accordance with our Restricted Jurisdiction Policy;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        o) you are a person who we consider is restricted from opening or
                                        holding an Account and/or accessing or using the Services as
                                        determined by us or in accordance with Applicable Laws;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        p) the person operating the Account is not{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;by or is not the true owner of the property connected with
                                        the Account; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        q) it is necessary to take action against your Account in order to
                                        protect you, our other users or ourselves.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        An Account Breach constitutes a breach of these Terms of Service
                                        by you.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        8.6. In the event of a User’s death or incapacitation, the person{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>authorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;to act on behalf of the deceased or incapacitated User (or
                                        such User’s estate) should contact Support.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9. Deposits, Withdrawals, and Transfers
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.1. The Trading Platform and all calculations are currently
                                        denominated in bitcoin or USD Tether (with transfer and credit on
                                        the Bitcoin blockchain and Ethereum blockchain, respectively). We
                                        may allow the use of other digital assets and denominate the
                                        Trading Platform and calculations using such other digital assets
                                        from time to time at our sole and absolute discretion (with
                                        transfer and credit on the applicable blockchain, according to
                                        such policies and procedures as we may determine).
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>Deposits</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.2. We will provide your Account with one or more applicable
                                        deposit addresses (each a “Deposit Address”), unless otherwise
                                        restricted under these Terms of Service (and may, from time to
                                        time, allow for the use of more than one Deposit Address in
                                        respect of any digital asset, at our discretion).
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.3. When you make an instruction to transfer the applicable
                                        digital assets to a Deposit Address (each such transfer, a
                                        “Deposit”):
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) in order for such transfer to be effective, you are required to
                                        transfer to the Company all right, title and interest in the
                                        relevant amount of digital assets free from all liens, claims,
                                        charges and encumbrances;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) we will typically credit each Deposit after receiving at least
                                        one confirmation of the transfer and credit to the Deposit Address
                                        on the relevant blockchain, but more than one confirmation may be
                                        necessary, and we do not guarantee that a transfer will be
                                        credited within any defined number of confirmations or that any
                                        given block will be processed simultaneously with any blockchain
                                        confirmation*; *in particular, without limitation, we may, at our
                                        discretion (i) credit a Deposit prior to confirmation on the
                                        relevant blockchain or (ii) require additional confirmations or
                                        conditions with respect to any Deposit, in particular (without
                                        limitation) where we believe that blockchain consensus is in
                                        doubt;**
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) when we determine that such transfer is effective, we will
                                        increase your Account Balance by an amount that is equivalent to
                                        the amount of digital assets transferred to the Deposit Address
                                        net of any applicable transaction fees (including, without
                                        limitation, any applicable mining fees);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        d) you agree that all right, title and interest in and to any and
                                        all digital assets that is transferred to the Deposit Address
                                        shall vest in us, free and clear of any liens, claims, charges or
                                        encumbrances or any of interest of you or any third party;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        e) immediately prior to each such transfer, you represent and
                                        warrant that:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) you are the legal and beneficial owner of any and all digital
                                        assets that you transfer to the Deposit Address;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        ii) you have the full and unqualified right to convey and have
                                        conveyed, all right, title and interest in and to the relevant
                                        amount of digital assets that you transfer to the Deposit Address;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iii) the digital assets you transfer to the Deposit Address are
                                        not derived from or obtained as a result of any breach of
                                        Applicable Laws or the proceeds of crime (including any proceeds
                                        arising from drug trafficking, corruption, financing of terrorism
                                        or money laundering or transactions that violate sanctions), and
                                        the transfer does not constitute a breach of any Applicable Laws;
                                        and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iv) you will execute and deliver all necessary documents and take
                                        all necessary steps that we require in order to procure that, upon
                                        such transfer, all right, title and interest in and to any digital
                                        assets shall vest in us, free and clear of any liens, claims,
                                        charges or encumbrances or any of interest of you or any third
                                        party.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.4. You have no rights or interest in respect of the Deposit
                                        Address or the amount of digital assets that is recorded in or
                                        registered to the Deposit Address. Your Account Balance is not
                                        your wallet. Your Account Balance is the balance of digital assets
                                        that we reflect in our books and records as credited to your
                                        Account. Amounts stated as being credited to or deducted from your
                                        Account Balance means that such amounts will be added to or
                                        deducted from the balance which the Company reflects in its books
                                        and records as available to be used by you in respect of the
                                        Services.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.5. You agree that it is your responsibility to ensure that
                                        instructions, orders or transactions sent to us are
                                        well-formatted, clear and denominated in the correct digital
                                        assets. In particular, you must transfer the correct digital
                                        assets to the Deposit Address. We are not liable for any
                                        inaccuracies, omissions or other errors with respect to any
                                        Deposit. In particular, without prejudice to the foregoing, we
                                        have no obligation to return any digital asset that has been
                                        transferred to a Deposit Address that is controlled by or
                                        associated with us, in circumstances where (i) we do not support
                                        that digital asset or the blockchain or protocol pursuant to which
                                        it was transferred or (ii) the digital asset has been transferred
                                        to the wrong Deposit Address.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>Withdrawals</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.6. When you instruct us to transfer to you an amount of digital
                                        assets from your available Account Balance (a “Withdrawal”), you
                                        must provide an appropriate withdrawal address (“Withdrawal
                                        Address”) for the transfer of digital assets to you. When we
                                        determine that your instruction is valid, we will deduct from your
                                        Account Balance the amount set out in your instruction; when such
                                        deduction has occurred, we then transfer an equivalent amount of
                                        digital assets to your Withdrawal Address minus any applicable
                                        transaction fees (including, without limitation, any applicable
                                        mining fees). We are not liable for any inaccuracies, omissions or
                                        other errors with respect to any Withdrawal.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.7. In respect of each Withdrawal Address you provide to us
                                        and/or save and register with your Account:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) you represent and warrant that the Withdrawal Address you
                                        provide is your own and that you have full control over that
                                        address;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) you agree that it is your responsibility entirely to provide us
                                        with correct withdrawal details including your Withdrawal Address
                                        and we have no liability should you not receive the digital assets
                                        withdrawn due to you providing incorrect or out-of-date details;
                                        and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) you agree to indemnify and hold us harmless against any and all
                                        claims, demands, lawsuits, actions, proceedings, investigations,
                                        liabilities, damages, losses, costs or expenses, including
                                        reasonable attorneys’ fees, in any way arising out of, in relation
                                        to or in connection with, directly or indirectly, our reliance on
                                        your representations and warranties set out in this Clause.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        We may allow you to save and register one or more Withdrawal
                                        Addresses with your Account.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.8. We will process Withdrawals in accordance with our withdrawal
                                        policies (collectively the Withdrawal Policies). We may amend the
                                        Withdrawal Policies at our sole and absolute discretion without
                                        prior Notice to you. Unless otherwise stated in our Withdrawal
                                        Policies, it is your responsibility to specify and pay any
                                        applicable network fees (and in any event any such network fee
                                        shall be at least the minimum amount required by us) in respect of
                                        any Withdrawal. Where a network or transaction fee can or must be
                                        paid in a digital asset other than the digital asset that is the
                                        subject of a Withdrawal, we reserve the right to require payment
                                        of any such fee in an alternative digital asset (and to deduct
                                        from your Account any amount payable pursuant to this Clause). We
                                        do not guarantee that a withdrawal will be processed, broadcast,
                                        or confirmed within any defined timeframe or number of
                                        confirmations. We are not responsible for any delay in
                                        confirmation or processing of any Withdrawal.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>Transfers</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.9. If you hold more than one Account, we may provide the option
                                        of linking your Accounts at our sole and absolute discretion, and
                                        to allow transfers of digital assets from one Account to another
                                        Account by making a Withdrawal from one Account to the Deposit
                                        Address of the other Account. If we approve the transfer, we will
                                        deduct the relevant amount from the balance of digital assets that
                                        we reflect in our books and records in respect of the transferor
                                        Account and credit the balance of digital assets that we reflect
                                        in our books and records in respect of the transferee Account.
                                        Such transfers will not be sent to the applicable blockchain and
                                        are solely what the Company reflects in its books and records as
                                        available to be used by you in respect of the Services.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>Other</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        9.10. The protocol underlying a digital asset may change or
                                        otherwise cease to operate as expected due to changes made to its
                                        underlying technology or changes resulting from an attack. These
                                        changes may include, without limitation, a “fork”, a “rollback”,
                                        an “airdrop”, or a “bootstrap”. We will not support any such
                                        change unless we choose to do so in our sole and absolute
                                        discretion. Any action that we choose to take with respect to any
                                        change to or cessation of any underlying blockchain or asset
                                        protocol will be determined by us at our sole and absolute
                                        discretion, including (without limitation) in relation to the
                                        crediting (or otherwise) of any asset associated with any change
                                        and the selection of which blockchain (if any) is the successor to
                                        any blockchain, asset or protocol version.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10. Know-Your-Client, Anti-Money Laundering and Counter-Terrorist
                                        Financing, Sanctions and Restricted Jurisdictions
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>KYC</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10.1. We will conduct due diligence during Account opening and
                                        from time to time afterwards in order to comply with our
                                        “know-your-client” and other legal and regulatory obligations and
                                        requirements. You must provide any information or documentation we
                                        require (“KYC Requirement”), and provide updates to such
                                        information in a timely manner. A non-exhaustive list of the
                                        information or documentation we may require from you is set out
                                        here for individuals and here for corporates. We have the right to
                                        amend this list at any time at our sole and absolute discretion
                                        without prior Notice to you.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        Anti-Money Laundering and Counter-Terrorist Financing
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10.2. We implement processes and procedures to comply with
                                        Applicable Laws and international standards around AML/CFT,
                                        including (but not limited to):
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) monitoring transactions for the purpose of identifying
                                        suspicious or illegal activity; and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) investigating and reporting suspicious activity in accordance
                                        with Applicable Laws.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>Sanctions</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10.3. You are prohibited from opening an Account and/or using any
                                        of the Services if you and/or (if you are a corporation,
                                        partnership, firm or{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>organization</span>
                                    <span style={{ fontSize: "18px" }}>
                                        ) your controllers or shareholders (whether directly or
                                        indirectly) are subject to a sanctions regime (including sanctions
                                        administered or enforced by the United Nations or other relevant
                                        sanctions authorities), or have received any oral or written
                                        notice from any government or regulatory authority targeting you
                                        with sanctions, restrictions, penalties, enforcement action or
                                        investigation under any Applicable Law (including but not limited
                                        to AML/CFT, anti-corruption or economic sanction laws) (a
                                        “Sanctioned Person”).
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>Restricted Jurisdictions</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10.4. You may not open an Account or use any of the Services if
                                        you are a person restricted from using our Services in accordance
                                        with our Restricted Jurisdiction Policy or are otherwise
                                        restricted from opening an Account and/or using any of the
                                        Services as a result of any Applicable Laws (“Restricted
                                        Jurisdiction”). We have the right to amend the Restricted
                                        Jurisdictions and the Restricted Jurisdictions Policy at any time
                                        at our sole and absolute discretion without prior Notice to you.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10.5. We may, at our sole and absolute discretion, implement
                                        controls to restrict access to the Account and the Services in any
                                        of the Restricted Jurisdictions. We may block or geo-block persons
                                        located in certain Restricted Jurisdictions from accessing or
                                        using the Website, an Account and/or the Services (including the
                                        Trading Platform). You acknowledge, agree and understand that if
                                        you are located in a Restricted Jurisdiction, you are not
                                        permitted to access or use the Website, your Account and/or the
                                        Trading Platform, even if you are located there only temporarily
                                        or for travel, and restrictions will be applied to your Account
                                        accordingly. You acknowledge that this may impact your ability to
                                        trade on the Trading Platform or monitor any existing orders or
                                        open positions or otherwise use the Services. You must not attempt
                                        in any way to circumvent any such restriction, including by use of
                                        any virtual private network to obfuscate your location.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10.6. Notwithstanding any other provision in these Terms of
                                        Service, if we determine that you are accessing the Services or
                                        the Trading Platform from any Restricted Jurisdiction, or have
                                        given false representations as to your location of incorporation,
                                        establishment, citizenship or place of residence, we reserve the
                                        right to close your Account or take all appropriate action we deem
                                        necessary.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10.7. If the jurisdiction of which you are a citizen or in which
                                        you are resident, located, incorporated, or otherwise established
                                        or based (as applicable) becomes a Restricted Jurisdiction or any
                                        change (including any change to Applicable Laws) results in your
                                        jurisdiction becoming a Restricted Jurisdictions, without
                                        prejudice to any other provision in these Terms of Service, we may
                                        immediately cease providing you with access to and use of your
                                        Account and the Services.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        10.8. We are not responsible for any losses which may arise
                                        because you are not able to access or use the Website, your
                                        Account and/or the Services (including the Trading Platform) at
                                        the relevant time because of any action taken under this Clause
                                        10.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        11. Warranties and Representations
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        11.1. When you have an Account and/or access to or use any of the
                                        Services, you represent and warrant on an on-going basis that:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) you have accepted these Terms of Service (as amended and varied
                                        from time to time);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) you satisfy the Eligibility Criteria in full and at all times
                                        in respect of your Account;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) any and all information you provide to us is true, complete and
                                        correct.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        11.2. You undertake to notify us immediately if any of the
                                        representations or warranties set out in Clause 11.1 becomes
                                        untrue, incomplete or inaccurate.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>12. Fees</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        12.1. We charge fees (“Fees”) for the use of certain Services or
                                        Products. Details of the Fees will be made available via our
                                        Website(s), by Notice, or otherwise in accordance with the
                                        relevant Annex(es) or terms and conditions applicable to the
                                        relevant Service(s). We have the right to amend the Fees at any
                                        time at our sole and absolute discretion without prior Notice to
                                        you. The amended Fees will apply to your use of the relevant
                                        Services or Products following the effective date.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        12.2. We have the right to collect Fees from you in any way we
                                        determine, including deducting an amount equivalent to the Fees
                                        from your Account Balance, and you hereby{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>authorize</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;us to collect such Fees and deduct from your Account Balance
                                        directly.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        13. Limitation of Liability and Indemnification
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        13.1. To the maximum extent permitted by Applicable Law, we and
                                        our respective directors, officers, employees, agents, contractors
                                        and licensors (together, the “Associated Parties”) shall:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) not be liable to you for any losses, damages or costs other
                                        than as a result of fraud;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) provide the Services and any Product, service or other item on
                                        an “as is” and “as available” basis. We expressly disclaim, and
                                        you waive, any and all other warranties of any kind, whether
                                        express or implied, including implied warranties of
                                        merchantability, fitness for a particular purpose, title or
                                        non-infringement or warranties arising from course of performance,
                                        course of dealing or usage in trade. Without limiting the
                                        foregoing, we do not represent or warrant that the Services are
                                        accurate, complete, reliable, current or error-free, or free of
                                        viruses or other harmful components; and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) have no liability and not be responsible in any way for any
                                        losses, damages or costs:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        i) resulting from your reliance on any data or information in
                                        connection with the Trading Platform’s order book, trading charts
                                        and trading information. Such data and information provided on the
                                        Trading Platform are for general information purposes only and are
                                        given in good faith. For example, certain orders are not publicly
                                        visible on the Trading Platform’s order book. The information is
                                        selective and may be incomplete or inaccurate. You should not rely
                                        upon the information without further enquiry;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        ii) to you or to any third parties, for the correctness, quality,
                                        accuracy, security, completeness, reliability, performance,
                                        timeliness, pricing or continued availability of the Services
                                        (including the Trading Platform) or for delays or omissions of the
                                        Services, or for the failure of any connection or communication
                                        service to provide or maintain your access to the Services, or for
                                        any interruption in or disruption of your access or any erroneous
                                        communications between us and you, regardless of cause;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iii) arising out of your use of the internet and any equipment to
                                        connect to the Services or any technical problems, system
                                        failures, malfunctions, communication line failures, high internet
                                        traffic or demand, related issues, security breaches or any
                                        similar technical problems or defects experienced. You shall be
                                        responsible for any fees and charges incurred in order to connect
                                        to the internet for the purpose of using or accessing the
                                        Services;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        iv) relating to interruptions to or errors in the provision of the
                                        Trading Platform or the Services. Amongst other things, the
                                        operation and availability of the systems used for accessing the
                                        Trading Platform, including public telephone services, computer
                                        networks and the internet, can be unpredictable and may from time
                                        to time interfere with or prevent access to the Trading Platform.
                                        We are not in any way liable or responsible for any such
                                        interference that prevents your access to or use of the Trading
                                        Platform and the Services or causes any inaccuracy, error, delay
                                        in, or omission of (i) any information or (ii) the transmission or
                                        delivery of information;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        v) arising out of failures of communication, including where (a)
                                        we do not receive (i) instructions from you through the Trading
                                        Platform or (ii) we do not receive any emails, contact form
                                        submissions or any other communications (whether electronic or
                                        otherwise) from you or (b) you are unable to (i) send instructions
                                        to us or make Orders or Trades through the Trading Platform or
                                        (ii) receive notices or communications (such as trading or
                                        security notices or communications) which we send to you, for
                                        example where such communications are placed in a spam folder or
                                        otherwise filtered by your email service provider; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        vi) for your use of systems (including any equipment, software and
                                        hardware) to use and access the Services. You are solely
                                        responsible for your own systems used to access the Services and
                                        are solely liable for the integrity and proper storage of any data
                                        associated with the Services that is stored on your own systems .
                                        You are responsible for taking appropriate action to protect your
                                        systems and data from viruses and malicious software, and any
                                        inappropriate material. Except as provided by Applicable Law, you
                                        are solely responsible for backing up and maintaining duplicate
                                        copies of any information you store or transfer through our
                                        Services. We will not be liable to you in the event that your
                                        systems fail, are damaged or destroyed or any records or data
                                        stored on your systems are corrupted or lost for any reason.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        13.2. In no event shall any of the Associated Parties be liable
                                        for any:
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) indirect or consequential loss;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) loss of profit, business opportunity, revenue or goodwill, in
                                        each case, whether arising from breach of contract, tort
                                        (including negligence), breach of statutory duty or otherwise,
                                        arising out of or in connection with{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>authorized</span>
                                    <span style={{ fontSize: "18px" }}>&nbsp;or </span>
                                    <span style={{ fontSize: "18px" }}>unauthorized</span>
                                    <span style={{ fontSize: "18px" }}>
                                        &nbsp;use of the Services or these Terms of Service, even if the
                                        Associated Parties have been advised of, knew of or should have
                                        known of the possibility of such damages.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        13.3. The Associated Parties’ aggregate liability in contract,
                                        tort or otherwise (including any liability for any negligent act
                                        or omission) howsoever arising out of or in connection with the
                                        performance of its obligations under these Terms of Service, the
                                        Exchange Rules, or any other terms incorporated by reference in or
                                        to these Terms of Service or the Exchange Rules in respect of any
                                        one or more incidents or occurrences shall be limited to the total
                                        net amount of digital assets received by us from you by way of
                                        trading Fees in connection with your use of the Trading Platform
                                        in the six-month period immediately prior to the date of the
                                        incident, or the first incident of a series of connected
                                        incidents, giving rise to a claim made by you under these Terms of
                                        Service or Exchange Rules or any other terms incorporated by
                                        reference in these Terms of Service or the Exchange Rules.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        13.4. You agree to indemnify and hold harmless the Associated
                                        Parties immediately upon demand from and against any and all
                                        claims, demands, lawsuits, actions, proceedings, investigations,
                                        liabilities, damages, losses, costs or expenses, including
                                        reasonable attorneys’ fees, in any way arising out of, in relation
                                        to or in connection with, directly or indirectly, you or your{" "}
                                    </span>
                                    <span style={{ fontSize: "18px" }}>Authorized</span>
                                    <span style={{ fontSize: "18px" }}>&nbsp;Representatives’:</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) improper or illegal use of, or conduct in connection with, the
                                        Account and the Services (including where you are prohibited from
                                        holding or operating an Account and/or accessing or using the
                                        Services);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) breach of these Terms of Service or the Exchange Rules, any
                                        other terms incorporated by reference in or to these Terms of
                                        Service or the Exchange Rules, or any of the Group’s policies; or
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) breaches or violation of any Applicable Law (including any
                                        AML/CFT, anti-corruption or economic sanction laws) or the rights
                                        of any other person or entity.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        13.5. You are responsible for any fees, charges, costs and
                                        expenses your mobile network operator or internet service provider
                                        may charge you to download, access and/or use any of the Services.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>14. Compliance</span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        We shall have the right to take (or refuse to take) any action we
                                        deem to be appropriate or necessary to comply with any Applicable
                                        Laws or in accordance with our policies and procedures.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        15. Communications and Notices
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        15.1. We have the right to communicate and provide notices
                                        (“Notice”) to you in any manner which we deem to be appropriate,
                                        including (without limitation):
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        a) site announcements via any domain controlled by us (including,
                                        without limitation, bitmex.com);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        b) to the contact details registered with your Account (including,
                                        but not limited to, your registered email address);
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        c) Website banners and popups;
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        d) API messages and announcements; and
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        e) mobile alerts via the Mobile App.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        You agree and consent to electronic delivery of all communications
                                        and Notices to you.
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        15.2. You are responsible for ensuring that all information
                                        provided to us is kept up-to-date, correct and complete at all
                                        times. You can update the contact information in respect of your
                                        Account at any time. We are not responsible for any loss or damage
                                        which you may incur as a result of your failure to ensure that
                                        your contact information is up-to-date, correct and complete. You
                                        are deemed to have received all communications and Notices sent to
                                        the contact information associated with your Account (including,
                                        but not limited to, your registered email address).
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        <br />
                                    </span>
                                </font>
                            </p>{" "}
                            <p class="font-18" style={{ marginRight: "0px", marginLeft: "0px" }}>
                                {" "}
                                <font face="Nunito, sans-serif">
                                    <span style={{ fontSize: "18px" }}>
                                        15.3. If you are required to make a notice to us in writing under
                                        these Terms of Service, you must address the notice to
                                        support@gffexvip.com or via web form submission here. The notices
                                        must be received by us through these channels. Even if we offer
                                        livechat support and you communicate with our representatives, you
                                        must still send a notice to support@gffexvip.com or via web form
                                        submission here.
                                    </span>
                                </font>
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}