import React, { Component } from "react";
import Header from "../../layout/header";
import Sidebar from "../../layout/sidebar";
import LoadingOverlay from '../../components/loading';
import service from "../../services/service";
import { API_BASE_URL, IMAGE_PATH_COMPELETE } from '../../config';

class DepositConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: sessionStorage.getItem('userId') ?? null,
      depositId: props.match.params.id,
      loading: false,
      errorMessage: "",
      successMessage: "",
      deposits: {},
      paymentMethods: [],
      copied: false,
    };
  }

  handleCopy = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        this.setState({ copied: true });
        setTimeout(() => this.setState({ copied: false }), 2000); // Reset copied state after 2 seconds
      })
      .catch(err => console.error('Failed to copy:', err));
  };

  getDepositDetails = async () => {
    this.setState({ loading: true });
    try {
      const deposits = await service.view('deposits/confirm/' + this.state.depositId);
      if (deposits && !deposits.success) {
        this.setState({ successMessage: "", errorMessage: deposits.message + " " + deposits.error });
        return;
      }
      this.setState({ deposits: deposits.data.deposits });
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false });
    }
  }

  getPaymentMethods = async () => {
    this.setState({ loading: true });
    try {
      const response = await service.view("payment-methods/");
      if (response && !response.success) {
        this.setState({ successMessage: "", errorMessage: response.message });
        return;
      }
      this.setState({ paymentMethods: response.data.paymentMethods });
    } catch (error) {
      this.setState({ successMessage: "", errorMessage: error.message });
    } finally {
      this.setState({ loading: false })
    }
  }

  componentDidMount = async () => {
    if (this.state.depositId) this.getDepositDetails();
    this.getPaymentMethods();
  }

  render() {
    const { loading, errorMessage, successMessage, deposits, paymentMethods, } = this.state;
    // Filter payment method based on deposit currency
    const selectedPaymentMethod = paymentMethods.find(method => method.shorthand === deposits.depositCurrency);

    return (
      <>
        <Header />
        <Sidebar />

        <div className="content-body">
          <div className="container">
            {loading && <LoadingOverlay />}
            <div className="row">
              {/* <div className="container"> */}
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <div className="card custom--card">
                    <div
                      className="card-header card-header-bg"
                    >
                      <h5 className="card-title">Deposit Confirm</h5>
                    </div>
                    {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                    {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                    <div
                      className="card-body"
                    >
                      <form
                        action={`${API_BASE_URL}deposits/upload-receipt/${this.state.depositId}`}
                        method="POST"
                        encType="multipart/form-data"
                      >
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <p className="text-center mt-2">
                              You have requested{" "}
                              <b className="text--success">{deposits.amountInUsd} USD</b>,
                              Please pay{" "}
                              <b className="text--success">{deposits.amountInUsd} USD</b> for
                              successful payment
                            </p>
                            <h4 className="text-center mb-4">
                              Please follow the instruction below
                            </h4>

                            <div className="getBitcoinAddress">
                              <p className="my-4 text-center">
                                <div style={{ textAlign: "center" }}>
                                  <span style={{ fontSize: "1rem" }}>
                                    <font color="#000">
                                      Send Your Payment to following {deposits.depositCurrency + " "}
                                      Wallet Address or Scan the QR code.
                                    </font>
                                  </span>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <img src={selectedPaymentMethod ? IMAGE_PATH_COMPELETE + selectedPaymentMethod.imagePath.replace(/\\/g, '/') : ""} width="150" alt={deposits.depositCurrency + " QR Code"} />
                                  <br />
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <div>
                                    <font color="black">{selectedPaymentMethod ? selectedPaymentMethod.walletAddress : ""}</font>&nbsp;
                                    <i title="Copy Wallet Address" style={{cursor: 'pointer'}} className="icofont-copy" onClick={this.handleCopy(selectedPaymentMethod ? selectedPaymentMethod.walletAddress : "")}></i>
                                    <br />
                                  </div>
                                </div>
                              </p>
                            </div>
                          </div>

                          {/* <div className="form-group">
                            <label className="form-label">Network Type</label>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                name="network_type[]"
                                type="checkbox"
                                value="BTC network"
                                id="network_type_btc_network"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="network_type_btc_network"
                              >
                                BTC network
                              </label>
                            </div>
                          </div> */}

                          <div className="form-group">
                            <label className="form-label">
                              Transaction screenshot
                            </label>
                            <input
                              type="file"
                              className="form-control form--control"
                              name="transaction_screenshot"
                              required
                              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx"
                            />
                            <pre className="text--base mt-1">
                              Supported mimes: jpg, jpeg, png, pdf, doc, docx
                            </pre>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <button type="submit" className="cmn--btn btn-block btn btn-success waves-effect">
                                Pay Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DepositConfirm;
