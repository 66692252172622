import React, { Component } from 'react';
import Header from '../layout/header'
import { FE_BASE_URL } from '../config';
import Sidebar from '../layout/sidebar';
import LoadingOverlay from '../components/loading';
import authService from '../services/authService';
import thirdPartyAPIs from '../services/thirdPartyAPIs';
// import SubMenu from '../element/sub-menu';

export default class Settingsprofile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            username: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
            contact_number: "",
            password: "",
            confirmPassword: "",
            errorMessage: "",
            successMessage: "",
            countries: [],
            states: [],
            cities: [],
            userDetail: {},
            updateFields: {},
            loading: false,
        };
    }

    getCountriesFromAPI = async () => {
        try {
            const countriesData = await thirdPartyAPIs.getCountriesFromAPI();
            this.setState({ countries: countriesData })
            console.log('Received countries data:', countriesData);
        } catch (error) {
            // Handle the error, such as showing an error message or logging
            console.error('Error:', error);
        }
    }

    handleCountryChange = async (event) => {
        const selectedCountry = event.target.value;
        try {
            const states = await thirdPartyAPIs.getStatesForCountry(selectedCountry);
            this.setState({
                country: selectedCountry,
                states: states,
                state: "",
                cities: []
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    handleStateChange = async (event) => {
        const selectedState = event.target.value;
        try {
            const cities = await thirdPartyAPIs.getCitiesForState(this.state.country, selectedState);
            this.setState({
                state: selectedState,
                cities: cities
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
        this.setState((prevState) => ({
            updateFields: {
                ...prevState.updateFields,
                [name]: value,
            },
        }));
    };

    handleUpdateForm = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        try {
            const userId = sessionStorage.getItem('userId');
            const response = await authService.updateProfile(userId, this.state.updateFields);
            if (response && !response.success) {
                this.setState({ successMessage: "", errorMessage: response.message + " " + response.displayMessage });
                return;
            }
            this.setState({ errorMessage: "", successMessage: response.message + " " + response.displayMessage });
        } catch (error) {
            this.setState({ errorMessage: error.message, successMessage: "" });
        } finally {
            this.setState({ loading: false });
        }
    }

    componentDidMount() {
        this.getUserDetailsByID();
        this.getCountriesFromAPI();
    }

    getUserDetailsByID = async () => {
        this.setState({ loading: true });
        try {
            const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
            if (isLoggedIn) {
                const userId = sessionStorage.getItem('userId');
                const userDetail = await authService.getUserDetailsById(userId);
                if (userDetail.success) {
                    const states = await thirdPartyAPIs.getStatesForCountry(userDetail.user.address.country);
                    const cities = await thirdPartyAPIs.getCitiesForState(userDetail.user.address.country, userDetail.user.address.state);
                    this.setState({
                        firstName: userDetail.user.firstName,
                        lastName: userDetail.user.lastName,
                        email: userDetail.user.email,
                        username: userDetail.user.username,
                        street: userDetail.user.address.street,
                        city: userDetail.user.address.city,
                        state: userDetail.user.address.state,
                        postalCode: userDetail.user.address.postalCode,
                        country: userDetail.user.address.country,
                        contact_number: userDetail.user.contact_number,
                        password: userDetail.user.password,
                        states: states,
                        cities: cities
                    });
                }
            }
        } catch (error) {
            this.setState({ errorMessage: error.message, successMessage: "" });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { firstName, lastName, email, username, country, contact_number, password, street, city, state,
            postalCode, countries, states, cities, errorMessage, successMessage, loading } = this.state;
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                    {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12 col-xl-12">
                                <div className="page-title">
                                    <h4>Profile</h4>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        {/* <SubMenu /> */}
                                        {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                        {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">User Profile</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <form method='PUT' onSubmit={this.handleUpdateForm}>
                                                            <div className="row g-3">
                                                                <div className="col-xxl-12">
                                                                    <div className="d-flex align-items-center">
                                                                        <img className="mr-3 rounded-circle mr-0 mr-sm-3"
                                                                            src={`${FE_BASE_URL}assets/images/profile/3.png`} width="55" height="55"
                                                                            alt="" />
                                                                        <div className="media-body">
                                                                            <h4 className="mb-0">{firstName + " " + lastName}</h4>
                                                                            {/* <p className="mb-0">Max file size is 20mb</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-6">
                                                                    <label className="form-label">First Name</label>
                                                                    <input type="text" onChange={this.handleInputChange} value={firstName} name="firstName" className="form-control" placeholder="First Name" />
                                                                </div>
                                                                <div className="col-xxl-6">
                                                                    <label className="form-label">Last Name</label>
                                                                    <input type="text" onChange={this.handleInputChange} value={lastName} name="lastName" className="form-control" placeholder="Last Name" />
                                                                </div>

                                                                <div className="col-xxl-12">
                                                                    <div className="form-file">
                                                                        {/* <input type="file" className="form-file-input"
                                                                            id="customFile" />
                                                                        <label className="form-file-label" for="customFile">
                                                                            <span className="form-file-text">Choose file...</span>
                                                                            <span className="form-file-button">Browse</span>
                                                                        </label> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-xxl-12">
                                                                    <button className="btn btn-success waves-effect"><i className="icofont-save"></i> Save</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Account Information</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <form method='PUT' onSubmit={this.handleUpdateForm}>
                                                            <div className="row g-3">
                                                                <div className="col-xxl-12">
                                                                    <label className="form-label">Email</label>
                                                                    <input type="email" onChange={this.handleInputChange} name="email" value={email} className="form-control"
                                                                        placeholder="Email" />
                                                                </div>
                                                                <div className="col-xxl-12">
                                                                    <label className="form-label">Username</label>
                                                                    <input type="text" onChange={this.handleInputChange} name="username" value={username} className="form-control"
                                                                        placeholder="Username" />
                                                                </div>
                                                                <div className="col-xxl-12">
                                                                    <label className="form-label">New Password</label>
                                                                    <input type="password" value={password} onChange={this.handleInputChange} className="form-control" name="password" placeholder="**********" />
                                                                </div>
                                                                <div className="col-12">
                                                                    <button className="btn btn-success waves-effect"><i className="icofont-save"></i> Save</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xxl-12">
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h4 className="card-title">Personal Information</h4>
                                                    </div>
                                                    <div className="card-body">
                                                        <form method='PUT' onSubmit={this.handleUpdateForm}>
                                                            <div className="row g-4">
                                                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                                    <label className="form-label">Contact Number</label>
                                                                    <input type="text" onChange={this.handleInputChange} className="form-control" value={contact_number}
                                                                        placeholder="Contact Number" name="contact_number" />
                                                                </div>
                                                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                                    <label className="form-label">Street</label>
                                                                    <input type="text" onChange={this.handleInputChange} className="form-control"
                                                                        placeholder="123, Central Square, Brooklyn"
                                                                        name="street" value={street} />
                                                                </div>
                                                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                                    <label className="form-label">City</label>
                                                                    <select
                                                                        name="city"
                                                                        style={{ color: '#000' }}
                                                                        className="form-control"
                                                                        value={city}
                                                                        onChange={this.handleInputChange}
                                                                        required
                                                                    >
                                                                        <option value="Select City">Select City</option>
                                                                        {cities.map((city) => (
                                                                            <option key={city.id} value={city.name}>
                                                                                {city.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                                    <label className="form-label">Postal Code</label>
                                                                    <input type="text" className="form-control" placeholder="25481"
                                                                        name="postalCode" value={postalCode} />
                                                                </div>
                                                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                                    <label className="form-label">State</label>
                                                                    <select
                                                                        name="state"
                                                                        style={{ color: '#000' }}
                                                                        className="form-control"
                                                                        value={state}
                                                                        onChange={this.handleStateChange}
                                                                        required
                                                                    >
                                                                        <option value="Select State">Select State</option>
                                                                        {states.map((state) => (
                                                                            <option key={state.id} value={state.iso2}>
                                                                                {state.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="col-xxl-6 col-xl-6 col-lg-6">
                                                                    <label className="form-label">Country</label>
                                                                    <select
                                                                        name="country"
                                                                        style={{ color: '#000' }}
                                                                        className="form-control"
                                                                        value={country}
                                                                        onChange={this.handleCountryChange}
                                                                        required
                                                                    >
                                                                        <option value="Select Country">Select Country</option>
                                                                        {Array.isArray(countries) && countries.length > 0 ? (
                                                                            countries.map((country) => (
                                                                                <option key={country.id} value={country.iso2}>
                                                                                    {country.name}
                                                                                </option>
                                                                            ))
                                                                        ) : (
                                                                            <option value="" disabled>
                                                                                Loading countries...
                                                                            </option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <div className="col-12">
                                                                    <button
                                                                        className="btn btn-success pl-5 pr-5 waves-effect"><i className="icofont-save"></i> Save</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };
};