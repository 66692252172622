import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authService from '../services/authService';
import LoadingOverlay from '../components/loading';

export default class Reset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            findAccount: "",
            errorMessage: "",
            successMessage: "",
            loading: false,
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleReset = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            const { findAccount } = this.state;
            const userData = { findAccount };
            const result = await authService.users(userData, "forgot-password");
            if (result && !result.success) {
                this.setState({ successMessage: "", errorMessage: result.message + " " + result.displayMessage });
                return;
            }
            this.setState({ errorMessage: "", successMessage: result.message + " " + result.displayMessage });
        } catch (error) {
            this.setState({ successMessage: "", errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { findAccount, errorMessage, successMessage, loading } = this.state;
        return (
            <>
                {loading && <LoadingOverlay />}
                <div className="authincation">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-4 col-md-5">
                                <div className="mini-logo text-center my-3">
                                    <Link to={"/"}><img src={"assets/images/logo.png"} alt="" /></Link>
                                    <h4 className="card-title mt-3">Reset Password</h4>
                                    <p className="text-center p-2">To reset your password please provide your email or username to find your account.</p>
                                </div>
                                <div className="auth-form card">
                                    <div className="card-body">
                                        {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                        {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}
                                        <form method="POST" className="row g-3" onSubmit={this.handleReset}>
                                            <div className="col-12">
                                                <label className="form-label">Email or Username</label>
                                                <input type="text" className="form-control" placeholder="***********"
                                                    name="findAccount"
                                                    value={findAccount}
                                                    onChange={this.handleInputChange}
                                                    required
                                                    autoFocus={true} />
                                            </div>
                                            <div className="text-center mt-4">
                                                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}