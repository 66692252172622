import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import authService from '../services/authService';
import thirdPartyAPIs from '../services/thirdPartyAPIs';
import LoadingOverlay from '../components/loading';

class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            username: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            country: "",
            contact_number: "",
            password: "",
            confirmPassword: "",
            errorMessage: "",
            successMessage: "",
            countries: [],
            states: [],
            cities: [],
            loading: false,
        };
    }

    getCountriesFromAPI = async () => {
        try {
            const countriesData = await thirdPartyAPIs.getCountriesFromAPI();
            this.setState({ countries: countriesData })
            console.log('Received countries data:', countriesData);
          } catch (error) {
            // Handle the error, such as showing an error message or logging
            console.error('Error:', error);
          }
    }

    handleCountryChange = async (event) => {
        const selectedCountry = event.target.value;
        try {
            const states = await thirdPartyAPIs.getStatesForCountry(selectedCountry);
            
            this.setState({
              country: selectedCountry,
              states: states,
              state: "",
              cities: []
            });
          } catch (error) {
            console.error('Error:', error);
          }
    };

    handleStateChange = async (event) => {
        const selectedState = event.target.value;
        try {
            const cities = await thirdPartyAPIs.getCitiesForState(this.state.country, selectedState);
        
            this.setState({
              state: selectedState,
              cities: cities
            });
          } catch (error) {
            console.error('Error:', error);
          }
    };

    componentDidMount() {
        this.getCountriesFromAPI();
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };

    handleRegister = async (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        try {
            const { firstName, lastName, email, username, password, contact_number, confirmPassword, street,
                city, state, postalCode, country } = this.state;
            // Client-side validation: Check if passwords match
            if (password !== confirmPassword) {
                this.setState({ errorMessage: "Passwords do not match" });
                return;
            }
            // Make the API call for user registration
            const userData = {
                firstName, lastName, email, username, password, contact_number, street,
                city, state, postalCode, country
            };
            const result = await authService.users(userData, "register");
            if (result && !result.success) {
                this.setState({ successMessage: "", errorMessage: result.message });
                return;
            }
            this.setState({ errorMessage: "", successMessage: result.message + " " + result.displayMessage });
            window.location.href = "/signin/true";
        } catch (error) {
            this.setState({ successMessage: "", errorMessage: error.message });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { firstName, lastName, email, username, country, contact_number, password, confirmPassword, errorMessage, successMessage,
            street, city, state, postalCode, countries, states, cities, loading } = this.state;
        return (
            <>
            {loading && <LoadingOverlay />}
                <div className="authincation">
                    <div className="container h-100">
                        <div className="row justify-content-center h-100 align-items-center">
                            <div className="col-xl-5 col-md-6">
                                <div className="mini-logo text-center my-4">
                                    <Link to={"/"}><img src={"assets/images/logo.png"} alt="" /></Link>
                                    <h4 className="card-title mt-3">Create your account</h4>
                                </div>
                                <div className="auth-form card">
                                    <div className="card-body">
                                        <form method="post" onSubmit={this.handleRegister} name="myform" className="signin_validate row g-3">
                                            {errorMessage && (<div className="alert alert-danger" role="alert">{errorMessage}</div>)}
                                            {successMessage && (<div className="alert alert-success" role="alert">{successMessage}</div>)}

                                            {/* First Row */}
                                            <div className="col-12">
                                                <div className="row g-2">
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="First Name"
                                                            name="firstName"
                                                            value={firstName}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Last Name"
                                                            name="lastName"
                                                            value={lastName}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Second Row */}
                                            <div className="col-12">
                                                <div className="row g-2">
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Username"
                                                            name="username"
                                                            value={username}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Email"
                                                            name="email"
                                                            value={email}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Third Row */}
                                            <div className="col-12">
                                                <div className="row g-2">
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Phone Number"
                                                            name="contact_number"
                                                            value={contact_number}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <select
                                                            name="country"
                                                            style={{ color: '#000' }}
                                                            className="form-control"
                                                            value={country}
                                                            onChange={this.handleCountryChange}
                                                            required
                                                        >
                                                            <option value="Select Country">Select Country</option>
                                                            {Array.isArray(countries) && countries.length > 0 ? (
                                                                countries.map((country) => (
                                                                    <option key={country.id} value={country.iso2}>
                                                                        {country.name}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <option value="" disabled>
                                                                    Loading countries...
                                                                </option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Fourth Row */}
                                            <div className="col-12">
                                                <div className="row g-2">
                                                    <div className="col">
                                                        <select
                                                            name="state"
                                                            style={{ color: '#000' }}
                                                            className="form-control"
                                                            value={state}
                                                            onChange={this.handleStateChange}
                                                            required
                                                        >
                                                            <option value="Select State">Select State</option>
                                                            {states.map((state) => (
                                                                <option key={state.id} value={state.iso2}>
                                                                    {state.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <select
                                                            name="city"
                                                            style={{ color: '#000' }}
                                                            className="form-control"
                                                            value={city}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        >
                                                            <option value="Select City">Select City</option>
                                                            {cities.map((city) => (
                                                                <option key={city.id} value={city.name}>
                                                                    {city.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            {/* Fifth Row */}
                                            <div className="col-12">
                                                <div className="row g-2">
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Street"
                                                            name="street"
                                                            value={street}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Postal Code"
                                                            name="postalCode"
                                                            value={postalCode}
                                                            onChange={this.handleInputChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Sixth Row */}
                                            <div className="col-12">
                                                <div className="row g-2">
                                                    <div className="col">
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Password"
                                                            name="password"
                                                            value={password}
                                                            onChange={this.handleInputChange}
                                                            required
                                                            autoComplete="on"
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="Confirm Password"
                                                            name="confirmPassword"
                                                            value={confirmPassword}
                                                            onChange={this.handleInputChange}
                                                            required
                                                            autoComplete="on"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Agreement Row
                                            <div className="col-12">
                                                <div className="form-check form-switch">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="flexSwitchCheckDefault"
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexSwitchCheckDefault"
                                                    >
                                                        I certify that I am 18 years of age or older, and agree to the{' '}
                                                        <Link to={"#"} className="text-primary">
                                                            User Agreement
                                                        </Link>{' '}
                                                        and{' '}
                                                        <Link to={"#"} className="text-primary">
                                                            Privacy Policy
                                                        </Link>
                                                        .
                                                    </label>
                                                </div>
                                            </div> */}

                                            {/* Submit Button */}
                                            <div className="text-center">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary btn-block"
                                                >
                                                    Create account
                                                </button>
                                            </div>
                                        </form>
                                        <div className="text-center">
                                            <p className="mt-3 mb-0">
                                                {' '}
                                                <Link className="text-primary" to={"/signin"}>
                                                    Sign in
                                                </Link>{' '}
                                                to your account
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Signup;
