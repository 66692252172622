import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '../src/css/style.css'
import Reset from './user/reset';
import EmailVerify from './user/EmailVerify';
import ChangePassword from './user/ChangePassword';
import Homepage from './components/homepage';
import Signin from './user/signin';
import Signup from './user/signup';
import Profile from './components/profile';
import DepositHistory from './components/deposit/deposit_history';
import DepositMoney from './components/deposit/deposit_money';
import DepositConfirm from './components/deposit/deposit_confirm';
import Settingsprofile from './components/edit-profile';
import WithdrawMoney from './components/withdraw/withdraw_money';
import Assets from './components/trade_now/assets';
import Dashboard from './components/admin/dashboard';
import AssetsP from './components/practice/assets';
import PracticeNow from './components/practice/practice_now';
import PracticeLog from './components/practice/practice_log';
import usdtWithdraw from './components/withdraw/usdt_withdraw';
import BankWithdraw from './components/withdraw/bank_withdraw';
import TransactionLog from './components/transaction_log';
import BalanceLog from './components/admin/balance_log';
import TradeNow from './components/trade_now/trade_now';
import TradeLog from './components/trade_now/trade_log';
import WithdrawHistory from './components/withdraw/withdraw_history';
import UsersList from './components/admin/users_list';
import EditUser from './components/admin/edit_user';
import AssetsList from './components/admin/assets_list';
import AssetForm from './components/admin/asset_form';
import DepositApproval from './components/admin/deposits_approval';
import WithdrawalApproval from './components/admin/withdrawl_approval';
import { finaliseTradeIfNeeded, logoutIfTokenExpired, finalisePracticeTradeIfNeeded } from './services/tradeService';
import CustomerSupport from './components/customer_support';
import TransactionLogAdmin from './components/admin/transactions_log';
import TradeLogAdmin from './components/admin/trade_log';
import PrivacyPolicy from './components/site_info_files/privacy_policy';
import TermsOfServices from './components/site_info_files/terms_of_services';
import About from './components/site_info_files/about';
import TradePopup from './layout/tradePopup';

const basenameAdmin = "/admin/";

const TradeFinaliseComponent = () => {
  const [tradeInfo, setTradeInfo] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const currentUrl = location.pathname;
  const [mounted, setMounted] = useState(false);

  useEffect(() => { setMounted(true); }, []);
  useEffect(() => {
    if (mounted) {
      // if (!currentUrl.includes("/trade-now/")) finaliseTradeIfNeeded();
      if (!currentUrl.includes("/trade-now/")){
        finaliseTradeIfNeeded((info) => {
          // Callback function to handle trade information
          setTradeInfo(info);
          setShowPopup(true); // Show the popup when trade is finalized
        });
      }
      if (!currentUrl.includes("/practice-now/")) finalisePracticeTradeIfNeeded();
      logoutIfTokenExpired();
    }
  }, [mounted, currentUrl]);

  const handleClosePopup = () => {
    setShowPopup(false);
    setTradeInfo(null); // Clear trade info when popup is closed
  };

  return <>{showPopup && <TradePopup tradeInfo={tradeInfo} onClose={handleClosePopup} />}</>;
};

const App = () => {
  return (
    <Router>
      <TradeFinaliseComponent />
      <div id="main-wrapper">
        <Switch>
          {/* User Routes */}
          <Route path="/" exact component={Homepage} />
          {/* Common Site Files */}
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/term-of-services" exact component={TermsOfServices} />
          <Route path="/about" exact component={About} />
          {/* User Profile Related Routes */}
          <Route path="/user/profile" exact component={Profile} />
          <Route path="/user/profile/edit" exact component={Settingsprofile} />
          <Route path="/customer-support" exact component={CustomerSupport} />
          <Route path="/signin/:success?" exact component={Signin} />
          <Route path="/signup" exact component={Signup} />
          <Route path="/reset" exact component={Reset} />
          <Route path="/:id/reset/:token" exact component={ChangePassword} />
          <Route path="/:id/verify/:token" exact component={EmailVerify} />
          {/* Deposit Feature Related Routes */}
          <Route path="/deposit-history/:success?" exact component={DepositHistory} />
          <Route path="/deposit-now" exact component={DepositMoney} />
          <Route path="/deposit-confirm/:id" exact component={DepositConfirm} />
          {/* Withdraw Feature Related Routes */}
          <Route path="/withdraw-now" exact component={WithdrawMoney} />
          <Route path="/usdt-withdraw/:withdrawId?" exact component={usdtWithdraw} />
          <Route path="/bank-withdraw/:withdrawId?" exact component={BankWithdraw} />
          <Route path="/withdraw-history/:success?" exact component={WithdrawHistory} />
          {/* Trade Now Feature Related Routes */}
          <Route path="/trade" exact component={Assets} />
          <Route path="/trade-now/:assetId" exact component={TradeNow} />
          <Route path="/trade-log/:type?" exact component={TradeLog} />
          {/* Practice Now Feature Related Routes */}
          <Route path="/practice" exact component={AssetsP} />
          <Route path="/practice-now/:assetId" exact component={PracticeNow} />
          <Route path="/practice-log" exact component={PracticeLog} />
          {/* Transaction Log Feature Related Routes */}
          <Route path="/transaction-log" exact component={TransactionLog} />
          {/* Admin Routes */}
          <Route path={basenameAdmin + "dashboard"} exact component={Dashboard} />
          <Route path={basenameAdmin + "deposit-approval/:userId?"} exact component={DepositApproval} />
          <Route path={basenameAdmin + "withdrawal-approval/:userId?"} exact component={WithdrawalApproval} />
          <Route path={basenameAdmin + "assets-list"} exact component={AssetsList} />
          <Route path={basenameAdmin + "balance/:userId?"} exact component={BalanceLog} />
          <Route path={basenameAdmin + "transactions/:userId?"} exact component={TransactionLogAdmin} />
          <Route path={basenameAdmin + "users-list"} exact component={UsersList} />
          <Route path={basenameAdmin + "update-user/:userId?"} exact component={EditUser} />
          <Route path={basenameAdmin + "asset-form/:assetId?"} exact component={AssetForm} />
          <Route path={basenameAdmin + "trade-log/:userId?/:type?"} exact component={TradeLogAdmin} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
