import React, { Component } from 'react';

export default class LoadingOverlay extends Component {
    render() {
        return (
            <div
                style={{
                    position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 999,
                }}>
                Loading...
            </div>
        );
    };
};