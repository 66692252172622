import React, { Component } from "react";
import Header from "../../layout/header";
import Footer from "../../layout/footer";

export default class About extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="container">
                    <div className="row">
                        <h1>About City Index Platform Introduction</h1>
                        <p>Global Financial Futures Exchange City Index for short</p>
                        <p>City Index is a subsidiary of London International Financial Futures Exchange (NYSE-LIFFE), the largest financial exchange in the United Kingdom. As the world's most diversified derivatives market leader, it handles an average of 1 billion contracts each year, worth about 100 billion U.S. dollars. City Index provides a market for buyers and sellers, linking individuals, companies, or institutions that need to manage risks or are willing to take risks to make profits.</p>
                        <p>City Index relies on its parent company NYSE-LIFFE's rich experience in international trading market management, advanced technical capabilities, and a wide range of investment varieties, and is committed to better serving international customers. It mainly operates energy futures transactions and various digital currency transactions. As the latest futures trading and digital currency trading hosting model, City Index realizes the decentralization of blockchain technology, and customers have better experience and financial management functions. The exchange covers energy, agricultural products, natural gas, and metal futures and options trading. The market provides innovative management solutions, providing customers with the most complete trading products and financial value-added products.</p>
                        <p>The exchange has employees all over the world, with a professional team of financial analysts, professional traders, and a strong data analysis team to provide scientific methods for trading various futures products at different stages.</p>
                        <p>Through our City Index electronic trading platform, global users can access the financial derivatives market with the widest coverage and the best liquidity no matter where they are.</p>
                        <h2>Why City Index is the Safest Exchange</h2>
                        <p>The City Index exchange has realized blockchain decentralized financial technology, and its parent company is the London International Financial Futures Exchange, which is the earliest and most active exchange in Europe, leading the world in terms of transaction types, transaction funds, and transaction technology. According to personal information and credit evaluation, the transaction limit level is increased to ensure the stability of customer transaction funds.</p>
                        <h2>More Benefits</h2>
                        <ol>
                            <li>The investment environment for crude oil is very safe. As the world's most important energy source, crude oil's international status guarantees that its price is not manipulated and always follows the market, making it a fair and equitable trade.</li>
                            <li>The high leverage trading model is an investment advantage. This trading model allows investors to trade with a small amount of capital and make bigger profits, which greatly reduces the amount of capital needed to invest in crude oil and allows investors to effectively use their investment funds.</li>
                            <li>Two-way operation and flexible trading. Compared to traditional investment products such as stocks, crude oil futures can be bought up as well as down, and the flexible trading method allows investors to make money under any one market, and profit from both up and down.</li>
                            <li>Crude oil investment is directly in line with international standards, including Asian, European, and U.S. markets, so the trading hours are 24 hours a day, suitable for investors engaged in a variety of jobs, so you do not have to worry about investment time.</li>
                        </ol>
                        <h2>What are the Advantages of Futures Investment</h2>
                        <ol>
                            <li>First, short-term transactions, the risk can be controlled, the investment capital is low, the rate of return is large, and the two-way transaction is long and short.</li>
                            <li>T+0 trading mode, real-time trading on the same day, capital application to the extreme, after grasping the trend, you can trade at any time.</li>
                            <li>Supported by national policies, futures projects are safe and stable, with gold and crude oil as the mainstays, and transactions are subject to state monitoring.</li>
                        </ol>
                        <h2>Product Description</h2>
                        <p>The opening of futures positions provides users with a stable crude oil asset appreciation plan. City Index cooperates with CME, COMEX, NYSE-LIFFE, and many other futures exchanges to purchase crude oil futures in advance, and conduct custody value-added transactions on the City Index platform, providing many futures. Clients provide safe and reliable futures wealth management transactions, realizing the stable value-added of futures wealth management.</p>
                        <h2>Locked Mining</h2>
                        <p>Product Description: City Index Exchange hosts blockchain decentralized transactions to the platform, combined with cloud-based mining scientific algorithms to provide digital currency trading users with stable revenue methods and scientific financial management, enabling users to achieve wealth freedom and asset expansion in the blockchain world.</p>
                        <h2>Balance Treasure</h2>
                        <p>It is an asset value-added financial plan for current deposit users to trade digital currency and legal currency in the world. After depositing, users can enjoy the convenience of fast in and out of funds, flexible deposit and withdrawal, and meet the needs of principal security and liquidity. Strive to achieve higher returns.</p>
                        <p>Income calculation: assets at the end of the day * annualized interest rate at the end of the day/365.</p>
                        <p>Account arrival method: Daily earnings are automatically re-deposited, enjoying rolling compound interest.</p>
                        <h2>Oil Futures is Our City Index Key Product</h2>
                        <p>City Index oil futures Light Crude Oil (CL) futures are the most liquid and actively traded crude oil contracts in the world, providing the most efficient way to trade the global oil market today.</p>
                        <p>City Index crude oil futures offer the opportunity to invest directly in the oil market and are a key advantage over other trading methods, whether you are looking to hedge your risk or hedge against price movements.</p>
                        <h2>Crude Oil Popularity</h2>
                        <p>Crude oil futures are currently one of the world's most traded commodity futures varieties and can be leveraged to operate long or short, with flexible and liquid operations. U.S. crude oil futures are the most successful commodity futures contracts ever traded, and its transaction prices have become the focus of attention in the international oil market. Due to the high price transparency of crude oil futures, their prices are the benchmark for international crude oil pricing.</p>
                        <p>In summary: oil investment environment is safe, prices are not manipulated, trading is fair and equitable, short-term trading is flexible and convenient, high profits are obtained with lower capital, and trading is available 24/24.</p>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}