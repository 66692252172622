import React, { Component } from 'react';
import Header from '../layout/header'
import Sidebar from '../layout/sidebar';
import { Link } from 'react-router-dom';
import authService from '../services/authService';
import LoadingOverlay from './loading';

export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userDetail: {},
            loading: false,
        };
    }

    componentDidMount() {
        this.getUserDetailsByID();
    }

    getUserDetailsByID = async () => {
        this.setState({ loading: true });
        try {
            const userId = sessionStorage.getItem('userId') ?? null;
            if (userId) {
                const userDetail = await authService.getUserDetailsById(userId);
                if (userDetail.success) {
                    this.setState(prevState => ({
                        userDetail: userDetail.user,
                        loading: false,
                    }));
                }
            }
        } catch (error) {
            // Handle error if needed
        } finally {
            this.setState({ loading: false });
        }
    };


    render() {
        const { userDetail, loading } = this.state;
        const { street, city, state, country, postalCode } = userDetail.address || {};
        return (
            <>
                <Header />
                <Sidebar />
                <div className="content-body">
                    <div className="container">
                        {loading && <LoadingOverlay />}
                        <div className="row">
                            <div className="col-xxl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-title">Information </h4>
                                        <Link to={"profile/edit"} className="btn btn-primary"><i className="icofont-edit"></i> Edit</Link>
                                    </div>
                                    <div className="card-body">
                                        <form className="row">
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div className="user-info">
                                                    <span>First Name</span>
                                                    <h4>{userDetail.firstName}</h4>
                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div className="user-info">
                                                    <span>Last Name</span>
                                                    <h4>{userDetail.lastName}</h4>
                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div className="user-info">
                                                    <span>Contact Number</span>
                                                    <h4>{userDetail.contact_number}</h4>
                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div className="user-info">
                                                    <span>Email</span>
                                                    <h4><h4>{userDetail.email}</h4></h4>
                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div className="user-info">
                                                    <span>Username</span>
                                                    <h4>{userDetail.username}</h4>
                                                </div>
                                            </div>
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                                <div className="user-info">
                                                    <span>Address</span>
                                                    <h4>{street || ''}, {city || ''}, {state || ''}, {postalCode || ''}, {country || ''}</h4>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};